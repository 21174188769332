<template>
  <b-modal hide-footer hide-header centered id="modalEdit2" size="lg">
    <div class="tutup" style="display: flex; justify-content: flex-end">
      <b-button
        @click="$bvModal.hide('modalEdit2')"
        style="background-color: white; border: none"
        ><img
          src="../../assets/icon/close.svg"
          alt=""
          style="width: 15px; height: 15px"
      /></b-button>
    </div>
    <div
      class="content"
      style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 1% 2%;
        "
    >
      <span
        class="tittle"
        style="
            font-family: 'nunito', sans-serif;
            font-size: 28px;
            font-weight: 700;
            text-transform: capitalize;
          "
      >
        Edit Akun
      </span>
      <div class="form" style="width: 95%; margin-top: 4%">
        <b-form-group
          label-size="lg"
          label-cols="4"
          label-cols-lg="3"
          label="Nama Akun"
          label-for="input-formatter"
        >
          <b-form-input
            id="input-formatter"
            placeholder="Nama Akun"
            style="border-radius: 10px; margin-bottom: 15px"
            size="lg"
            type="text"
            v-model="data.namaAkunSubHeadSub"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          label-size="lg"
          label-cols="4"
          label-cols-lg="3"
          label="Kode Akun"
          label-for="input-fromatter"
        >
          <b-form-input
            id="input-formatter"
            placeholder="Kode Akun"
            style="border-radius: 10px; margin-bottom: 15px"
            size="lg"
            type="text"
            v-model="data.kodeAkunSubHeadSub"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          label-size="lg"
          label-cols="4"
          label-cols-lg="3"
          label="Keterangan Akun"
          label-for="input-fromatter"
        >
          <b-form-input
            id="input-formatter"
            placeholder="Keterangan Akun"
            style="border-radius: 10px; margin-bottom: 15px"
            size="lg"
            type="text"
            v-model="data.keteranganSubHeadSub"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          label-size="lg"
          label-cols="4"
          label-cols-lg="3"
          label="Kategori Akun"
          label-for="input-fromatter"
        >
          <b-form-input
            id="input-formatter"
            placeholder="Kategori Akun"
            style="border-radius: 10px; margin-bottom: 15px"
            size="lg"
            type="text"
            v-model="data.kategoriSubHeadSub"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          label-size="lg"
          label-cols="4"
          label-cols-lg="3"
          label="CLK Akun"
          label-for="input-fromatter"
        >
          <b-form-input
            id="input-formatter"
            placeholder="CLK Akun"
            style="border-radius: 10px; margin-bottom: 15px"
            size="lg"
            type="text"
            v-model="data.CLKSubHeadSub"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          label-size="lg"
          label-cols="4"
          label-cols-lg="3"
          label="PAAK Akun"
          label-for="input-fromatter"
        >
          <b-form-input
            id="input-formatter"
            placeholder="PAAK Akun"
            style="border-radius: 10px; margin-bottom: 15px"
            size="lg"
            type="text"
            v-model="data.PAAKSubHeadSub"
          >
          </b-form-input>
        </b-form-group>
      </div>
    </div>
    <div
      class="wrapper"
      style="
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 0 2%;
        "
    >
      <div
        class="button-wrapper"
        style="display: flex; width: 95%; justify-content: flex-end"
      >
        <b-button
          @click="doEdit()"
          :disabled="loading"
          style="
              background-color: #327ff2;
              color: #fff;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              width: 21%;
              border: none;
            "
        >
          <WhiteSpinner v-show="loading" />
          <span v-show="!loading">Edit Akun</span>
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import axios from "@/ip_config.js";
import WhiteSpinner from "@/components/spinner/white-spinner.vue";

export default {
  components: {
    WhiteSpinner,
  },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      keterangan: null,
    };
  },
  methods: {
    doEdit() {
      let vm = this;
      vm.loading = true;
      axios
        .post(
          "akun/subHeadSub/updateAkun/" + vm.data.idAkunSubHeadSub,
          {
            namaAkunSubHeadSub: vm.data.namaAkunSubHeadSub,
            kodeSubHeadSub: vm.data.kodeAkunSubHeadSub,
            projectId: +localStorage.getItem("idProject"),
            keteranganSubHeadSub: vm.data.keteranganSubHeadSub,
            kategoriSubHeadSub: +vm.data.kategoriSubHeadSub,
            CLKSubHeadSub: +vm.data.CLKSubHeadSub,
            PAAKSubHeadSub: vm.data.PAAKSubHeadSub,
          },
          {
            headers: { token: localStorage.getItem("token") },
          }
        )
        .then((res) => {
          vm.loading = false;
          if (res.data.status == 200) {
            vm.$emit("triggerAlert", {
              pesan: `Sukses Mengubah Akun ${vm.data.kodeAkunSubHead}`,
              alert: "alert2",
            });
          }
          vm.$bvModal.hide("modalEdit2");
        })
        .catch(() => {
          vm.loading = false;
          vm.$emit("triggerAlert", {
            pesan:
              "Koneksi / Server bermasalah! Silahkan coba beberapa saat lagi.",
            alert: "alert",
          });
          vm.$bvModal.hide("modalEdit2");
        });
    },
  },
};
</script>
