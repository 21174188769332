<template>
  <div class="wrapper">
    <div
      style="width: 100%; height: 50px"
      class="d-md-block d-lg-block d-xl-none"
    ></div>
    <b-row>
      <b-col>
        <div class="button-wrapper">
          <b-button size="sm" @click="showAll">Tampilkan Semua</b-button>
          <b-button size="sm" @click="showAll">Tutup Semua</b-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="tables">
          <vue-ads-table
            :columns="columns"
            :rows="rows"
            :start="start"
            :end="end"
            :classes="classes"
          >
            <template slot="sort-icon" slot-scope="props">
              ({{
                props.direction === null
                  ? "null"
                  : props.direction
                  ? "up"
                  : "down"
              }})
            </template>
            <template slot="toggle-children-icon" slot-scope="props">
              [{{ props.expanded ? "-" : "+" }}]
            </template>
            <template slot="aksi" slot-scope="props"
              ><img
                style="
                  border: none;
                  background-color: #f4b366;
                  padding: 5%;
                  border-radius: 4px;
                  cursor: pointer;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
                v-if="props.row.nomor"
                @click="edit(props.row)"
                src="../../assets/icon/edit-2.svg"
                alt=""
              />
            </template>
            <template slot="keteranganAset" slot-scope="props">
              <span
                v-if="
                  props.row.keteranganAset == 'Sub Total' ||
                    props.row.keteranganAset == 'Total'
                "
                style="font-weight: 800; font-size: 16px"
                >{{ props.row.keteranganAset }}</span
              >

              <span v-else>{{ props.row.keteranganAset }}</span>
            </template>
            <template slot="hargaPerolehan" slot-scope="props">
              <span
                v-if="props.row.status == true"
                style="font-weight: 800; font-size: 16px"
                >{{ props.row.hargaPerolehan }}</span
              >
              <span v-else>{{ props.row.hargaPerolehan }}</span>
            </template>
            <template slot="check1" slot-scope="props">
              <span
                v-if="props.row.status == true"
                style="font-weight: 800; font-size: 16px"
                >{{ props.row.check1 }}</span
              >
              <span v-else>{{ props.row.check1 }}</span>
            </template>
            <template slot="check2" slot-scope="props">
              <span
                v-if="props.row.status == true"
                style="font-weight: 800; font-size: 16px"
                >{{ props.row.check2 }}</span
              >
              <span v-else>{{ props.row.check2 }}</span>
            </template>
            <template slot="check3" slot-scope="props">
              <span
                v-if="props.row.status == true"
                style="font-weight: 800; font-size: 16px"
                >{{ props.row.check3 }}</span
              >
              <span v-else>{{ props.row.check3 }}</span>
            </template>
            <template slot="check4" slot-scope="props">
              <span
                v-if="props.row.status == true"
                style="font-weight: 800; font-size: 16px"
                >{{ props.row.check4 }}</span
              >
              <span v-else>{{ props.row.check4 }}</span>
            </template>
            <template slot="akumPenyusutanTahunSebelumnya" slot-scope="props">
              <span
                v-if="props.row.status == true"
                style="font-weight: 800; font-size: 16px"
                >{{ props.row.akumPenyusutanTahunSebelumnya }}</span
              >

              <span v-else>{{ props.row.akumPenyusutanTahunSebelumnya }}</span>
            </template>
            <template slot="nilaiBukuTahunSebelumnya" slot-scope="props">
              <span
                v-if="props.row.status == true"
                style="font-weight: 800; font-size: 16px"
                >{{ props.row.nilaiBukuTahunSebelumnya }}</span
              >

              <span v-else>{{ props.row.nilaiBukuTahunSebelumnya }}</span>
            </template>
            <template slot="penyusutanTahunBerjalan" slot-scope="props">
              <span
                v-if="props.row.status == true"
                style="font-weight: 800; font-size: 16px"
                >{{ props.row.penyusutanTahunBerjalan }}</span
              >

              <span v-else>{{ props.row.penyusutanTahunBerjalan }}</span>
            </template>
            <template slot="akumPenyusutanTahunBerjalan" slot-scope="props">
              <span
                v-if="props.row.status == true"
                style="font-weight: 800; font-size: 16px"
                >{{ props.row.akumPenyusutanTahunBerjalan }}</span
              >

              <span v-else>{{ props.row.akumPenyusutanTahunBerjalan }}</span>
            </template>
            <template slot="nilaiBukuTahunBerjalan" slot-scope="props">
              <span
                v-if="props.row.status == true"
                style="font-weight: 800; font-size: 16px"
                >{{ props.row.nilaiBukuTahunBerjalan }}</span
              >

              <span v-else>{{ props.row.nilaiBukuTahunBerjalan }}</span>
            </template>
          </vue-ads-table>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { VueAdsTable } from "vue-ads-table-tree";
import axios from "@/ip_config.js";
import accounting from "accounting-js";
import moment from "moment";
moment.locale("id");
import "../../../node_modules/vue-ads-table-tree/dist/vue-ads-table-tree.css";

let dataAset = [];

export default {
  components: {
    VueAdsTable,
  },
  data() {
    let classes = {
      table: {
        "vue-ads-border": true,
        "vue-ads-w-full": true,
        "vue-ads-font-bold": true,
      },
      info: {
        "vue-ads-text-center": true,
        "vue-ads-py-6": true,
        "vue-ads-text-sm": true,
        "vue-ads-border-t": true,
      },
      group: {
        "vue-ads-border-b": true,
        "vue-ads-italic": true,
      },
      selected: {
        "vue-ads-bg-teal-100": true,
      },
      "all/": {
        "hover:vue-ads-bg-gray-200": true,
      },
      "all/all": {
        "vue-ads-px-4": true,
        "vue-ads-py-2": true,
        "vue-ads-text-sm": true,
      },
      "even/": {
        "vue-ads-bg-white": true,
      },
      "odd/": {
        "vue-ads-bg-gray-100": true,
      },
      "0/all": {
        "vue-ads-px-4": true,
        "vue-ads-py-2": true,
        "vue-ads-text-center": true,
      },
      "0_-1/": {
        "vue-ads-border-b": true,
      },
      "/0_-1": {
        "vue-ads-border-r": true,
      },
      "1_/": {
        "vue-ads-text-blue": true,
      },
    };

    let rows = [
      {
        keteranganAset: "",
        asetTetapHead: "",
        _showChildren: false,
        _children: [
          {
            keteranganAset: "",
            akumPenyusutanTahunSebelumnya: "",
            akumPenyusutanTahunBerjalan: "",
            tahunPerolehan: "",
            persen: "",
            hargaPerolehan: "",
            nilaiBukuTahunBerjalan: "",
            nilaiBukuTahunSebelumnya: "",
          },
        ],
      },
    ];

    let columns = [
      {
        property: "asetTetapHead",
        title: "No#",
        filterable: true,
      },
      {
        property: "keteranganAset",
        title: "Nama Aset",
        filterable: true,
        collapseIcon: true,
      },

      {
        property: "jumlahAset",
        title: "Unit",
        filterable: true,
      },

      {
        property: "tahunPerolehan",
        title: "Tahun Perolehan",
        filterable: true,
      },

      {
        property: "persen",
        title: "%",
        filterable: true,
      },

      {
        property: "hargaPerolehan",
        title: "Harga Perolehan",
        filterable: true,
      },

      {
        property: "akumPenyusutanTahunSebelumnya",
        title: "AKM Penyusutan Thn Sebelumnya",
        filterable: true,
      },

      {
        property: "nilaiBukuTahunSebelumnya",
        title: "Nilai Buku Thn Sebelumnya",
        filterable: true,
      },

      {
        property: "check1",
        title: "Check",
        filterable: true,
      },

      {
        property: "penyusutanTahunBerjalan",
        title: "Penyusutan Thn Berjalan",
        filterable: true,
      },

      {
        property: "check2",
        title: "Check",
        filterable: true,
      },

      {
        property: "akumPenyusutanTahunBerjalan",
        title: "AKM Penyusutan Thn Berjalan",
        filterable: true,
      },

      {
        property: "check3",
        title: "Check",
        filterable: true,
      },

      {
        property: "nilaiBukuTahunBerjalan",
        title: "Nilai Buku Thn Berjalan",
        filterable: true,
      },

      {
        property: "check4",
        title: "Check",
        filterable: true,
      },
    ];
    return {
      classes,
      rows,
      columns,
      start: 0,
      end: 10000,
    };
  },
  mounted() {
    this.getAset();
  },
  methods: {
    async getAset() {
      let vm = this;
      try {
        let aset = await axios.get(
          "asetTetap/listAsetTetap/" + localStorage.getItem("idProject"),
          {
            headers: {
              token: localStorage.getItem("token"),
            },
          }
        );
        dataAset = aset.data.data;

        let hargaPerolehan = 0;
        let akumPenyusutanTahunSebelumnya = 0;
        let nilaiBukuTahunSebelumnya = 0;
        let penyusutanTahunBerjalan = 0;
        let akumPenyusutanTahunBerjalan = 0;
        let nilaiBukuTahunBerjalan = 0;
        let totalAset = 0;
        let totalCheck1 = 0;
        let totalCheck2 = 0;
        let totalCheck3 = 0;
        let totalCheck4 = 0;

        let arr = [];

        for (let i = 0; i < dataAset.length; i++) {
          let subTotalAset = 0;
          let subCheck1 = 0;
          let subCheck2 = 0;
          let subCheck3 = 0;
          let subCheck4 = 0;

          hargaPerolehan += dataAset[i].jumlahHargaPerolehan;
          akumPenyusutanTahunSebelumnya +=
            dataAset[i].jumlahAkumPenyusutanTahunSebelumnya;
          nilaiBukuTahunSebelumnya +=
            dataAset[i].jumlahNilaiBukuTahunSebelumnya;
          penyusutanTahunBerjalan += dataAset[i].jumlahPenyusutanTahunBerjalan;
          akumPenyusutanTahunBerjalan +=
            dataAset[i].jumlahAkumPenyusutanTahunBerjalan;
          nilaiBukuTahunBerjalan += dataAset[i].jumlahNilaiBukuTahunBerjalan;

          for (let c = 0; c < dataAset[i]._children.length; c++) {
            dataAset[i]._children[c].asetTetapHead =
              dataAset[i]._children[c].asetTetapSubHead;

            dataAset[i]._children[c].tahunPerolehan = moment(
              dataAset[i]._children[c].tahunPerolehan
            ).format("L");

            dataAset[i]._children[c].akumPenyusutanTahunBerjalan = vm.formats(
              dataAset[i]._children[c].akumPenyusutanTahunBerjalan
            );

            dataAset[i]._children[c].penyusutanTahunBerjalan = vm.formats(
              dataAset[i]._children[c].penyusutanTahunBerjalan
            );

            dataAset[i]._children[c].akumPenyusutanTahunSebelumnya = vm.formats(
              dataAset[i]._children[c].akumPenyusutanTahunSebelumnya
            );

            dataAset[i]._children[c].hargaPerolehan = vm.formats(
              dataAset[i]._children[c].hargaPerolehan
            );

            dataAset[i]._children[c].nilaiBukuTahunSebelumnya = vm.formats(
              dataAset[i]._children[c].nilaiBukuTahunSebelumnya
            );

            dataAset[i]._children[c].nilaiBukuTahunBerjalan = vm.formats(
              dataAset[i]._children[c].nilaiBukuTahunBerjalan
            );

            dataAset[i]._children[c].persen =
              dataAset[i]._children[c].persen * 100 + "%";

            dataAset[i]._children[c].jumlahAset = +dataAset[i]._children[c]
              .jumlahAset;

            if (dataAset[i]._children[c].status) {
              dataAset[i]._children[c].jumlahAset = 0;
            }

            subCheck1 += dataAset[i]._children[c].check1;
            subCheck2 += dataAset[i]._children[c].check2;
            subCheck3 += dataAset[i]._children[c].check3;
            subCheck4 += dataAset[i]._children[c].check4;

            totalCheck1 += dataAset[i]._children[c].check1;
            totalCheck2 += dataAset[i]._children[c].check2;
            totalCheck3 += dataAset[i]._children[c].check3;
            totalCheck4 += dataAset[i]._children[c].check4;

            dataAset[i]._children[c].check1 = vm.formats(
              dataAset[i]._children[c].check1
            );

            dataAset[i]._children[c].check2 = vm.formats(
              dataAset[i]._children[c].check2
            );

            dataAset[i]._children[c].check3 = vm.formats(
              dataAset[i]._children[c].check3
            );

            dataAset[i]._children[c].check4 = vm.formats(
              dataAset[i]._children[c].check4
            );

            subTotalAset += dataAset[i]._children[c].jumlahAset;
            totalAset += dataAset[i]._children[c].jumlahAset;
          }

          arr.push(dataAset[i], {
            keteranganAset: "Sub Total",
            hargaPerolehan: vm.formats(dataAset[i].jumlahHargaPerolehan),
            akumPenyusutanTahunSebelumnya: vm.formats(
              dataAset[i].jumlahAkumPenyusutanTahunSebelumnya
            ),
            nilaiBukuTahunSebelumnya: vm.formats(
              dataAset[i].jumlahNilaiBukuTahunSebelumnya
            ),
            penyusutanTahunBerjalan: vm.formats(
              dataAset[i].jumlahPenyusutanTahunBerjalan
            ),
            akumPenyusutanTahunBerjalan: vm.formats(
              dataAset[i].jumlahAkumPenyusutanTahunBerjalan
            ),
            nilaiBukuTahunBerjalan: vm.formats(
              dataAset[i].jumlahNilaiBukuTahunBerjalan
            ),
            check1: vm.formats(subCheck1),
            check2: vm.formats(subCheck2),
            check3: vm.formats(subCheck3),
            check4: vm.formats(subCheck4),
            jumlahAset: subTotalAset,
            status: true,
          });
        }

        arr.push({
          keteranganAset: "Total",
          hargaPerolehan: vm.formats(hargaPerolehan),
          akumPenyusutanTahunSebelumnya: vm.formats(
            akumPenyusutanTahunSebelumnya
          ),
          nilaiBukuTahunSebelumnya: vm.formats(nilaiBukuTahunSebelumnya),
          penyusutanTahunBerjalan: vm.formats(penyusutanTahunBerjalan),
          akumPenyusutanTahunBerjalan: vm.formats(akumPenyusutanTahunBerjalan),
          nilaiBukuTahunBerjalan: vm.formats(nilaiBukuTahunBerjalan),
          jumlahAset: totalAset,
          check1: vm.formats(totalCheck1),
          check2: vm.formats(totalCheck2),
          check3: vm.formats(totalCheck3),
          check4: vm.formats(totalCheck4),
          status: true,
        });

        vm.rows = arr;
      } catch (error) {
        console.log(error);
      }
    },

    formats(money) {
      money = accounting.formatMoney(money, {
        symbol: "",
        precicsion: 2,
        thousand: ".",
        decimal: ",",
        format: {
          pos: "%v",
          neg: "(%v)",
          zero: "-",
        },
      });
      return money;
    },

    showAll() {
      let vm = this;

      let arr = vm.rows;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i]._showChildren === false) {
          arr[i]._showChildren = true;
        } else {
          arr[i]._showChildren = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");
$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white": #fafafa,
  "white2": #fff,
  "gray-dark": #b5bac3,
  "blue-light": #e8f3fe,
  "red-light": #ffe8e7,
  "green-light": #eafffa,
  "green": #6adbbe,
);

.wrapper {
  position: relative;
  .tables {
    max-height: 67.8vh;
    min-height: 67.8vh;
    border-radius: 7px;
    overflow-y: scroll;
    width: 100%;
    border: 1px solid map-get($color, "gray-dark");
    table {
      width: 150vw;
      min-width: 100vw;
      font-family: "Nunito", sans-serif;
    }
  }
}

.button-wrapper {
  margin-bottom: 1%;
  display: flex;
  width: 100%;
  .btn {
    // height: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: map-get($color, "blue");
    font-family: "nunito", sans-serif;
    text-transform: capitalize;
    border: none;
    margin-right: 2%;
  }
}
</style>
