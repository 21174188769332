<template>
  <div class="wrapper">
    <div
      style="width: 100%; height: 50px"
      class="d-md-block d-lg-block d-xl-none"
    ></div>
    <b-card no-body>
      <b-tabs
        class="tab"
        pills
        card
        vertical
        nav-wrapper-class="col-3"
        style="font-family: 'Nunito', sans-serrif; font-weight: 600"
      >
        <b-tab title="List" active>
          <div class="buttons" style="padding-bottom: 10px">
            <b-button
              @click="buatList"
              v-if="
                rows[0]._children.length == 0 &&
                  rows[1]._children.length == 0 &&
                  rows[2]._children.length == 0 &&
                  rows[3]._children.length == 0 &&
                  rows[4]._children.length == 0 &&
                  rows[5]._children.length == 0
              "
              style="
                background-color: #327ff2;
                border: none;
                font-family: 'Nunito', sans-serrif;
                color: #fff;
                height: clamp(24px, 28px, 32px);
                display: flex;
                font-size: clamp(10px, 14px, 18px);
                align-items: center;
              "
              >Buat List</b-button
            >
          </div>
          <b-overlay :show="show" spinner-variant="primary" rounded="sm">
            <div :aria-hidden="show ? 'true' : null" class="table">
              <!-- <h3
                v-if="rows[0]._children.length == 0"
                style="
                  font-family: 'Nunito', sans-serrif;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 5em;
                  transform: translateY(40%);
                "
              >
                To Do List Belum Dibuat
              </h3> -->
              <vue-ads-table
                :columns="columns"
                :rows="rows"
                :filter="filter"
                :start="start"
                :end="end"
                :classes="classes"
                :itemPerPage="12"
                top
              >
                <template slot="sort-icon" slot-scope="props">
                  ({{
                    props.direction === null
                      ? "null"
                      : props.direction
                      ? "up"
                      : "down"
                  }})
                </template>

                <template slot="toggle-children-icon" slot-scope="props">
                  [{{ props.expanded ? "-" : "+" }}]
                </template>

                <template slot="aksi" slot-scope="props">
                  <div class="button-wrapper2">
                    <div
                      class="tambah"
                      v-if="props.row.head == true"
                      v-show="
                        role == 'adminKAP' ||
                          role == 'manager' ||
                          role == 'supervisor'
                      "
                      @click="tambahList(props.row.namaTitle)"
                    >
                      <img
                        src="../../assets/icon/icon/plus-circle.svg"
                        alt=""
                      />
                    </div>

                    <div
                      class="edit"
                      v-if="props.row.head == false"
                      v-show="
                        role == 'adminKAP' ||
                          role == 'manager' ||
                          role == 'supervisor'
                      "
                      v-b-modal.editTodo
                      @click="dataProps = props.row"
                    >
                      <img src="../../assets/icon/edit-2.svg" alt="" />
                    </div>

                    <div
                      class="hapus"
                      v-if="
                        role == 'adminKAP' ||
                          role == 'manager' ||
                          (role == 'supervisor' &&
                            props.row.namaTitle != 'Pra Audit' &&
                            props.row.namaTitle != 'Legalitas' &&
                            props.row.namaTitle != 'Data Pendukung' &&
                            props.row.namaTitle != 'Visitasi' &&
                            props.row.namaTitle != 'Finalisasi' &&
                            props.row.namaTitle != 'Release')
                      "
                      @click="hapus(props.row)"
                    >
                      <img src="../../assets/icon/trash.svg" alt="" />
                    </div>
                  </div>
                </template>

                <template slot="checkMark" slot-scope="props">
                  <b-form-group
                    v-if="
                      props.row.namaTitle != 'Pra Audit' &&
                        props.row.namaTitle != 'Legalitas' &&
                        props.row.namaTitle != 'Data Pendukung' &&
                        props.row.namaTitle != 'Visitasi' &&
                        props.row.namaTitle != 'Finalisasi' &&
                        props.row.namaTitle != 'Release'
                    "
                  >
                    <b-form-checkbox
                      @change="
                        checkMark(
                          props.row.idTodoList,
                          props.row.namaTitle,
                          $event
                        )
                      "
                      v-model="props.row.statusTodoList"
                      value="1"
                      unchecked-value="0"
                      >Selesai</b-form-checkbox
                    >
                  </b-form-group>
                </template>
              </vue-ads-table>
            </div>
          </b-overlay>
        </b-tab>
        <b-tab title="Import">
          <div>
            <div
              class="content"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding: 1% 2%;
              "
            >
              <span
                class="tittle"
                style="
                  font-family: 'nunito', sans-serif;
                  font-size: 28px;
                  font-weight: 700;
                  text-transform: capitalize;
                "
              >
                Import Lembar Kerja
              </span>
              <div class="form" style="width: 95%; margin-top: 4%">
                <b-form-file
                  placeholder="Pilih File"
                  v-model="fileUpload"
                ></b-form-file>
              </div>
            </div>
            <div
              class="wrapper"
              style="
                width: 100%;
                display: flex;
                justify-content: center;
                padding: 0 2%;
              "
            >
              <div
                class="button-wrapper"
                style="display: flex; width: 95%; justify-content: flex-end"
              >
                <b-button
                  @click="uploadFile"
                  v-if="loading == false"
                  :disabled="!fileUpload"
                  style="
                    background-color: #327ff2;
                    color: #fff;
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    border: none;
                    margin-top: 20px;
                    height: 40px;
                    align-items: center;
                  "
                  >Import Lembar Kerja</b-button
                >
                <b-button
                  disabled
                  v-else
                  style="
                    background-color: #327ff2;
                    color: #fff;
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    border: none;
                    margin-top: 20px;
                    height: 40px;
                    align-items: center;
                  "
                  ><WhiteSpinner
                /></b-button>
              </div>
            </div>

            <hr />

            <div
              class="content"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding: 1% 2%;
              "
            >
              <span
                class="tittle"
                style="
                  font-family: 'nunito', sans-serif;
                  font-size: 28px;
                  font-weight: 700;
                  text-transform: capitalize;
                "
              >
                Import Aset Tetap
              </span>
              <div class="form" style="width: 95%; margin-top: 4%">
                <b-form-file
                  placeholder="Pilih File"
                  v-model="fileAset"
                ></b-form-file>

                <multiselect
                  v-model="tahunAsetTetap"
                  :options="pilihan"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="false"
                  placeholder="Pilih Pembanding"
                  label="text"
                  track-by="value"
                  :hideSelected="false"
                  :preselect-first="false"
                  style="font-family: 'nunito', sans-serif; margin-top: 2%"
                >
                </multiselect>
              </div>
            </div>
            <div
              class="wrapper"
              style="
                width: 100%;
                display: flex;
                justify-content: center;
                padding: 0 2%;
              "
            >
              <div
                class="button-wrapper"
                style="display: flex; width: 95%; justify-content: flex-end"
              >
                <b-button
                  @click="uploadAset"
                  v-if="loading == false"
                  :disabled="!fileAset || !tahunAsetTetap"
                  style="
                    background-color: #327ff2;
                    color: #fff;
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    border: none;
                    margin-top: 20px;
                    height: 40px;
                    align-items: center;
                  "
                  >Import Aset Tetap</b-button
                >
                <b-button
                  disabled
                  v-else
                  style="
                    background-color: #327ff2;
                    color: #fff;
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    border: none;
                    margin-top: 20px;
                    height: 40px;
                    align-items: center;
                  "
                  ><WhiteSpinner
                /></b-button>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="Detail">
          <div>
            <div
              class="content"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding: 1% 2%;
              "
            >
              <span
                class="tittle"
                style="
                  font-family: 'nunito', sans-serif;
                  font-size: 28px;
                  font-weight: 700;
                  text-transform: capitalize;
                  padding: 25px;
                "
              >
                Pengaturan untuk Unduh File
              </span>
            </div>
            <div class="isian">
              <b-row>
                <h4 style="padding: 15px">Informasi KAP dan Akuntan Publik</h4>
              </b-row>
              <b-row style="margin-bottom: 30px">
                <b-col>
                  <b-form-group
                    label-size="md"
                    label-cols="4"
                    label-cols-lg="4"
                    label="Nama KAP"
                  >
                  </b-form-group>

                  <b-form-input
                    placeholder="Nama KAP"
                    style="
                      border-radius: 10px;
                      margin-bottom: 15px;
                      width: 300px;
                    "
                    size="md"
                    type="text"
                    v-model="namaKAP"
                  >
                  </b-form-input>
                </b-col>
                <b-col>
                  <b-form-group
                    label-size="md"
                    label-cols="4"
                    label-cols-lg="6"
                    label="Nama Akuntan Publik"
                  >
                  </b-form-group>

                  <b-form-input
                    placeholder="Nama Akuntan Publik"
                    style="
                      border-radius: 10px;
                      margin-bottom: 15px;
                      width: 300px;
                    "
                    size="md"
                    type="text"
                    v-model="namaAkuntanPublik"
                  >
                  </b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <h4 style="padding: 15px">Informasi Auditor</h4>
              </b-row>
              <b-row style="margin-bottom: 30px">
                <b-col>
                  <b-form-group
                    label-size="md"
                    label-cols="4"
                    label-cols-lg="4"
                    label="Nama Auditor"
                  >
                  </b-form-group>
                  <b-form-input
                    list="list-auditor"
                    style="width: 300px; heigth: 40px"
                    v-model="namaAuditor"
                  ></b-form-input>
                </b-col>
              </b-row>

              <b-row>
                <h4 style="padding: 15px">Informasi Penyusun Dan Review</h4>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    label-size="md"
                    label-cols="4"
                    label-cols-lg="6"
                    label="Nama Penyusun"
                  >
                  </b-form-group>
                  <b-form-input
                    list="list-auditor"
                    style="width: 300px; heigth: 40px"
                    v-model="namaPenyusun"
                  ></b-form-input>
                </b-col>
                <b-col>
                  <b-form-group
                    label-size="md"
                    label-cols="4"
                    label-cols-lg="6"
                    label="Tanggal Penyusunan"
                  >
                  </b-form-group>
                  <b-form-datepicker
                    style="width: 300px"
                    v-model="tanggalPenyusunan"
                    id="datepicker-dateformat2"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    locale="id"
                  ></b-form-datepicker>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group
                    label-size="md"
                    label-cols="4"
                    label-cols-lg="6"
                    label="Nama Reviewer"
                  >
                  </b-form-group>
                  <b-form-input
                    list="list-auditor"
                    style="width: 300px; heigth: 40px"
                    v-model="namaReviewer"
                  ></b-form-input>
                  <!-- <b-form-select
              v-model="namaReviewer"
              class="mb-3"
              style="width: 300px; heigth: 40px"
            >
              <b-form-select-option
                v-for="(item, idx) in auditor"
                :key="idx"
                :value="item.id"
                >{{ item.nama }}</b-form-select-option
              >
            </b-form-select> -->
                </b-col>
                <b-col>
                  <b-form-group
                    label-size="md"
                    label-cols="4"
                    label-cols-lg="6"
                    label="Tanggal Review"
                  >
                  </b-form-group>
                  <b-form-datepicker
                    style="width: 300px"
                    v-model="tanggalReview"
                    id="datepicker-dateformat1"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    locale="id"
                  ></b-form-datepicker>
                </b-col>
              </b-row>
              <b-row>
                <b-col></b-col>
                <b-col>
                  <b-form-group
                    label-size="md"
                    label-cols="4"
                    label-cols-lg="6"
                    label="Tanggal Selesai"
                  >
                  </b-form-group>
                  <b-form-datepicker
                    style="width: 300px"
                    v-model="tanggalBerakhir"
                    id="datepicker-dateformat3"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    locale="id"
                  ></b-form-datepicker>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-row>
                    <b-col cols="6">
                      <h4 style="padding: 15px">Informasi Penanda Tangan</h4>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="6">
                      <b-form-group label="Nama" label-for="namaTTd">
                        <b-form-input
                          id="namaTTd"
                          v-model="namaPenandaTangan"
                          type="text"
                          required
                          style="width: 300px; heigth: 40px"
                        ></b-form-input
                      ></b-form-group>

                      <b-form-group label="Jabatan" label-for="jabatan">
                        <b-form-input
                          id="jabatan"
                          v-model="jabatanPenandaTangan"
                          type="text"
                          required
                          style="width: 300px; heigth: 40px"
                        ></b-form-input
                      ></b-form-group>

                      <b-form-group label="Kota" label-for="kota">
                        <b-form-input
                          id="kota"
                          v-model="kotaTandaTangan"
                          type="text"
                          required
                          style="width: 300px; heigth: 40px"
                        ></b-form-input
                      ></b-form-group>

                      <b-form-group
                        label="Tanggal Tanda Tangan"
                        label-for="jabatan"
                      >
                        <b-form-datepicker
                          style="width: 300px"
                          v-model="tanggalTandaTangan"
                          id="tanggalTTD"
                          :date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                          }"
                          locale="id"
                        ></b-form-datepicker
                      ></b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <div
              class="wrapper"
              style="
                width: 100%;
                display: flex;
                justify-content: center;
                padding: 0 2%;
              "
            >
              <div
                class="button-wrapper"
                style="display: flex; width: 95%; justify-content: flex-end"
              >
                <b-button
                  @click="ubah()"
                  v-if="loading == false"
                  style="
                    background-color: #327ff2;
                    color: #fff;
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    width: 21%;
                    border: none;
                    margin-top: 20px;
                    height: 40px;
                  "
                  >Ubah</b-button
                >
                <b-button
                  disabled
                  v-else
                  style="
                    background-color: #327ff2;
                    color: #fff;
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    width: 25%;
                    border: none;
                    margin-top: 20px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  ><white-spinner
                /></b-button>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="Ganti Pembanding">
          <multiselect
            v-model="selected"
            :options="pilihan"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="false"
            placeholder="Pilih Pembanding"
            label="text"
            track-by="value"
            :hideSelected="false"
            :preselect-first="false"
            style="font-family: 'nunito', sans-serif; width: 15vw"
            @input="gantiPembanding"
          >
          </multiselect>
        </b-tab>
        <b-tab title="Tanggal Project">
          <tanggal-project />
        </b-tab>
        <b-tab title="Download & Export">
          <b-button
            style="
              background-color: #327ff2;
              border: none;
              font-family: 'Nunito', sans-serrif;
              color: #fff;
              display: flex;
              hight: 60px;
              align-items: center;
            "
            @click="download"
            >Download Draft Audit</b-button
          >
          <hr />
          <b-button
            style="
              background-color: #327ff2;
              border: none;
              font-family: 'Nunito', sans-serrif;
              color: #fff;
              display: flex;
              hight: 60px;
              align-items: center;
            "
            @click="downloadAset"
            >Download Form Upload AT</b-button
          >
          <hr />
          <b-button
            style="
              background-color: #327ff2;
              border: none;
              font-family: 'Nunito', sans-serrif;
              color: #fff;
              display: flex;
              hight: 60px;
              align-items: center;
            "
            @click="download2"
            >Download Form Upload COA</b-button
          >
        </b-tab>
      </b-tabs>
    </b-card>
    <b-modal hide-footer hide-header centered ref="modal-tambah" size="lg">
      <div class="tutup" style="display: flex; justify-content: flex-end">
        <b-button
          @click="tutupModal"
          style="background-color: white; border: none"
          ><img
            src="../../assets/icon/close.svg"
            alt=""
            style="width: 15px; height: 15px"
        /></b-button>
      </div>
      <div
        class="content"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 1% 2%;
        "
      >
        <span
          class="tittle"
          style="
            font-family: 'nunito', sans-serif;
            font-size: 28px;
            font-weight: 700;
            text-transform: capitalize;
          "
        >
          Buat To Do List
        </span>
        <div class="form" style="width: 95%; margin-top: 4%">
          <b-form-group
            label-size="lg"
            label-cols="4"
            label-cols-lg="2"
            label="To Do List"
            label-for="input-formatter"
          >
            <b-form-input
              id="input-formatter"
              placeholder="Masukan To Do List"
              style="border-radius: 10px; margin-bottom: 15px"
              size="lg"
              type="text"
              v-model="namaListBaru"
            >
            </b-form-input>
          </b-form-group>
        </div>
      </div>
      <div
        class="wrapper"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 0 2%;
        "
      >
        <div
          class="button-wrapper"
          style="display: flex; width: 95%; justify-content: flex-end"
        >
          <b-button
            @click="doTambah"
            v-if="loading == false"
            style="
              background-color: #327ff2;
              color: #fff;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              border: none;
              margin-top: 20px;
              height: 40px;
              align-items: center;
            "
            >Tambah List</b-button
          >
          <b-button
            disabled
            v-else
            style="
              background-color: #327ff2;
              color: #fff;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              width: 25%;
              border: none;
              margin-top: 20px;
              height: 40px;
            "
            ><white-spinner
          /></b-button>
        </div>
      </div>
    </b-modal>
    <b-modal
      class="hapus"
      hide-footer
      hide-header
      centered
      ref="modal-hapus"
      size="sm"
    >
      <div
        class="content"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          padding: 1% 2%;
        "
      >
        <span
          class="tittle"
          style="
            font-family: 'nunito', sans-serif;
            font-size: 28px;
            font-weight: 700;
            text-transform: capitalize;
          "
        >
          Hapus To Do List
        </span>
      </div>
      <div
        class="wrapper"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 0 2%;
        "
      >
        <div
          class="button-wrapper"
          style="display: flex; width: 95%; justify-content: flex-end"
        >
          <b-button
            @click="doDelete()"
            v-if="loading == false"
            style="
              background-color: #327ff2;
              border-radius: 10px;
              margin-right: 10px;
              font-size: 14px;
              border: none;
              color: #fff;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              height: 28px;
              display: flex;
              align-items: center;
              box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
            "
            >Hapus List</b-button
          >
          <b-button
            disabled
            v-else
            style="
              background-color: #327ff2;
              color: #fff;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              width: 50px;
              height: 25px;
              border: none;
              margin-top: 20px;
              margin-right: 20px
              height: 40px;
            "
            ><white-spinner
          /></b-button>
          <b-button
            @click="batalDelete()"
            v-if="loading == false"
            style="
              background-color: red;
              border-radius: 10px;
              margin-right: 10px;
              font-size: 14px;
              border: none;
              color: #fff;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              height: 28px;
              display: flex;
              align-items: center;
              box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
            "
            >Batalkan</b-button
          >
          <b-button
            disabled
            v-else
            style="
              background-color: #327ff2;
              color: #fff;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              width: 50px;
              height: 25px;
              border: none;
              margin-top: 20px;
              height: 40px;
            "
            ><white-spinner
          /></b-button>
        </div>
      </div>
    </b-modal>
    <b-alert dismissible style="width: 50%" variant="danger" fade :show="alert">
      {{ pesan }}
    </b-alert>
    <b-alert
      dismissible
      style="width: 50%"
      variant="success"
      fade
      :show="alert2"
    >
      {{ pesan }}
    </b-alert>

    <b-alert
      dismissible
      style="width: 50%"
      variant="danger"
      fade
      :show="alert3"
    >
      {{ pesan }}
    </b-alert>
    <b-alert
      dismissible
      style="width: 50%"
      variant="success"
      fade
      :show="alert4"
    >
      {{ pesan }}
    </b-alert>
    <ModalEditToDoList
      @alertnya="alertnya($event)"
      @refresh="getList"
      :dataProps="dataProps"
    />
  </div>
</template>

<script>
import axios from "@/ip_config.js";
import { VueAdsTable } from "vue-ads-table-tree";
import { mapState } from "vuex";
import WhiteSpinner from "../../components/spinner/white-spinner.vue";
import TanggalProject from "./tanggalProject.vue";
import Multiselect from "vue-multiselect";
import ModalEditToDoList from "@/components/modals/modalEditTodoList.vue";

export default {
  components: {
    VueAdsTable,
    WhiteSpinner,
    TanggalProject,
    Multiselect,
    ModalEditToDoList,
  },

  computed: {
    ...mapState("apiProject", ["stateProject", "role"]),
  },

  data() {
    let classes = {
      table: {
        "vue-ads-border": true,
        "vue-ads-w-full": true,
        "vue-ads-font-bold": true,
      },
      info: {
        "vue-ads-text-center": true,
        "vue-ads-py-6": true,
        "vue-ads-text-sm": true,
        "vue-ads-border-t": true,
      },
      group: {
        "vue-ads-border-b": true,
        "vue-ads-italic": true,
      },

      selected: {
        "vue-ads-bg-teal-100": true,
      },
      "all/": {
        "hover:vue-ads-bg-gray-200": true,
      },
      "all/all": {
        "vue-ads-pxvue-ads-px-0": true,
        "vue-ads-text-sm": true,
      },
      "even/": {
        "vue-ads-bg-white": true,
      },
      "odd/": {
        "vue-ads-bg-gray-100": true,
      },
      "0/all": {
        "vue-ads-text-center": true,
      },
      "0_-1/": {
        "vue-ads-border-b": true,
      },
      "/0_-1": {
        "vue-ads-border-r": true,
      },
      "1_/": {
        "vue-ads-text-blue": false,
      },
    };
    let rows = [
      {
        nomer: "",
        namaTitle: "",
        _showChildren: true,
        _children: [
          {
            nomer: "",
            idTodoList: "",
            namaTitle: "",
            statusTodoList: 0,
          },
        ],
      },
    ];

    let columns = [
      {
        property: "nomer",
        title: "No",
        filterable: true,
      },
      {
        property: "namaTitle",
        title: "Kegiatan",
        filterable: true,
        collapseIcon: true,
      },
      {
        property: "checkMark",
        title: "Check",
        filterable: true,
      },
      {
        property: "aksi",
        title: "Aksi",
        filterable: true,
      },
    ];

    return {
      columns,
      rows,
      start: 0,
      end: 1000,
      filter: "",
      _children: [],
      show: false,
      loading: false,
      classes,
      statusTodoList: 0,
      idPembanding: 0,
      selected: "",
      tanggalReview: "",
      namaReviewer: "",
      namaPenyusun: "",
      tanggalPenyusunan: "",
      pilihan: [],
      namaAuditor: "",
      auditor: [],
      namaAkuntanPublik: "",
      fileUpload: "",
      fileAset: [],
      namaKAP: "",
      pesan: "",
      alert: false,
      alert2: false,
      alert3: false,
      alert4: false,
      checkMarking: 0,
      namaListBaru: "",
      namanya: "",
      idDelete: "",
      tanggalBerakhir: "",
      direkturUtama: "",
      direktur: "",
      komisaris: "",
      namaPenandaTangan: "",
      jabatanPenandaTangan: "",
      kotaTandaTangan: "",
      tanggalTandaTangan: "",
      tahunAsetTetap: "",
      tanggalMulai: "",
      deadline: "",
      dataProps: {},
    };
  },
  mounted() {
    this.getList();
    this.edit();
    let check = localStorage.getItem("pembanding");
    this.getOtherProject();

    if (check) {
      var checkOther = JSON.parse(check);
      if (this.$route.params.id === checkOther.project) {
        this.selected = checkOther;
      } else if (this.$route.params.id != checkOther.project) {
        localStorage.removeItem("pembanding");
      }
    }

    if (this.stateProject.deadline != "" || this.stateProject.tanggalMulai) {
      this.deadline = this.stateProject.deadline;
      this.tanggalMulai = this.stateProject.tanggalMulai;
    }
  },
  beforeDestroy() {
    localStorage.removeItem("pembandingnya");
  },
  methods: {
    alertnya(msg) {
      this.alert4 = true;
      this.pesan = msg.pesan;
      setTimeout(() => {
        this.alert4 = false;
      }, 3000);
    },
    getList() {
      axios
        .get(
          "todoList/detailsByProjectId/" + localStorage.getItem("idProject"),
          {
            headers: { token: localStorage.getItem("token") },
          }
        )
        .then((res) => {
          let arr = res.data.data;
          // if (arr.length < 1) {
          //   this.buatList();
          //   console.log("a");
          // } else {
          for (let iterate = 0; iterate < arr.length; iterate++) {
            arr[iterate].nomer = iterate + 1;
            arr[iterate].head = true;

            let arr2 = arr[iterate]._children;
            for (let iterate2 = 0; iterate2 < arr2.length; iterate2++) {
              arr2[iterate2].nomer = arr[iterate].nomer + "." + (iterate2 + 1);
              arr2[iterate2].head = false;
              arr2[iterate2].judul = arr[iterate].namaTitle;
            }
          }
          this.rows = arr;
        });
    },

    async getOtherProject() {
      let vm = this;
      let getProject = await axios.get(
        "client/listById/" + localStorage.getItem("idClient"),
        {
          headers: { token: localStorage.getItem("token") },
        }
      );
      let arr = getProject.data.data;

      for (let iterate in arr) {
        if (arr[iterate].projectid != vm.stateProject.id) {
          vm.pilihan.push({
            text: arr[iterate].tahunProject,
            value: arr[iterate].projectid,
            project: vm.$route.params.id,
          });
        }
      }
    },

    gantiPembanding() {
      let beforeParse = localStorage.getItem("pembanding");
      if (!beforeParse) {
        localStorage.setItem("pembanding", JSON.stringify(this.selected));
      } else {
        var getBefore = JSON.parse(beforeParse);
        localStorage.getItem("pembanding", JSON.stringify(getBefore));
      }
    },

    buatList() {
      let x = localStorage.getItem("token");
      let y = localStorage.getItem("idProject");
      let forms = [
        {
          namaTitle: "Pra Audit",
          namaTodoList: "Surat Penunjukan",
          projectId: y,
        },
        {
          namaTitle: "Pra Audit",
          namaTodoList: "Surat Penawaran",
          projectId: y,
        },
        {
          namaTitle: "Pra Audit",
          namaTodoList: "Surat Perikatan Kerja",
          projectId: y,
        },
        {
          namaTitle: "Pra Audit",
          namaTodoList: "Laporan Keuangan Inhouse",
          projectId: y,
        },
        {
          namaTitle: "Pra Audit",
          namaTodoList: "Laporan Audit Tahun Lalu",
          projectId: y,
        },
        {
          namaTitle: "Pra Audit",
          namaTodoList: "Inhouse TTD CAP",
          projectId: y,
        },
        {
          namaTitle: "Legalitas",
          namaTodoList: "Akta Pendirian",
          projectId: y,
        },
        {
          namaTitle: "Legalitas",
          namaTodoList: "Akta Perubahan",
          projectId: y,
        },
        {
          namaTitle: "Legalitas",
          namaTodoList: "NIB",
          projectId: y,
        },
        {
          namaTitle: "Legalitas",
          namaTodoList: "NPWP",
          projectId: y,
        },
        {
          namaTitle: "Legalitas",
          namaTodoList: "Company Profile",
          projectId: y,
        },
        {
          namaTitle: "Data Pendukung",
          namaTodoList: "Rekening Koran",
          projectId: y,
        },
        {
          namaTitle: "Data Pendukung",
          namaTodoList: "SPT Masa",
          projectId: y,
        },
        {
          namaTitle: "Data Pendukung",
          namaTodoList: "SPT Tahunan",
          projectId: y,
        },
        {
          namaTitle: "Data Pendukung",
          namaTodoList: "Perhitungan Imbalan Kerja",
          projectId: y,
        },
        {
          namaTitle: "Data Pendukung",
          namaTodoList: "SPK Bank",
          projectId: y,
        },
        {
          namaTitle: "Data Pendukung",
          namaTodoList: "Lampiran Aset Tetap",
          projectId: y,
        },
        {
          namaTitle: "Data Pendukung",
          namaTodoList: "Lampiran Piutang",
          projectId: y,
        },
        {
          namaTitle: "Data Pendukung",
          namaTodoList: "Lampiran Persediaan",
          projectId: y,
        },
        {
          namaTitle: "Data Pendukung",
          namaTodoList: "Lampiran Hutang",
          projectId: y,
        },
        {
          namaTitle: "Visitasi",
          namaTodoList: "Cash Opname",
          projectId: y,
        },
        {
          namaTitle: "Visitasi",
          namaTodoList: "Stock Opname",
          projectId: y,
        },
        {
          namaTitle: "Visitasi",
          namaTodoList: "Aset Tetap Opname",
          projectId: y,
        },
        {
          namaTitle: "Visitasi",
          namaTodoList: "Konfirmasi Piutang",
          projectId: y,
        },
        {
          namaTitle: "Visitasi",
          namaTodoList: "Konfirmasi Hutang",
          projectId: y,
        },
        {
          namaTitle: "Visitasi",
          namaTodoList: "Konfirmasi Bank",
          projectId: y,
        },
        {
          namaTitle: "Visitasi",
          namaTodoList: "Dokumentasi Foto",
          projectId: y,
        },
        {
          namaTitle: "Finalisasi",
          namaTodoList: "Draft Final",
          projectId: y,
        },
        {
          namaTitle: "Finalisasi",
          namaTodoList: "Surat Persetujuan Draft",
          projectId: y,
        },
        {
          namaTitle: "Finalisasi",
          namaTodoList: "Surat Pernyataan Direksi",
          projectId: y,
        },
        {
          namaTitle: "Finalisasi",
          namaTodoList: "Surat Setuju/ Tidak Pelaporan",
          projectId: y,
        },
        {
          namaTitle: "Finalisasi",
          namaTodoList: "Neraca TTD",
          projectId: y,
        },
        {
          namaTitle: "Finalisasi",
          namaTodoList: "Laba Rugi TTD",
          projectId: y,
        },
        {
          namaTitle: "Finalisasi",
          namaTodoList: "Opini",
          projectId: y,
        },
        {
          namaTitle: "Finalisasi",
          namaTodoList: "Manajemen Letter",
          projectId: y,
        },
        {
          namaTitle: "Release",
          namaTodoList: "Laporan Audit Final (HC)",
          projectId: y,
        },
        {
          namaTitle: "Release",
          namaTodoList: "Laporan Audit Final(SC)",
          projectId: y,
        },
        {
          namaTitle: "Release",
          namaTodoList: "Faktur Pengeluaran (PPN)",
          projectId: y,
        },
        {
          namaTitle: "Release",
          namaTodoList: "Bupot PPh 23",
          projectId: y,
        },
      ];

      axios
        .post(
          "todoList/register",
          {
            bulk: forms,
          },
          {
            headers: { token: x },
          }
        )
        .then((res) => {
          if (res.data.message == "sukses") {
            this.getList();
          }
        });
    },

    tambahList(nama) {
      this.namanya = nama;
      this.$refs["modal-tambah"].show();
    },

    doTambah() {
      let vm = this;
      let x = localStorage.getItem("token");
      let y = localStorage.getItem("idProject");
      axios
        .post(
          "todoList/register",
          {
            bulk: [
              {
                namaTitle: vm.namanya,
                namaTodoList: vm.namaListBaru,
                bisaDelete: 0,
                projectId: y,
              },
            ],
          },
          {
            headers: { token: x },
          }
        )
        .then(() => {
          this.getList();
          this.$refs["modal-tambah"].hide();
          vm.pesan = "Berhasil Menambahkan List";
          vm.alert2 = true;
          setTimeout(() => {
            vm.alert2 = false;
          }, 3000);
        });
    },

    checkMark(ids, nam, val) {
      let x = localStorage.getItem("token");
      let y = localStorage.getItem("idProject");
      axios.post(
        "todoList/editCheckmark",
        {
          id: ids,
          statusTodoList: val,
          projectId: y,
          namaTodoList: nam,
        },
        {
          headers: { token: x },
        }
      );
    },

    uploadFile() {
      let vm = this;
      var formData = new FormData();
      let idProject = localStorage.getItem("idProject");
      let tahun = this.stateProject.tahunProject;
      let x = localStorage.getItem("token");
      this.loading = true;
      formData.append("projectId", idProject);
      formData.append("tahun", tahun);
      if (vm.fileUpload) {
        formData.append("excelFile", vm.fileUpload);
      }
      axios({
        method: "post",
        url: "excel/insert",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: x,
        },
      }).then(() => {
        this.fileUpload = "";
        this.loading = false;
        vm.pesan = "sukses mengupload file";
        vm.alert4 = true;
        setTimeout(() => {
          vm.alert4 = false;
        }, 3000);
      });
    },

    uploadAset() {
      let vm = this;
      let formData = new FormData();
      let idProject = localStorage.getItem("idProject");
      let tokens = localStorage.getItem("token");
      vm.loading = true;

      formData.append("projectId", idProject);
      formData.append("asetTetap", vm.fileAset);
      formData.append("tahunProject", localStorage.getItem("tahunProject"));
      formData.append("tahunPembanding", vm.tahunAsetTetap.text);

      axios
        .post("excel/uploadAsetTetap", formData, {
          headers: {
            token: tokens,
          },
        })
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.loading = false;
            vm.alert4 = true;
            vm.pesan = "BERHASIL MENGUNGGAH DOKUMEN ASET TETAP";
            vm.fileAset = [];
            vm.tahunAsetTetap = "";
            setTimeout(() => {
              vm.alert4 = false;
            }, 3000);
          } else {
            vm.loading = false;
            vm.alert = true;
            vm.pesan = res.data.message;
            vm.fileAset = [];
            setTimeout(() => {
              vm.alert = false;
            }, 3000);
          }
        })
        .catch(() => {
          vm.loading = false;
          vm.alert = true;
          vm.pesan = "TERJADI KESALAHAN PADA SERVER";
          vm.fileAset = [];
          setTimeout(() => {
            vm.alert = false;
          }, 3000);
        });
    },

    edit() {
      this.namaKAP = this.stateProject.namaKAP;
      this.namaAkuntanPublik = this.stateProject.namaAkuntanPublik;
      this.namaAuditor = this.stateProject.namaAuditor;
      this.namaReviewer = this.stateProject.namaReviewer;
      this.namaPenyusun = this.stateProject.namaPenyusun;
      this.tanggalPenyusunan = this.stateProject.tanggalPenyusunan;
      this.tanggalReview = this.stateProject.tanggalReview;
      this.direkturUtama = this.stateProject.direkturUtama;
      this.direktur = this.stateProject.direktur;
      this.komisaris = this.stateProject.komisaris;
      this.tanggalBerakhir = this.stateProject.tanggalBerakhir;
      this.namaPenandaTangan = this.stateProject.namaPenandaTangan;
      this.kotaTandaTangan = this.stateProject.kotaTandaTangan;
      this.tanggalTandaTangan = this.stateProject.tanggalTandaTangan;
      this.jabatanPenandaTangan = this.stateProject.jabatanPenandaTangan;
    },

    ubah() {
      let vm = this;
      let x = localStorage.getItem("token");
      let y = localStorage.getItem("idProject");
      vm.loading = true;
      axios
        .post(
          "project/update/" + y,
          {
            namaAuditor: vm.namaAuditor,
            namaReviewer: vm.namaReviewer,
            namaPenyusun: vm.namaPenyusun,
            namaAkuntanPublik: vm.namaAkuntanPublik,
            tanggalPenyusunan: vm.tanggalPenyusunan,
            tanggalReview: vm.tanggalReview,
            direkturUtama: vm.direkturUtama,
            direktur: vm.direktur,
            komisaris: vm.komisaris,
            tanggalBerakhir: vm.tanggalBerakhir,
            namaPenandaTangan: vm.namaPenandaTangan,
            jabatanPenandaTangan: vm.jabatanPenandaTangan,
            kotaTandaTangan: vm.kotaTandaTangan,
            tanggalTandaTangan: vm.tanggalTandaTangan,
          },
          {
            headers: { token: x },
          }
        )
        .then(() => {
          vm.loading = false;
          vm.pesan = "data berhasil diubah";
          vm.alert2 = true;
          setTimeout(() => {
            vm.alert2 = false;
          }, 3000);
          this.$store.dispatch("apiProject/getData");
        });
    },

    download() {
      var pem = localStorage.getItem("pembanding");
      if (pem == "" || !pem) {
        this.pesan = "Pilih Tahun Pembanding Terlebih Dahulu!";
        this.alert = true;
        setTimeout(() => {
          this.alert = false;
        }, 4000);
      } else if(this.checkLengkap() == false){
        this.pesan = "Detail masih belum lengkap!";
        this.alert = true;
        setTimeout(() => {
          this.alert = false;
        }, 4000);
      } else if(this.checkTanggal() == false){
        this.pesan = "Tanggal masih belum lengkap! Reload halaman apabila sudah";
        this.alert = true;
        setTimeout(() => {
          this.alert = false;
        }, 4000);
      } else {
        let buatPembanding = JSON.parse(pem);
        window.open(
          "https://engine.kapmobile.id/" +
            "project/downloadexcel/" +
            this.$route.params.id +
            "/" +
            buatPembanding.value
        );
      }
    },

    checkLengkap(){
      if(this.namaKAP == ""|| this.namaKAP == "") return false;  
      if(this.namaAkuntanPublik == "" || this.namaAkuntanPublik == "") return false;  
      if(this.namaAuditor == null || this.namaAuditor == "") return false; 
      if(this.namaPenyusun == null || this.namaPenyusun == "") return false; 
      if(this.tanggalPenyusunan == null || this.tanggalPenyusunan == "") return false;
      if(this.namaReviewer == null || this.namaReviewer == "") return false; 
      if(this.tanggalReview == null || this.tanggalReview == "") return false; 
      if(this.tanggalBerakhir == null || this.tanggalBerakhir == "") return false; 
      if(this.namaPenandaTangan == null || this.namaPenandaTangan == "") return false; 
      if(this.jabatanPenandaTangan == null || this.jabatanPenandaTangan == "") return false; 
      if(this.kotaTandaTangan == null || this.kotaTandaTangan == "") return false;
      if(this.tanggalTandaTangan == null || this.tanggalTandaTangan == "") return false;
      return true;
    },

    checkTanggal(){
      if(this.tanggalMulai == null || this.tanggalMulai == "") return false;
      // if(this.deadline == null || this.deadline == "") return false;
      return true;
    },

    downloadAset() {
      window.open(
        "https://engine.kapmobile.id/" +
          "excel/downloadAset/" +
          this.$route.params.id
      );
    },

    download2() {
      let x = localStorage.getItem("token");
      window.open(
        "https://engine.kapmobile.id/" + "excel/download/" + this.$route.params.id,
        {
          headers: { token: x },
        }
      );
    },

    async hapus(aidi) {
      this.idDelete = aidi.idTodoList;
      this.$refs["modal-hapus"].show();
    },

    doDelete() {
      let vm = this;
      let x = localStorage.getItem("token");
      let id = vm.idDelete;
      axios
        .post(
          "todoList/delete",
          {
            id: id,
          },
          {
            headers: { token: x },
          }
        )
        .then(() => {
          this.$refs["modal-hapus"].hide();
          vm.getList();
          vm.pesan = "akun berhasil dihapus";
          vm.alert = true;
          setTimeout(() => {
            vm.alert = false;
          }, 3000);
        });
    },

    batalDelete() {
      this.$refs["modal-hapus"].hide();
    },

    tutupModal() {
      this.$refs["modal-tambah"].hide();
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");

$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white": #fafafa,
  "white2": #fff,
  "gray-dark": #b5bac3,
  "blue-light": #e8f3fe,
  "red-light": #ffe8e7,
  "green-light": #eafffa,
  "green": #6adbbe,
);

.wrapper {
  position: relative;

  .tab {
    .btnActive {
      font-family: "nunito", sans-serif;
      color: map-get($color, "blue");
    }
  }

  .table {
    min-width: 100%;
    max-width: 100%;
    font-family: "nunito", sans-serif;
    font-size: 13px;
    overflow: scroll;
    overflow-x: auto;
    min-height: 57vh;
    max-height: 57vh;
    border-radius: 7px;
    border: 1px solid map-get($color, "gray-dark");
    table {
      min-width: 100%;
      font-family: "Nunito", sans-serif;
    }
    .button-wrapper2 {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      justify-content: space-around;
      .tambah {
        background-color: map-get($color, "blue");
        padding: 5%;
        cursor: pointer;
        border-radius: 4px;
        display: flex;
        align-items: center;
        height: clamp(24px, 28px, 32px);
      }
      .edit {
        background-color: #f4b366;
        padding: 5%;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        height: clamp(24px, 28px, 32px);
      }
      .hapus {
        background-color: #e0544e;
        cursor: pointer;
        padding: 5%;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: clamp(24px, 28px, 32px);
      }
    }
  }
  .alert {
    position: fixed;
    z-index: 100;
    bottom: 10%;
    right: 0;
    left: 0;
    margin: auto;
    // width: 60%;
  }
  .alert2 {
    position: fixed;
    // width: 80%;
    bottom: 10%;
    right: 0;
    left: 0;
    margin: auto;
  }
}
</style>
