<template>
  <div class="wrapper">
    <div
      style="width: 100%; height: 50px"
      class="d-md-block d-lg-block d-xl-none"
    ></div>
    <div class="tab">
      <b-tabs
        style="font-family: 'Nunito', sans-serrif; font-weight: 600"
        content-class="mt-3"
        fill
      >
        <b-tab @click="flags = 'aset'" title="Aset" active></b-tab>
        <b-tab @click="flags = 'kewajiban'" title="Kewajiban"></b-tab>
        <b-tab @click="flags = 'ekuitas'" title="Ekuitas"></b-tab>
        <b-tab @click="flags = 'pendapatan'" title="Pendapatan"></b-tab>
        <b-tab @click="flags = 'beban'" title="Beban"></b-tab>
        <b-tab @click="flags = 'lembarKerja'" title="Semua"></b-tab>
      </b-tabs>
    </div>
    <b-modal hide-footer hide-header centered ref="modal-tambah2" size="lg">
      <div class="tutup" style="display: flex; justify-content: flex-end">
        <b-button
          @click="tutupModal3"
          style="background-color: white; border: none"
          ><img
            src="../../assets/icon/close.svg"
            alt=""
            style="width: 15px; height: 15px"
        /></b-button>
      </div>
      <div
        class="content"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 1% 2%;
        "
      >
        <span
          class="tittle"
          style="
            font-family: 'nunito', sans-serif;
            font-size: 28px;
            font-weight: 700;
            text-transform: capitalize;
          "
        >
          Buat Sub Akun
        </span>
        <div class="form" style="width: 95%; margin-top: 4%">
          <b-form-group
            label-size="lg"
            label-cols="4"
            label-cols-lg="3"
            label="Nama Akun"
            label-for="input-formatter"
          >
            <b-form-input
              id="input-formatter"
              placeholder="Nama Akun"
              style="border-radius: 10px; margin-bottom: 15px"
              size="lg"
              type="text"
              v-model="namaAkunSub"
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            label-size="lg"
            label-cols="4"
            label-cols-lg="3"
            label="Kode Akun"
            label-for="input-formatter"
          >
            <b-form-input
              id="input-formatter"
              placeholder="Kode Akun"
              style="border-radius: 10px; margin-bottom: 15px"
              size="lg"
              type="text"
              v-model="kodeSubHead"
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            label-size="lg"
            label-cols="4"
            label-cols-lg="3"
            label="Kategori Akun"
            label-for="input-formatter"
          >
            <b-form-input
              id="input-formatter"
              placeholder="Kategori Akun"
              style="border-radius: 10px; margin-bottom: 15px"
              size="lg"
              type="number"
              v-model="kategoriAkun"
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            label-size="lg"
            label-cols="4"
            label-cols-lg="3"
            label="CLK Akun"
            label-for="input-formatter"
          >
            <b-form-input
              id="input-formatter"
              placeholder="CLK Akun"
              style="border-radius: 10px; margin-bottom: 15px"
              size="lg"
              type="number"
              v-model="CLKAkun"
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            label-size="lg"
            label-cols="4"
            label-cols-lg="3"
            label="PAAK Akun"
            label-for="input-formatter"
          >
            <b-form-input
              id="input-formatter"
              placeholder="PAAK Akun"
              style="border-radius: 10px; margin-bottom: 15px"
              size="lg"
              type="text"
              v-model="PAAKAkun"
            >
            </b-form-input>
          </b-form-group>
        </div>
      </div>
      <div
        class="wrapper"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 0 2%;
        "
      >
        <div
          class="button-wrapper"
          style="display: flex; width: 95%; justify-content: flex-end"
        >
          <b-button
            @click="send()"
            v-if="loading == false"
            style="
              background-color: #327ff2;
              color: #fff;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              width: 21%;
              border: none;
              margin-top: 20px;
              height: 40px;
            "
            >Tambah Akun</b-button
          >
          <b-button
            disabled
            v-else
            style="
              background-color: #327ff2;
              color: #fff;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              width: 25%;
              border: none;
              margin-top: 20px;
              height: 40px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
            ><white-spinner
          /></b-button>
        </div>
      </div>
    </b-modal>
    <b-modal hide-footer hide-header centered ref="modal-tambah3" size="lg">
      <div class="tutup" style="display: flex; justify-content: flex-end">
        <b-button
          @click="tutupModal4"
          style="background-color: white; border: none"
          ><img
            src="../../assets/icon/close.svg"
            alt=""
            style="width: 15px; height: 15px"
        /></b-button>
      </div>
      <div
        class="content"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 1% 2%;
        "
      >
        <span
          class="tittle"
          style="
            font-family: 'nunito', sans-serif;
            font-size: 28px;
            font-weight: 700;
            text-transform: capitalize;
          "
        >
          Buat Sub Akun
        </span>
        <div class="form" style="width: 95%; margin-top: 4%">
          <b-form-group
            label-size="lg"
            label-cols="4"
            label-cols-lg="3"
            label="Nama Akun"
            label-for="input-formatter"
          >
            <b-form-input
              id="input-formatter"
              placeholder="Nama Akun Sub"
              style="border-radius: 10px; margin-bottom: 15px"
              size="lg"
              type="text"
              v-model="namaAkunSub"
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            label-size="lg"
            label-cols="4"
            label-cols-lg="3"
            label="Kode Akun"
            label-for="input-formatter"
          >
            <b-form-input
              id="input-formatter"
              placeholder="Kode Akun"
              style="border-radius: 10px; margin-bottom: 15px"
              size="lg"
              type="text"
              v-model="kodeSubHead"
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            label-size="lg"
            label-cols="4"
            label-cols-lg="3"
            label="Nominal Akun"
            label-for="input-formatter"
          >
            <b-form-input
              id="input-formatter"
              placeholder="Nominal Akun"
              style="border-radius: 10px; margin-bottom: 15px"
              size="lg"
              type="number"
              v-model="nominalAkun"
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            label-size="lg"
            label-cols="4"
            label-cols-lg="3"
            label="Kategori Akun"
            label-for="input-formatter"
          >
            <b-form-input
              id="input-formatter"
              placeholder="Kategori Akun"
              style="border-radius: 10px; margin-bottom: 15px"
              size="lg"
              type="number"
              v-model="kategoriAkun"
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            label-size="lg"
            label-cols="4"
            label-cols-lg="3"
            label="CLK Akun"
            label-for="input-formatter"
          >
            <b-form-input
              id="input-formatter"
              placeholder="CLK Akun"
              style="border-radius: 10px; margin-bottom: 15px"
              size="lg"
              type="number"
              v-model="CLKAkun"
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            label-size="lg"
            label-cols="4"
            label-cols-lg="3"
            label="PAAK Akun"
            label-for="input-formatter"
          >
            <b-form-input
              id="input-formatter"
              placeholder="PAAK Akun"
              style="border-radius: 10px; margin-bottom: 15px"
              size="lg"
              type="text"
              v-model="PAAKAkun"
            >
            </b-form-input>
          </b-form-group>
        </div>
      </div>
      <div
        class="wrapper"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 0 2%;
        "
      >
        <div
          class="button-wrapper"
          style="display: flex; width: 95%; justify-content: flex-end"
        >
          <b-button
            @click="send2()"
            v-if="loading == false"
            style="
              background-color: #327ff2;
              color: #fff;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              width: 21%;
              border: none;
              margin-top: 20px;
              height: 40px;
            "
            >Tambah Akun</b-button
          >
          <b-button
            disabled
            v-else
            style="
              background-color: #327ff2;
              color: #fff;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              width: 25%;
              border: none;
              margin-top: 20px;
              height: 40px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
            ><white-spinner
          /></b-button>
        </div>
      </div>
    </b-modal>

    <b-modal
      class="hapus"
      hide-footer
      hide-header
      centered
      ref="modal-hapus"
      size="sm"
    >
      <div
        class="content"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          padding: 1% 2%;
        "
      >
        <span
          class="tittle"
          style="
            font-family: 'nunito', sans-serif;
            font-size: 28px;
            font-weight: 700;
            text-transform: capitalize;
          "
        >
          Hapus Akun
        </span>
      </div>
      <div
        class="wrapper"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 0 2%;
        "
      >
        <div
          class="button-wrapper"
          style="display: flex; width: 95%; justify-content: flex-end"
        >
          <b-button
            @click="doDelete()"
            v-if="loading == false"
            style="
              background-color: #327ff2;
              border-radius: 10px;
              margin-right: 10px;
              font-size: 14px;
              border: none;
              color: map-get($color, 'white2');
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              height: 28px;
              display: flex;
              align-items: center;
              box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
            "
            >Hapus Akun</b-button
          >
          <b-button
            disabled
            v-else
            style="
              background-color: #327ff2;
              color: #fff;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              width: 50px;
              height: 25px;
              border: none;
              margin-top: 20px;
              margin-right: 20px
              height: 40px;
            "
            ><white-spinner
          /></b-button>
          <b-button
            @click="batalDelete()"
            v-if="loading == false"
            style="
              background-color: red;
              border-radius: 10px;
              margin-right: 10px;
              font-size: 14px;
              border: none;
              color: map-get($color, 'white2');
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              height: 28px;
              display: flex;
              align-items: center;
              box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
            "
            >Batalkan</b-button
          >
          <b-button
            disabled
            v-else
            style="
              background-color: #327ff2;
              color: #fff;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              width: 50px;
              height: 25px;
              border: none;
              margin-top: 20px;
              height: 40px;
            "
            ><white-spinner
          /></b-button>
        </div>
      </div>
    </b-modal>
    <b-modal hide-footer hide-header centered ref="modal-mark" size="lg">
      <div class="tutup" style="display: flex; justify-content: flex-end">
        <b-button
          @click="tutupModal7"
          style="background-color: white; border: none"
          ><img
            src="../../assets/icon/close.svg"
            alt=""
            style="width: 15px; height: 15px"
        /></b-button>
      </div>
      <div
        class="content"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 1% 2%;
        "
      >
        <span
          class="tittle"
          style="
            font-family: 'nunito', sans-serif;
            font-size: 28px;
            font-weight: 700;
            text-transform: capitalize;
          "
        >
          Mark Tick
        </span>
        <div class="form" style="width: 95%; margin-top: 4%">
          <b-form-group>
            <b-form-checkbox-group
              v-if="row == 4"
              v-model="selected"
              :options="options"
              switches
              stacked
            ></b-form-checkbox-group>
          </b-form-group>
          <b-form-group
            label-size="lg"
            label-cols="4"
            label-cols-lg="2"
            label="Keterangan"
            label-for="input-formatter"
          >
            <b-form-textarea
              placeholder="Keterangan"
              style="border-radius: 10px"
              size="lg"
              rows="3"
              max-rows="6"
              v-model="keterangan"
            ></b-form-textarea>
          </b-form-group>
        </div>
      </div>
      <div
        class="wrapper"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 0 2%;
        "
      >
        <div
          class="button-wrapper"
          style="display: flex; width: 95%; justify-content: flex-end"
        >
          <b-button
            @click="doMark()"
            v-if="loading == false"
            style="
              background-color: #327ff2;
              color: #fff;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              padding: 0 2%;
              border: none;
              margin-top: 20px;
              height: 40px;
            "
            >Simpan</b-button
          >
          <b-button
            disabled
            v-else
            style="
              background-color: #327ff2;
              color: #fff;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              width: 25%;
              border: none;
              margin-top: 20px;
              height: 40px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
            ><white-spinner
          /></b-button>
        </div>
      </div>
    </b-modal>

    <div class="buttons2">
      <div class="btn-container">
        <b-button @click="closeAll">Head Akun</b-button>
        <b-button @click="open(1), (show = true)"> 2</b-button>
        <b-button @click="open(2), (show = true)"> 3</b-button>
        <b-button @click="open(3), (show = true)"> 4</b-button>
        <b-button @click="open(4), (show = true)"> 5</b-button>
      </div>
      <div class="filter">
        <b-form-group id="input-group-1">
          <b-form-input
            id="input-1"
            class="input"
            v-model="filter"
            @mouseenter="filter = ''"
            type="text"
            placeholder="Filter"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>

    <b-overlay :show="show" spinner-variant="primary" rounded="sm">
      <div class="table">
        <vue-ads-table
          :columns="columns"
          :rows="rows"
          :filter="filter"
          :start="start"
          :end="end"
          @filter-change="filterChanged"
          :classes="classes"
          :itemPerPage="12"
          :page="page"
          @page-change="pageChanged"
          top
        >
          <!-- <template slot="sort-icon" slot-scope="props">
            ({{
              props.direction === null
                ? "null"
                : props.direction
                ? "up"
                : "down"
            }})
          </template> -->

          <template slot="toggle-children-icon" slot-scope="props">
            [{{ props.expanded ? "-" : "+" }}]
          </template>
          <template slot="aksi" slot-scope="props">
            <div class="button-wrapper2">
              <div
                class="tambah"
                v-if="props.row.row != 4"
                @click="tambahChildren(props.row.row, props.row.id, props.row)"
              >
                <img src="../../assets/icon/icon/plus-circle.svg" alt="" />
              </div>

              <div class="edit" @click="editAkun(props.row)">
                <img src="../../assets/icon/edit-2.svg" alt="" />
              </div>

              <div
                class="hapus"
                v-if="role == 'adminKAP'"
                @click="hapus(props.row.row, props.row.id)"
              >
                <img src="../../assets/icon/trash.svg" alt="" />
              </div>
            </div>
          </template>
          <template slot="mark" slot-scope="props">
            <div
              class="button-wrapper3"
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
              "
            >
              <span class="tes" @click="tambahMark(props.row)">Mark</span>
              <img
                v-if="props.row.keterangan != null"
                src="../../assets/icon/star.svg"
                alt=""
                style="width: 15px; height: 15px"
              />
            </div>
          </template>
          <template slot="prosentase" slot-scope="props">
            <span style="color: red" v-if="props.row.check < -50"
              >{{ props.row.prosentase }}▼</span
            >
            <span
              style="color: #e100ff"
              v-else-if="props.row.check < -0 && props.row.check >= -50"
              >{{ props.row.prosentase }}▼</span
            >
            <span style="color: #f4b366" v-else-if="props.row.check == 0">
              =
            </span>
            <span
              style="color: #6adbbe"
              v-else-if="props.row.check > 0 && props.row.check <= 50"
              >{{ props.row.prosentase }}▲</span
            >
            <span style="color: green" v-else-if="props.row.check > 50"
              >{{ props.row.prosentase }}▲</span
            >
          </template>
          <template slot="nomor" slot-scope="props">
            <span>{{ props.row.nomor }}</span>
          </template>
        </vue-ads-table>
      </div>

      <!-- <div :aria-hidden="show ? 'true' : null" class="table" v-else>
        <vue-ads-table
          :columns="columns"
          :rows="rows"
          :filter="filter"
          :start="start"
          :end="end"
          @filter-change="filterChanged"
          :classes="classes"
          :itemPerPage="12"
          :page="page"
          @page-change="pageChanged"
          top
        >
          <template slot="sort-icon" slot-scope="props">
            ({{
              props.direction === null
                ? "null"
                : props.direction
                ? "up"
                : "down"
            }})
          </template>
          <template slot="toggle-children-icon" slot-scope="props">
            [{{ props.expanded ? "-" : "+" }}]
          </template>
          <template slot="aksi" slot-scope="props">
            <div class="button-wrapper2">
              <div
                class="tambah"
                v-if="props.row.row != 4"
                @click="tambahChildren(props.row.row, props.row.id, props.row)"
              >
                <img src="../../assets/icon/icon/plus-circle.svg" alt="" />
              </div>
              <div class="edit" @click="editAkun(props.row)">
                <img src="../../assets/icon/edit-2.svg" alt="" />
              </div>
              <div
                class="hapus"
                v-if="role == 'adminKAP'"
                @click="hapus(props.row.row, props.row.id)"
              >
                <img src="../../assets/icon/trash.svg" alt="" />
              </div>
            </div>
          </template>
          <template slot="mark" slot-scope="props">
            <div
              class="button-wrapper3"
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
              "
            >
              <span class="tes" @click="tambahMark(props.row)">Mark</span>
              <img
                v-if="props.row.keterangan != null"
                src="../../assets/icon/star.svg"
                alt=""
                style="width: 15px; height: 15px"
              />
            </div>
          </template>
          <template slot="prosentase" slot-scope="props">
            <span style="color: red" v-if="props.row.check < -50"
              >{{ props.row.prosentase }}▼</span
            >
            <span
              style="color: #e100ff"
              v-else-if="props.row.check < -0 && props.row.check >= -50"
              >{{ props.row.prosentase }}▼</span
            >
            <span style="color: #f4b366" v-else-if="props.row.check == 0">
              =</span
            >
            <span
              style="color: #6adbbe"
              v-else-if="props.row.check > 0 && props.row.check <= 50"
              >{{ props.row.prosentase }}▲</span
            >
            <span style="color: green" v-else-if="props.row.check > 50"
              >{{ props.row.prosentase }}▲</span
            >
          </template>
          <template slot="sort-icon" slot-scope="props">
            ({{
              props.direction === null
                ? "null"
                : props.direction
                ? "up"
                : "down"
            }})
          </template>
        </vue-ads-table>
      </div> -->
    </b-overlay>
    <!-- <Modal2 /> -->
    <Modal1 />
    <ModalEdit :data="akunHeadRow" @triggerAlert="triggerAlert($event)" />
    <ModalEdit1 :data="akunSubHeadRow" @triggerAlert="triggerAlert($event)" />
    <ModalEdit2
      :data="akunSubHeadSubRow"
      @triggerAlert="triggerAlert($event)"
    />
    <ModalEdit3
      :data="akunSublifikasiRow"
      @triggerAlert="triggerAlert($event)"
    />
    <ModalEdit4 :data="akunSubsifikRow" @triggerAlert="triggerAlert($event)" />
    <div class="button-wrapper14">
      <b-button v-if="loading1 == false" @click="flag = !flag"
        ><modal-1 @refresh="getAkuns" :load="rows" :flag="flag"/>
        <img src="../../assets/icon/plus-circle.svg" alt=""
      /></b-button>
    </div>
    <div class="alert">
      <b-alert
        dismissible
        style="width: 50%"
        variant="danger"
        fade
        :show="alert"
      >
        {{ pesan }}
      </b-alert>
      <b-alert
        dismissible
        style="width: 50%"
        variant="success"
        fade
        :show="alert2"
      >
        {{ pesan }}
      </b-alert>
    </div>
  </div>
</template>

<script>
import axios from "@/ip_config.js";
import { mapState } from "vuex";
import accounting from "accounting-js";
import nav_bar from "../nav_bar.vue";
import { VueAdsTable } from "vue-ads-table-tree";
import "../../../node_modules/vue-ads-table-tree/dist/vue-ads-table-tree.css";
import _ from "lodash";
import Modal1 from "./modal1.vue";
// import Modal2 from "./modal2.vue";
import ModalEdit from "./modalEdit.vue";
import ModalEdit1 from "./modalEdit1.vue";
import ModalEdit2 from "./modalEdit2.vue";
import ModalEdit3 from "./modalEdit3.vue";
import ModalEdit4 from "./modalEdit4.vue";
let data1 = [];
export default {
  components: {
    VueAdsTable,
    nav_bar,
    Modal1,
    // Modal2,
    ModalEdit,
    ModalEdit1,
    ModalEdit2,
    ModalEdit3,
    ModalEdit4,
  },
  computed: {
    ...mapState("apiProject", ["stateProject", "role"]),
  },
  data() {
    let classes = {
      table: {
        "vue-ads-border": true,
        "vue-ads-w-full": true,
        "vue-ads-font-bold": true,
      },
      info: {
        "vue-ads-text-center": true,
        "vue-ads-py-6": true,
        "vue-ads-text-sm": true,
        "vue-ads-border-t": true,
      },
      group: {
        "vue-ads-border-b": true,
        "vue-ads-italic": true,
      },
      selected: {
        "vue-ads-bg-teal-100": true,
      },
      "all/": {
        "hover:vue-ads-bg-gray-200": true,
      },
      "all/all": {
        "vue-ads-pxvue-ads-px-0": true,
        "vue-ads-text-sm": true,
      },
      "even/": {
        "vue-ads-bg-white": true,
      },
      "odd/": {
        "vue-ads-bg-gray-100": true,
      },
      "0/all": {
        "vue-ads-text-center": true,
      },
      "0_-1/": {
        "vue-ads-border-b": true,
      },
      "/0_-1": {
        "vue-ads-border-r": true,
      },
      "1_/": {
        "vue-ads-text-blue": true,
      },
      "_-1": {
        "vue-ads-text-blue": true,
      },
    };
    let rows = [];
    let columns = [
      {
        property: "kodeAkun",
        title: "Kode Akun",
      },
      {
        property: "akun",
        title: "Nama Akun",
        filterable: true,
        collapseIcon: true,
        direction: null,
      },
      {
        property: "mark",
        title: "Mark",
        filterable: true,
        direction: null,
      },
      {
        property: "total",
        title: "Saldo",
        filterable: true,
        direction: null,
      },
      {
        property: "debit",
        title: "Debet",
        filterable: true,
        direction: null,
      },
      {
        property: "kredit",
        title: "Kredit",
        filterable: true,
        direction: null,
      },
      {
        property: "total_akhir",
        title: "Saldo Akhir",
        filterable: true,
        direction: null,
      },
      {
        property: "prosentase",
        title: "%",
        direction: null,
      },
      {
        property: "pembanding",
        title: "Pembanding",
        filterable: true,
        direction: null,
      },
      {
        property: "aksi",
        title: "Aksi",
        filterable: true,
        direction: null,
      },
      {
        property: "nomor",
        title: "#",
        direction: true,
        // visible: false,
      },
    ];
    return {
      columns,
      rows,
      rows2: [],
      loading: false,
      loading1: false,
      currentPage: 1,
      items: [],
      namaAkunHead: "",
      namaAkunChild: "",
      namaAkunSub: "",
      nomer: "",
      akunId: "",
      perPage: 11,
      page: 0,
      filter: "",
      start: 0,
      semua: false,
      end: 10000,
      classes,
      row: "",
      id: "",
      fileUpload: [],
      kodeSubHead: "",
      kodeHead: "",
      nominalAkun: "",
      alert: false,
      alert2: false,
      pesan: "",
      _children: [],
      project: [],
      idProject: 0,
      tahunPembanding: "-",
      idPembanding: 0,
      kodenya: "",
      selected: [],
      keterangan: "",
      show: false,
      kategori: "",
      rownya: "",
      ids: "",
      load: [],
      flags: "aset",
      flag: false,
      options: [
        { text: "Sesuai dengan Buku Besar", value: "bukuBesar" },
        { text: "Sesuai buku Kas harian", value: "kasHarian" },
        { text: "Telah dilakukan cash opname", value: "cashOpname" },
        {
          text: "Telah dilakukan sample konfirmasi",
          value: "sampelKonfirmasi",
        },
        { text: "Penjumlahan telah di cek ulang", value: "cekUlang" },
        { text: "Telah dilakukan stock opname", value: "stockOpname" },
        {
          text: "Telah dilakukan vouching ke dokumen pendukung",
          value: "vouchingDocument",
        },
      ],
      kategoriAkun: null,
      CLKAkun: null,
      PAAKAkun: "",
      akunHeadRow: {
        kodeAkunHead: null,
        keteranganHead: null,
        kategoriHead: null,
        namaAkunHead: null,
        nominalAkunHead: null,
        idAkunHead: null,
      },
      akunSubHeadRow: {
        namaAkunSubHead: null,
        kodeAkunSubHead: null,
        keteranganSubHead: null,
        kategoriSubHead: null,
        CLKSubHead: null,
        PAAKSubHead: null,
        idAkunSubHead: null,
      },
      akunSubHeadSubRow: {
        namaAkunSubHeadSub: null,
        kodeAkunSubHeadSub: null,
        keteranganSubHeadSub: null,
        kategoriSubHeadSub: null,
        CLKSubHeadSub: null,
        PAAKSubHeadSub: null,
        idAkunSubHeadSub: null,
      },
      akunSublifikasiRow: {
        namaAkunSublifikasi: null,
        kodeAkunSublifikasi: null,
        idAkunSublifikasi: null,
        keteranganSublifikasi: null,
        kategoriSublifikasi: null,
        CLKSublifikasi: null,
        PAAKSublifikasi: null,
        nominalAkunSublifikasi: null,
        haveChildren: null,
      },
      akunSubsifikRow: {
        namaAkunSubsifik: null,
        kodeSubsifik: null,
        idAkunSubsifik: null,
        keteranganSubsifik: null,
        kategoriSubsifik: null,
        CLKSubsifik: null,
        PAAKSubsifik: null,
        nominalAkunSubsifik: null,
      },
    };
  },
  async mounted() {
    await this.getAkuns();
    this.aset(data1);
  },
  watch: {
    flags(newVal, oldVal) {
      if (newVal != oldVal) {
        if (this.flags == "aset") {
          this.aset(data1);
        } else if (this.flags == "kewajiban") {
          this.kewajiban(data1);
        } else if (this.flags == "ekuitas") {
          this.ekuitas(data1);
        } else if (this.flags == "pendapatan") {
          this.pendapatan(data1);
        } else if (this.flags == "beban") {
          this.beban(data1);
        } else if (this.flags == "lembarKerja") {
          this.lembarKerja(data1);
        }
      }
    },
    // rows(newVal, oldVal) {
    //   if (newVal != oldVal) {
    //     this.show = false;
    //   }
    // },
  },
  methods: {
    // async getSubs() {
    //   this.rows = [];
    //   let gets = await axios.get(
    //     ip_server + "akun/subsifik/listAkunSubsifik/" + this.$route.params.id,
    //     {
    //       headers: { token: localStorage.getItem("token") },
    //     }
    //   );
    //   let arr = gets.data.data[0];
    //   this.rows = arr.filter((item) => {
    //     return (
    //       item.kategori == 1 ||
    //       item.kategori == 2 ||
    //       item.kategori == 3 ||
    //       item.kategori == 4 ||
    //       item.kategori == 5
    //     );
    //   });
    //   this.show = false;
    // },
    open(opens) {
      // this.show = true;
      let datas = JSON.parse(JSON.stringify(data1));
      let forRows = [];
      // let x = lodash.sortBy(datas);
      // console.log(x);
      setTimeout(() => {
        if (this.flags == "aset") {
          for (let i = 0; i < datas.length; i++) {
            let arr1 = datas[i]._children;
            for (let f = 0; f < arr1.length; f++) {
              let arr2 = arr1[f]._children;
              if (opens == 1) {
                if (
                  datas[i].kategori == 1 ||
                  datas[i].kategori == 2 ||
                  datas[i].kategori == 3 ||
                  datas[i].kategori == 4 ||
                  datas[i].kategori == 5
                ) {
                  forRows.push(arr1[f]);
                }
              } else if (opens > 1) {
                for (let s = 0; s < arr2.length; s++) {
                  let arr3 = arr2[s]._children;
                  if (opens == 2) {
                    if (
                      datas[i].kategori == 1 ||
                      datas[i].kategori == 2 ||
                      datas[i].kategori == 3 ||
                      datas[i].kategori == 4 ||
                      datas[i].kategori == 5
                    ) {
                      forRows.push(arr2[s]);
                    }
                  } else {
                    for (let t = 0; t < arr3.length; t++) {
                      let arr4 = arr3[t]._children;
                      if (opens == 3) {
                        if (
                          datas[i].kategori == 1 ||
                          datas[i].kategori == 2 ||
                          datas[i].kategori == 3 ||
                          datas[i].kategori == 4 ||
                          datas[i].kategori == 5
                        ) {
                          forRows.push(arr3[t]);
                        }
                      } else {
                        for (let fh = 0; fh < arr4.length; fh++) {
                          if (
                            datas[i].kategori == 1 ||
                            datas[i].kategori == 2 ||
                            datas[i].kategori == 3 ||
                            datas[i].kategori == 4 ||
                            datas[i].kategori == 5
                          ) {
                            forRows.push(arr4[fh]);
                          }
                          // if (datas[i].kategori == 1) {
                          //   forRows.push(arr4[fh]);
                          // }
                          // if (datas[i].kategori == 2) {
                          //   forRows.push(arr4[fh]);
                          // }
                          // if (datas[i].kategori == 3) {
                          //   forRows.push(arr4[fh]);
                          // }
                          // if (datas[i].kategori == 4) {
                          //   forRows.push(arr4[fh]);
                          // }
                          // if (datas[i].kategori == 5) {
                          //   forRows.push(arr4[fh]);
                          // }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        } else if (this.flags == "kewajiban") {
          for (let i = 0; i < datas.length; i++) {
            let arr1 = datas[i]._children;
            for (let f = 0; f < arr1.length; f++) {
              let arr2 = arr1[f]._children;
              if (opens == 1) {
                if (datas[i].kategori == 6 || datas[i].kategori == 7) {
                  forRows.push(arr1[f]);
                }
              } else if (opens > 1) {
                for (let s = 0; s < arr2.length; s++) {
                  let arr3 = arr2[s]._children;
                  if (opens == 2) {
                    if (datas[i].kategori == 6 || datas[i].kategori == 7) {
                      forRows.push(arr2[s]);
                    }
                  } else {
                    for (let t = 0; t < arr3.length; t++) {
                      let arr4 = arr3[t]._children;
                      if (opens == 3) {
                        if (datas[i].kategori == 6 || datas[i].kategori == 7) {
                          forRows.push(arr3[t]);
                        }
                      } else {
                        for (let fh = 0; fh < arr4.length; fh++) {
                          if (
                            datas[i].kategori == 6 ||
                            datas[i].kategori == 7
                          ) {
                            forRows.push(arr4[fh]);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        } else if (this.flags == "pendapatan") {
          for (let i = 0; i < datas.length; i++) {
            let arr1 = datas[i]._children;
            for (let f = 0; f < arr1.length; f++) {
              let arr2 = arr1[f]._children;
              if (opens == 1) {
                if (datas[i].kategori == 9 || datas[i].kategori == 12) {
                  forRows.push(arr1[f]);
                }
              } else if (opens > 1) {
                for (let s = 0; s < arr2.length; s++) {
                  let arr3 = arr2[s]._children;
                  if (opens == 2) {
                    if (datas[i].kategori == 9 || datas[i].kategori == 12) {
                      forRows.push(arr2[s]);
                    }
                  } else {
                    for (let t = 0; t < arr3.length; t++) {
                      let arr4 = arr3[t]._children;
                      if (opens == 3) {
                        if (datas[i].kategori == 9 || datas[i].kategori == 12) {
                          forRows.push(arr3[t]);
                        }
                      } else {
                        for (let fh = 0; fh < arr4.length; fh++) {
                          if (
                            datas[i].kategori == 9 ||
                            datas[i].kategori == 12
                          ) {
                            forRows.push(arr4[fh]);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        } else if (this.flags == "ekuitas") {
          for (let i = 0; i < datas.length; i++) {
            let arr1 = datas[i]._children;
            for (let f = 0; f < arr1.length; f++) {
              let arr2 = arr1[f]._children;
              if (opens == 1) {
                if (datas[i].kategori == 8 || datas[i].kategori == 15) {
                  forRows.push(arr1[f]);
                }
              } else if (opens > 1) {
                for (let s = 0; s < arr2.length; s++) {
                  let arr3 = arr2[s]._children;
                  if (opens == 2) {
                    if (datas[i].kategori == 8 || datas[i].kategori == 15) {
                      forRows.push(arr2[s]);
                    }
                  } else {
                    for (let t = 0; t < arr3.length; t++) {
                      let arr4 = arr3[t]._children;
                      if (opens == 3) {
                        if (datas[i].kategori == 8 || datas[i].kategori == 15) {
                          forRows.push(arr3[t]);
                        }
                      } else {
                        for (let fh = 0; fh < arr4.length; fh++) {
                          if (
                            datas[i].kategori == 8 ||
                            datas[i].kategori == 15
                          ) {
                            forRows.push(arr4[fh]);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        } else if (this.flags == "beban") {
          for (let i = 0; i < datas.length; i++) {
            let arr1 = datas[i]._children;
            for (let f = 0; f < arr1.length; f++) {
              let arr2 = arr1[f]._children;
              if (opens == 1) {
                if (
                  datas[i].kategori == 10 ||
                  datas[i].kategori == 10 ||
                  datas[i].kategori == 11 ||
                  datas[i].kategori == 13 ||
                  datas[i].kategori == 14
                ) {
                  forRows.push(arr1[f]);
                }
              } else if (opens > 1) {
                for (let s = 0; s < arr2.length; s++) {
                  let arr3 = arr2[s]._children;
                  if (opens == 2) {
                    if (
                      datas[i].kategori == 10 ||
                      datas[i].kategori == 11 ||
                      datas[i].kategori == 13 ||
                      datas[i].kategori == 14
                    ) {
                      forRows.push(arr2[s]);
                    }
                  } else {
                    for (let t = 0; t < arr3.length; t++) {
                      let arr4 = arr3[t]._children;
                      if (opens == 3) {
                        if (
                          datas[i].kategori == 10 ||
                          datas[i].kategori == 11 ||
                          datas[i].kategori == 13 ||
                          datas[i].kategori == 14
                        ) {
                          forRows.push(arr3[t]);
                        }
                      } else if (opens == 4) {
                        for (let fh = 0; fh < arr4.length; fh++) {
                          if (
                            datas[i].kategori == 10 ||
                            datas[i].kategori == 11 ||
                            datas[i].kategori == 13 ||
                            datas[i].kategori == 14
                          ) {
                            forRows.push(arr4[fh]);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        } else if (this.flags == "lembarKerja") {
          for (let i = 0; i < datas.length; i++) {
            let arr1 = datas[i]._children;
            for (let f = 0; f < arr1.length; f++) {
              let arr2 = arr1[f]._children;
              if (opens == 1) {
                forRows.push(arr1[f]);
              } else if (opens > 1) {
                for (let s = 0; s < arr2.length; s++) {
                  let arr3 = arr2[s]._children;
                  if (opens == 2) {
                    forRows.push(arr2[s]);
                  } else {
                    for (let t = 0; t < arr3.length; t++) {
                      let arr4 = arr3[t]._children;
                      if (opens == 3) {
                        forRows.push(arr3[t]);
                      } else if (opens == 4) {
                        for (let fh = 0; fh < arr4.length; fh++) {
                          forRows.push(arr4[fh]);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        for (let d = 0; d < forRows.length; d++) {
          forRows[d].nomor = d + 1;
          forRows[d].haveChildren = forRows[d]._children.length ? true : false;
          delete forRows[d]._children;
        }
        let sorted = forRows.sort((a, b) => {
          return a.nomor > b.nomor ? -1 : 1;
        });
        this.rows = sorted;
        this.show = false;
      }, 0);
    },
    closeAll() {
      // this.rows = data1;
      this.filter = "";
      if (this.flags == "aset") {
        this.aset(data1);
      } else if (this.flags == "kewajiban") {
        this.kewajiban(data1);
      } else if (this.flags == "ekuitas") {
        this.ekuitas(data1);
      } else if (this.flags == "pendapatan") {
        this.pendapatan(data1);
      } else if (this.flags == "beban") {
        this.beban(data1);
      } else if (this.flags == "lembarKerja") {
        this.lembarKerja(data1);
      }
    },
    async getAkuns() {
      let vm = this;
      this.show = true;
      var pembanding = localStorage.getItem("pembanding");
      if (pembanding) {
        var thn = JSON.parse(pembanding);
      }
      if (thn) {
        vm.idPembanding = thn.value;
        vm.columns[8].title = thn.text;
      }
      let fetch = await axios.get(
        "akun/sublifikasi/report/" +
          this.$route.params.id +
          "/" +
          vm.idPembanding,
        {
          headers: { token: localStorage.getItem("token") },
        }
      );
      data1 = fetch.data.hasil.sort((a, b) =>
        a.id > b.id ? 1 : b.id > a.id ? -1 : 0
      );
      for (let a = 0; a < data1.length; a++) {
        vm.prosentase(data1[a]);
        data1[a].debit = vm.formats(data1[a].debit);
        data1[a].kredit = vm.formats(data1[a].kredit);
        data1[a].total = vm.formats(data1[a].total);
        data1[a].total_akhir = vm.formats(data1[a].total_akhir);
        data1[a].pembanding = vm.formats(data1[a].pembanding);
        // loop 2
        for (let b = 0; b < data1[a]._children.length; b++) {
          vm.prosentase(data1[a]._children[b]);
          data1[a]._children[b].debit = vm.formats(data1[a]._children[b].debit);
          data1[a]._children[b].kredit = vm.formats(
            data1[a]._children[b].kredit
          );
          data1[a]._children[b].total = vm.formats(data1[a]._children[b].total);
          data1[a]._children[b].total_akhir = vm.formats(
            data1[a]._children[b].total_akhir
          );
          data1[a]._children[b].pembanding = vm.formats(
            data1[a]._children[b].pembanding
          );
          // loop3
          for (let c = 0; c < data1[a]._children[b]._children.length; c++) {
            vm.prosentase(data1[a]._children[b]._children[c]);
            data1[a]._children[b]._children[c].debit = vm.formats(
              data1[a]._children[b]._children[c].debit
            );
            data1[a]._children[b]._children[c].kredit = vm.formats(
              data1[a]._children[b]._children[c].kredit
            );
            data1[a]._children[b]._children[c].total = vm.formats(
              data1[a]._children[b]._children[c].total
            );
            data1[a]._children[b]._children[c].total_akhir = vm.formats(
              data1[a]._children[b]._children[c].total_akhir
            );
            data1[a]._children[b]._children[c].pembanding = vm.formats(
              data1[a]._children[b]._children[c].pembanding
            );
            // loop 4
            for (
              let d = 0;
              d < data1[a]._children[b]._children[c]._children.length;
              d++
            ) {
              vm.prosentase(data1[a]._children[b]._children[c]._children[d]);
              data1[a]._children[b]._children[c]._children[
                d
              ].debit = vm.formats(
                data1[a]._children[b]._children[c]._children[d].debit
              );
              data1[a]._children[b]._children[c]._children[
                d
              ].kredit = vm.formats(
                data1[a]._children[b]._children[c]._children[d].kredit
              );
              data1[a]._children[b]._children[c]._children[
                d
              ].total = vm.formats(
                data1[a]._children[b]._children[c]._children[d].total
              );
              data1[a]._children[b]._children[c]._children[
                d
              ].total_akhir = vm.formats(
                data1[a]._children[b]._children[c]._children[d].total_akhir
              );
              data1[a]._children[b]._children[c]._children[
                d
              ].pembanding = vm.formats(
                data1[a]._children[b]._children[c]._children[d].pembanding
              );
              // loop 5
              for (
                let e = 0;
                e <
                data1[a]._children[b]._children[c]._children[d]._children
                  .length;
                e++
              ) {
                if (
                  !data1[a]._children[b]._children[c]._children[d]._children[e]
                    .pembanding
                ) {
                  data1[a]._children[b]._children[c]._children[d]._children[
                    e
                  ].pembanding = 0;
                }
                vm.prosentase(
                  data1[a]._children[b]._children[c]._children[d]._children[e]
                );
                data1[a]._children[b]._children[c]._children[d]._children[
                  e
                ].debit = vm.formats(
                  data1[a]._children[b]._children[c]._children[d]._children[e]
                    .debit
                );
                data1[a]._children[b]._children[c]._children[d]._children[
                  e
                ].kredit = vm.formats(
                  data1[a]._children[b]._children[c]._children[d]._children[e]
                    .kredit
                );
                data1[a]._children[b]._children[c]._children[d]._children[
                  e
                ].total = vm.formats(
                  data1[a]._children[b]._children[c]._children[d]._children[e]
                    .total
                );
                data1[a]._children[b]._children[c]._children[d]._children[
                  e
                ].total_akhir = vm.formats(
                  data1[a]._children[b]._children[c]._children[d]._children[e]
                    .total_akhir
                );
                data1[a]._children[b]._children[c]._children[d]._children[
                  e
                ].pembanding = vm.formats(
                  data1[a]._children[b]._children[c]._children[d]._children[e]
                    .pembanding
                );
              }
            }
          }
        }
      }
      vm.closeAll(data1);
      vm.show = false;
      // data2 = JSON.parse(JSON.stringify(data1));
      // for (let a = 0; a < data2.length; a++) {
      //   data2[a]._showChildren = true;
      //   // loop 2
      //   for (let b = 0; b < data2[a]._children.length; b++) {
      //     data2[a]._children[b]._showChildren = true;
      //     // loop3
      //     for (let c = 0; c < data2[a]._children[b]._children.length; c++) {
      //       data2[a]._children[b]._children[c]._showChildren = true;
      //       // loop 4
      //       for (
      //         let d = 0;
      //         d < data2[a]._children[b]._children[c]._children.length;
      //         d++
      //       ) {
      //         data2[a]._children[b]._children[c]._children[
      //           d
      //         ]._showChildren = true;
      //       }
      //     }
      //   }
      // }
    },
    tambahMark(row) {
      this.row = row.row;
      let vm = this;
      let x = localStorage.getItem("token");
      vm.keterangan = row.keterangan;
      vm.row = row.row;
      vm.id = row.id;
      if (vm.row == 4) {
        axios
          .get("akun/tickmark/checkListAkun/" + vm.id, {
            headers: { token: x },
          })
          .then((res) => {
            this.selected = [];
            if (res.data.respon.bukuBesar != 0) {
              this.selected.push("bukuBesar");
            }
            if (res.data.respon.kasHarian != 0) {
              this.selected.push("kasHarian");
            }
            if (res.data.respon.sampelKonfirmasi != 0) {
              this.selected.push("sampelKonfirmasi");
            }
            if (res.data.respon.cekUlang != 0) {
              this.selected.push("cekUlang");
            }
            if (res.data.respon.stockOpname != 0) {
              this.selected.push("stockOpname");
            }
            if (res.data.respon.vouchingDocument != 0) {
              this.selected.push("vouchingDocument");
            }
            if (res.data.respon.cashOpname != 0) {
              this.selected.push("cashOpname");
            }
          });
      }
      this.$refs["modal-mark"].show();
    },
    prosentase(persen) {
      let q = persen.total_akhir - persen.pembanding;
      let w = q / persen.total_akhir;
      let e = w * 100;
      persen.check = Number(e.toFixed(2));
      persen.prosentase = e.toFixed(0) + "%";
      return persen;
    },
    formats(money) {
      money = accounting.formatMoney(money, {
        symbol: "",
        precicsion: 2,
        thousand: ".",
        decimal: ",",
        format: {
          pos: "%v",
          neg: "(%v)",
          zero: "-",
        },
      });
      return money;
    },
    doMark() {
      let vm = this;
      let x = localStorage.getItem("token");
      let y = localStorage.getItem("idProject");
      let row = this.row;
      let id = this.id;
      let kode = this.kode;
      let bukuBesar = 0;
      let kasHarian = 0;
      let cashOpname = 0;
      let sampelKonfirmasi = 0;
      let cekUlang = 0;
      let stockOpname = 0;
      let vouchingDocument = 0;
      vm.selected.forEach((el) => {
        if (el == "bukuBesar") {
          bukuBesar = 1;
        } else if (el == "kasHarian") {
          kasHarian = 1;
        } else if (el == "cashOpname") {
          cashOpname = 1;
        } else if (el == "sampelKonfirmasi") {
          sampelKonfirmasi = 1;
        } else if (el == "cekUlang") {
          cekUlang = 1;
        } else if (el == "stockOpname") {
          stockOpname = 1;
        } else if (el == "vouchingDocument") {
          vouchingDocument = 1;
        }
      });
      axios.post(
        "akun/tickmark/checkList/" + id,
        {
          bukuBesar: bukuBesar,
          kasHarian: kasHarian,
          cashOpname: cashOpname,
          sampelKonfirmasi: sampelKonfirmasi,
          cekUlang: cekUlang,
          stockOpname: stockOpname,
          vouchingDocument: vouchingDocument,
          kodeAkun: kode,
          projectId: y,
        },
        {
          headers: { token: x },
        }
      );
      if (row == 0) {
        axios
          .post(
            "akun/head/updateAkun/" + id,
            {
              kodeHead: kode,
              keteranganHead: vm.keterangan,
            },
            {
              headers: { token: x },
            }
          )
          .then((res) => {
            this.getAkuns();
            if (res.data.status == 200) {
              vm.pesan = "sukses merubah akun";
              vm.alert2 = true;
              setTimeout(() => {
                vm.alert2 = false;
              }, 3000);
            }
            vm.kodeSubHead = "";
            vm.keterangan = "";
            this.$refs["modal-mark"].hide();
            this.getAkuns();
          });
      } else if (row == 1) {
        axios
          .post(
            "akun/subHead/updateAkun/" + id,
            {
              kodeSubHead: kode,
              keteranganSubHead: vm.keterangan,
            },
            {
              headers: { token: x },
            }
          )
          .then((res) => {
            this.getAkuns();
            if (res.data.status == 200) {
              vm.pesan = "sukses merubah akun";
              vm.alert2 = true;
              setTimeout(() => {
                vm.alert2 = false;
              }, 3000);
            }
            vm.kodeSubHead = "";
            vm.keterangan = "";
            this.$refs["modal-mark"].hide();
          });
      } else if (row == 2) {
        axios
          .post(
            "akun/subHeadSub/updateAkun/" + id,
            {
              kodeSubHeadSub: kode,
              keteranganSubHeadSub: vm.keterangan,
            },
            {
              headers: { token: x },
            }
          )
          .then((res) => {
            this.getAkuns();
            if (res.data.status == 200) {
              vm.pesan = "sukses merubah akun";
              vm.alert2 = true;
              setTimeout(() => {
                vm.alert2 = false;
              }, 3000);
            }
            vm.kodeSubHead = "";
            vm.keterangan = "";
            this.$refs["modal-mark"].hide();
          });
      } else if (row == 3) {
        axios
          .post(
            "akun/sublifikasi/updateAkun/" + id,
            {
              kodeSublifikasi: kode,
              keteranganSublifikasi: vm.keterangan,
            },
            {
              headers: { token: x },
            }
          )
          .then((res) => {
            this.getAkuns();
            if (res.data.status == 200) {
              vm.pesan = "sukses merubah akun";
              vm.alert2 = true;
              setTimeout(() => {
                vm.alert2 = false;
              }, 3000);
            }
            vm.keterangan = "";
            this.$refs["modal-mark"].hide();
          });
      } else {
        axios
          .post(
            "akun/subsifik/updateAkun/" + id,
            {
              kodeSubsifik: kode,
              keteranganSubsifik: vm.keterangan,
            },
            {
              headers: { token: x },
            }
          )
          .then((res) => {
            this.getAkuns();
            if (res.data.status == 200) {
              vm.pesan = "sukses merubah akun";
              vm.alert2 = true;
              setTimeout(() => {
                vm.alert2 = false;
              }, 3000);
            }
            vm.keterangan = "";
            this.$refs["modal-mark"].hide();
          });
      }
    },
    // async download() {
    //   let vm = this;
    //   let x = localStorage.getItem("token");
    //   let y = localStorage.getItem("idProject");
    //   await axios.get(ip_server + "excel/download/" + y, {
    //     headers: { token: x },
    //   });
    // },
    tambahChildren(row, id, load) {
      this.row = row;
      this.id = id;
      let rows = load;
      let kodenya = rows.kodeAkun;
      if (row == 0 || row == 1 || row == 2) {
        this.$refs["modal-tambah2"].show();
        if (row == 0) {
          let loadnya = rows._children;
          let f = "";
          let g = ".1";
          loadnya.forEach((el) => {
            let x = el.kodeAkun.split("");
            f = x;
          });
          let z = loadnya.length + 1;
          if (f.length - 1 == z.toString()) {
            let add = loadnya.length + 2;
            f.pop();
            f.push(add);
            this.kodeSubHead = f.join("");
          } else if (loadnya.length == 0) {
            this.kodeSubHead = kodenya + g;
          } else {
            f.pop();
            f.push(z);
            this.kodeSubHead = f.join("");
          }
        } else if (row == 1) {
          let loadnya = rows._children;
          let f = "";
          let g = ".1";
          loadnya.forEach((el) => {
            let x = el.kodeAkun.split("");
            f = x;
          });
          let z = loadnya.length + 1;
          if (f.length - 1 == z.toString()) {
            let add = loadnya.length + 2;
            f.pop();
            f.push(add);
            this.kodeSubHead = f.join("");
          } else if (loadnya.length == 0) {
            this.kodeSubHead = kodenya + g;
          } else {
            f.pop();
            f.push(z);
            this.kodeSubHead = f.join("");
          }
        } else {
          let loadnya = rows._children;
          let f = "";
          let g = ".1";
          loadnya.forEach((el) => {
            let x = el.kodeAkun.split("");
            f = x;
          });
          let z = loadnya.length + 1;
          if (f.length - 1 == z.toString()) {
            let add = loadnya.length + 2;
            f.pop();
            f.push(add);
            this.kodeSubHead = f.join("");
          } else if (loadnya.length == 0) {
            this.kodeSubHead = kodenya + g;
          } else {
            f.pop();
            f.push(z);
            this.kodeSubHead = f.join("");
          }
        }
      } else if (row == 3) {
        this.$refs["modal-tambah3"].show();
        if (row == 3) {
          let loadnya = rows._children;
          let kodenya = rows.kodeAkun;
          let f = "";
          let g = ".1";
          loadnya.forEach((el) => {
            let x = el.kodeAkun.split("");
            f = x;
          });
          let z = loadnya.length + 1;
          if (f.length - 1 == z.toString()) {
            let add = loadnya.length + 2;
            f.pop();
            f.push(add);
            this.kodeSubHead = f.join("");
          } else if (loadnya.length == 0) {
            this.kodeSubHead = kodenya + g;
          } else {
            let z = loadnya.length + 1;
            f.pop();
            f.push(z);
            this.kodeSubHead = f.join("");
          }
        }
      }
    },
    editAkun(data) {
      let row = data.row;
      if (row === 0) {
        this.akunHeadRow.kodeAkunHead = data.kodeAkun;
        this.akunHeadRow.keteranganHead = data.keterangan;
        this.akunHeadRow.kategoriHead = data.kategori;
        this.akunHeadRow.namaAkunHead = data.akun;
        this.akunHeadRow.idAkunHead = data.id;
        this.$bvModal.show("modalEdit");
      } else if (row === 1) {
        this.akunSubHeadRow.kodeAkunSubHead = data.kodeAkun;
        this.akunSubHeadRow.keteranganSubHead = data.keterangan;
        this.akunSubHeadRow.kategoriSubHead = data.kategori;
        this.akunSubHeadRow.namaAkunSubHead = data.akun;
        this.akunSubHeadRow.idAkunSubHead = data.id;
        this.akunSubHeadRow.PAAKSubHead = data.paak;
        this.akunSubHeadRow.CLKSubHead = data.clk;
        this.$bvModal.show("modalEdit1");
      } else if (row === 2) {
        this.akunSubHeadSubRow.kodeAkunSubHeadSub = data.kodeAkun;
        this.akunSubHeadSubRow.keteranganSubHeadSub = data.keterangan;
        this.akunSubHeadSubRow.kategoriSubHeadSub = data.kategori;
        this.akunSubHeadSubRow.namaAkunSubHeadSub = data.akun;
        this.akunSubHeadSubRow.idAkunSubHeadSub = data.id;
        this.akunSubHeadSubRow.PAAKSubHeadSub = data.paak;
        this.akunSubHeadSubRow.CLKSubHeadSub = data.clk;
        this.$bvModal.show("modalEdit2");
      } else if (row === 3) {
        this.akunSublifikasiRow.kodeAkunSublifikasi = data.kodeAkun;
        this.akunSublifikasiRow.keteranganSublifikasi = data.keterangan;
        this.akunSublifikasiRow.kategoriSublifikasi = data.kategori;
        this.akunSublifikasiRow.namaAkunSublifikasi = data.akun;
        this.akunSublifikasiRow.idAkunSublifikasi = data.id;
        this.akunSublifikasiRow.PAAKSublifikasi = data.paak;
        this.akunSublifikasiRow.CLKSublifikasi = data.clk;
        this.akunSublifikasiRow.haveChildren = data.haveChildren;
        this.akunSublifikasiRow.nominalAkunSublifikasi = data.total
          .replace(/\.+/g, "")
          .replace(",00", "");
        this.$bvModal.show("modalEdit3");
      } else if (row === 4) {
        this.akunSubsifikRow.kodeSubsifik = data.kodeAkun;
        this.akunSubsifikRow.keteranganSubsifik = data.keterangan;
        this.akunSubsifikRow.kategoriSubsifik = data.kategori;
        this.akunSubsifikRow.namaAkunSubsifik = data.akun;
        this.akunSubsifikRow.idAkunSubsifik = data.id;
        this.akunSubsifikRow.PAAKSubsifik = data.paak;
        this.akunSubsifikRow.CLKSubsifik = data.clk;
        this.akunSubsifikRow.nominalAkunSubsifik = data.total
          .replace(/\.+/g, "")
          .replace(",00", "");
        this.$bvModal.show("modalEdit4");
      }
    },
    triggerAlert(payload) {
      if (payload.alert === "alert2") {
        this.alert2 = true;
        this.pesan = payload.pesan;
        this.getAkuns();
        setTimeout(() => {
          this.alert2 = false;
        }, 3000);
      } else {
        this.alert = true;
        this.pesan = payload.pesan;
        this.getAkuns();
        setTimeout(() => {
          this.alert = false;
        }, 3000);
      }
    },
    hapus(row, id) {
      this.row = row;
      this.id = id;
      this.$refs["modal-hapus"].show();
    },
    batalDelete() {
      this.$refs["modal-hapus"].hide();
    },
    doDelete() {
      let vm = this;
      let row = vm.row;
      let id = vm.id;
      let x = localStorage.getItem("token");
      if (row == 0) {
        axios.post(
          "akun/head/deleteAkun/" + id,
          {},
          {
            headers: {
              token: x,
            },
          }
        );
        vm.getAkuns();
      } else if (row == 1) {
        axios.post(
          "akun/subHead/deleteAkun/" + id,
          {},
          {
            headers: {
              token: x,
            },
          }
        );
        vm.getAkuns();
      } else if (row == 2) {
        axios.post(
          "akun/subHeadSub/deleteAkun/" + id,
          {},
          {
            headers: {
              token: x,
            },
          }
        );
        vm.getAkuns();
      } else if (row == 3) {
        axios.post(
          "akun/sublifikasi/deleteAkun/" + id,
          {},
          {
            headers: {
              token: x,
            },
          }
        );
        vm.getAkuns();
      } else {
        axios.post(
          "akun/subsifik/deleteAkun/" + id,
          {},
          {
            headers: {
              token: x,
            },
          }
        );
      }
      this.$refs["modal-hapus"].hide();
      vm.getAkuns();
      vm.pesan = "akun berhasil dihapus";
      vm.alert = true;
      setTimeout(() => {
        vm.alert = false;
      }, 3000);
    },
    filterChanged(filter) {
      this.filter = filter;
    },
    pageChanged(page) {
      this.page = page;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    uploadFile() {
      let vm = this;
      var formData = new FormData();
      let idProject = localStorage.getItem("idProject");
      let tahun = this.stateProject.tahunProject;
      let x = localStorage.getItem("token");
      formData.append("projectId", idProject);
      formData.append("tahun", tahun);
      if (vm.fileUpload) {
        formData.append("excelFile", vm.fileUpload);
      }
      axios({
        method: "post",
        url: "excel/insert",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: x,
        },
      });
      this.$refs["modal-import"].hide();
      vm.getAkuns();
    },
    send() {
      let vm = this;
      let x = localStorage.getItem("token");
      let idProject = localStorage.getItem("idProject");
      let row = this.row;
      let id = this.id;
      if (row == 0) {
        axios
          .post(
            "akun/subHead/createAkun",
            {
              namaAkunSubHead: vm.namaAkunSub,
              kodeSubHead: vm.kodeSubHead,
              akunHeadId: id,
              projectId: idProject,
              kategoriSubHead: vm.kategoriAkun,
              CLKSubHead: vm.CLKAkun,
              PAAKSubHead: _.toUpper(vm.PAAKAkun),
            },
            {
              headers: { token: x },
            }
          )
          .then((res) => {
            if (res.data.status == 200) {
              this.getAkuns();
              vm.pesan = "sukses membuat akun";
              vm.alert2 = true;
              setTimeout(() => {
                vm.alert2 = false;
              }, 3000);
              vm.kodeSubHead = "";
              vm.namaAkunSub = "";
              this.$refs["modal-tambah2"].hide();
            }
          })
          .catch((err) => {
            console.log(err);
            if (err.response) {
              this.getAkuns();
              vm.pesan = "kode sudah terdaftar";
              vm.alert = true;
              setTimeout(() => {
                vm.alert = false;
              }, 3000);
              vm.kodeSubHead = "";
              vm.namaAkunSub = "";
              this.$refs["modal-tambah2"].hide();
            }
          });
      } else if (row == 1) {
        axios
          .post(
            "akun/subHeadSub/createAkun",
            {
              namaAkunSubHeadSub: vm.namaAkunSub,
              kodeSubHeadSub: vm.kodeSubHead,
              akunSubHeadId: id,
              projectId: idProject,
              kategoriSubHeadSub: vm.kategoriAkun,
              CLKSubHeadSub: vm.CLKAkun,
              PAAKSubHeadSub: _.toUpper(vm.PAAKAkun),
            },
            {
              headers: { token: x },
            }
          )
          .then((res) => {
            if (res.data.status == 200) {
              this.getAkuns();
              vm.pesan = "sukses membuat akun";
              vm.alert2 = true;
              setTimeout(() => {
                vm.alert2 = false;
              }, 3000);
              vm.kodeSubHead = "";
              vm.namaAkunSub = "";
              this.$refs["modal-tambah2"].hide();
            }
          })
          .catch((err) => {
            console.log(err);
            if (err.response) {
              this.getAkuns();
              vm.pesan = "kode sudah terdaftar";
              vm.alert = true;
              setTimeout(() => {
                vm.alert = false;
              }, 3000);
              vm.kodeSubHead = "";
              vm.namaAkunSub = "";
              this.$refs["modal-tambah2"].hide();
            }
          });
      } else {
        axios
          .post(
            "akun/sublifikasi/createAkun",
            {
              projectId: idProject,
              namaAkunSublifikasi: vm.namaAkunSub,
              tahunAkunSublifikasi: this.stateProject.tahunProject,
              nominalAkunSublifikasi: 0,
              akunSubHeadSubId: id,
              kodeSublifikasi: vm.kodeSubHead,
              kategoriSublifikasi: vm.kategoriAkun,
              CLKSublifikasi: vm.CLKAkun,
              PAAKSublifikasi: _.toUpper(vm.PAAKAkun),
            },
            {
              headers: { token: x },
            }
          )
          .then((res) => {
            if (res.data.status == 200) {
              this.getAkuns();
              vm.pesan = "sukses membuat akun";
              vm.alert2 = true;
              setTimeout(() => {
                vm.alert2 = false;
              }, 3000);
              vm.kodeSubHead = "";
              vm.nominalAkun = "";
              vm.namaAkunSub = "";
              this.$refs["modal-tambah2"].hide();
            }
          })
          .catch((err) => {
            console.log(err);
            if (err.response) {
              this.getAkuns();
              vm.pesan = "kode sudah terdaftar";
              vm.alert = true;
              setTimeout(() => {
                vm.alert = false;
              }, 3000);
              vm.kodeSubHead = "";
              vm.nominalAkun = "";
              vm.namaAkunSub = "";
              this.$refs["modal-tambah2"].hide();
            }
          });
      }
    },
    send2() {
      let vm = this;
      let x = localStorage.getItem("token");
      let idProject = localStorage.getItem("idProject");
      axios
        .post(
          "akun/subsifik/createAkun",
          {
            namaAkunSubsifik: vm.namaAkunSub,
            nominalAkunSubsifik: vm.nominalAkun,
            kodeSubsifik: vm.kodeSubHead,
            akunSublifikasiId: vm.id,
            projectId: idProject,
            kategoriSubsifik: vm.kategoriAkun,
            CLKSubsifik: vm.CLKAkun,
            PAAKSubsifik: _.toUpper(vm.PAAKAkun),
          },
          {
            headers: { token: x },
          }
        )
        .then((res) => {
          if (res.data.status == 200) {
            this.getAkuns();
            vm.pesan = "sukses membuat akun";
            vm.alert2 = true;
            setTimeout(() => {
              vm.alert2 = false;
            }, 3000);
            vm.kodeSubHead = "";
            vm.nominalAkun = "";
            vm.namaAkunSub = "";
            this.$refs["modal-tambah3"].hide();
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response) {
            this.getAkuns();
            vm.pesan = "kode sudah terdaftar";
            vm.alert = true;
            setTimeout(() => {
              vm.alert = false;
            }, 3000);
            vm.kodeSubHead = "";
            vm.nominalAkun = "";
            vm.namaAkunSub = "";
            this.$refs["modal-tambah3"].hide();
          }
        });
    },
    tutupModal2() {
      this.$refs["modal-import"].hide();
    },
    tutupModal3() {
      this.$refs["modal-tambah2"].hide();
    },
    tutupModal4() {
      this.$refs["modal-tambah3"].hide();
    },
    tutupModal5() {
      this.$refs["modal-edit"].hide();
    },
    tutupModal6() {
      this.$refs["modal-edit2"].hide();
    },
    tutupModal7() {
      this.$refs["modal-mark"].hide();
    },
    aset(datas) {
      let vm = this;
      // if (vm.tampil == true) {
      //   vm.tampil = false;
      // }
      let forRows = [];
      datas.forEach((el) => {
        if (el.kategori == 1) {
          el._showChildren = false;
          forRows.push(el);
        }
        if (el.kategori == 2) {
          el._showChildren = false;
          forRows.push(el);
        }
        if (el.kategori == 3) {
          el._showChildren = false;
          forRows.push(el);
        }
        if (el.kategori == 4) {
          el._showChildren = false;
          forRows.push(el);
        }
        if (el.kategori == 5) {
          el._showChildren = false;
          forRows.push(el);
        }
        vm.rows = forRows;
        // this.filter = "";
      });
    },
    kewajiban(datas) {
      let vm = this;
      // if (vm.tampil == true) {
      //   vm.tampil = false;
      // }
      let forRows = [];
      datas.forEach((el) => {
        if (el.kategori == 6) {
          el._showChildren = false;
          forRows.push(el);
        }
        if (el.kategori == 7) {
          el._showChildren = false;
          forRows.push(el);
        }
        vm.rows = forRows;
      });
    },
    ekuitas(datas) {
      let vm = this;
      // if (vm.tampil == true) {
      //   vm.tampil = false;
      // }
      let forRows = [];
      datas.forEach((el) => {
        if (el.kategori == 8) {
          el._showChildren = false;
          forRows.push(el);
        }
        if (el.kategori == 15) {
          el._showChildren = false;
          forRows.push(el);
        }
        vm.rows = forRows;
      });
    },
    pendapatan(datas) {
      let vm = this;
      // if (vm.tampil == true) {
      //   vm.tampil = false;
      // }
      let forRows = [];
      datas.forEach((el) => {
        if (el.kategori == 9) {
          el._showChildren = false;
          forRows.push(el);
        }
        if (el.kategori == 12) {
          el._showChildren = false;
          forRows.push(el);
        }
        vm.rows = forRows;
      });
    },
    beban(datas) {
      let vm = this;
      // if (vm.tampil == true) {
      //   vm.tampil = false;
      // }
      let forRows = [];
      datas.forEach((el) => {
        if (el.kategori == 10) {
          el._showChildren = false;
          forRows.push(el);
        }
        if (el.kategori == 11) {
          el._showChildren = false;
          forRows.push(el);
        }
        if (el.kategori == 13) {
          el._showChildren = false;
          forRows.push(el);
        }
        if (el.kategori == 14) {
          el._showChildren = false;
          forRows.push(el);
        }
        vm.rows = forRows;
      });
    },
    lembarKerja(datas) {
      let vm = this;
      vm.rows = datas;
      for (let i = 0; i < vm.rows.length; i++) {
        vm.rows[i]._showChildren = false;
      }
      vm.semua = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");
$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white": #fafafa,
  "white2": #fff,
  "gray-dark": #b5bac3,
  "blue-light": #e8f3fe,
  "red-light": #ffe8e7,
  "green-light": #eafffa,
  "green": #6adbbe,
);
.wrapper {
  position: relative;
  .buttons {
    margin-top: 0.2%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .btn {
      width: 16%;
      font-family: "nunito", sans-serif;
      color: #c8d5ec;
      font-weight: 700;
      background-color: transparent;
      border: 2px solid #e8c8ec;
      text-transform: capitalize;
      border-radius: 10px;
      padding: 0.5% 2%;
    }
  }
  .tab {
    .btnActive {
      font-family: "nunito", sans-serif;
      color: map-get($color, "blue");
    }
  }
  .button-wrapper14 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 5;
    position: absolute;
    right: -1%;
    bottom: -4%;
    .btn {
      background-color: map-get($color, "blue");
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      height: clamp(40px, 50px, 60px);
      width: clamp(40px, 50px, 60px);
      display: flex;
      align-items: center;
      box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
      img {
        width: 32px;
        height: 32px;
      }
    }
    .btn:hover {
      background-color: map-get($color, "blue");
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      height: clamp(40px, 50px, 60px);
      width: clamp(40px, 50px, 60px);
      display: flex;
      align-items: center;
      box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
      img {
        width: 32px;
        height: 32px;
      }
    }
  }
  .table {
    // min-width: 100%;
    max-width: 100%;
    overflow-x: scroll;
    font-family: "nunito", sans-serif;
    font-size: 13px;
    overflow: scroll;
    min-height: 57vh;
    max-height: 57vh;
    border-radius: 7px;
    border: 1px solid map-get($color, "gray-dark");
    table {
      min-width: 100%;
      font-family: "Nunito", sans-serif;
      .vue-ads-border-b {
        text-align: center;
      }
      .tes {
        background-color: #327ff2;
        color: #fff;
        padding: 0.5% 10%;
        font-size: clamp(12px, 14px, 16px);
        border-radius: 4px;
      }
      .tes:hover {
        color: #fff;
        cursor: pointer;
        padding: 0.5% 10%;
        font-size: clamp(12px, 14px, 16px);
        border-radius: 4px;
      }
    }
    .button-wrapper2 {
      display: flex;
      width: 90px;
      justify-content: space-between;
      .tambah {
        background-color: #6adbbe;
        padding: 5%;
        cursor: pointer;
        border-radius: 4px;
        display: flex;
        align-items: center;
      }
      .edit {
        background-color: #f4b366;
        padding: 5%;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .hapus {
        background-color: #e0544e;
        cursor: pointer;
        padding: 5%;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .buttons2 {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.8%;
    .btn-container {
      display: flex;
      width: 35%;
      .btn {
        border-color: none;
        margin-right: 3%;
        background-color: #327ff2;
        border: none;
        font-family: "nunito", sans-serif;
        color: #fff;
        height: clamp(24px, 28px, 32px);
        display: flex;
        font-size: clamp(10px, 14px, 18px);
        align-items: center;
      }
    }
    .filter {
      width: 40%;
      .form-group {
        margin: 0;
      }
    }
  }
  .alert {
    position: absolute;
    width: 80%;
    display: flex;
    z-index: 100;
    transform: translate(10%, -180%);
    align-items: flex-end;
    justify-content: center;
  }
}
</style>
