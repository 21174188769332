<template>
  <b-row>
    <b-col cols="12">
      <div class="forms">
        <b-form-group label="Tanggal Mulai" style="width: 45%">
          <date-picker
            v-model="tanggalMulai"
            valueType="format"
            style="width: 100%"
          ></date-picker>
        </b-form-group>
        -
        <b-form-group label="Durasi Project " style="width: 45%">
          <b-form-input
            v-model="deadline"
            placeholder="Durasi hari (365)"
            type="number"
            valueType="format"
            style="width: 100%"
          ></b-form-input>
        </b-form-group>
      </div>
      <b-button :disabled="loading" block @click="send">{{ tombol }}</b-button>
    </b-col>
    <b-alert :variant="variant" dismissible fade :show="showing">{{
      pesan
    }}</b-alert>
  </b-row>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import axios from "@/ip_config";
import { mapState } from "vuex";

export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      tanggalMulai: "",
      deadline: "",
      tombol: "Simpan",
      variant: "success",
      pesan: "",
      showing: false,
      loading: false,
    };
  },

  computed: {
    ...mapState("apiProject", ["stateProject"]),
  },
  mounted() {
    if (this.stateProject.deadline != "" || this.stateProject.tanggalMulai) {
      this.deadline = this.stateProject.deadline;
      this.tanggalMulai = this.stateProject.tanggalMulai;
    }
  },
  methods: {
    async send() {
      let vm = this;
      vm.loading = true;
      vm.tombol = "Mohon Tunggu";

      if (vm.tanggalMulai == "" || vm.deadline == "") {
        vm.pesan = "Mohon periksa kembali!";
        vm.loading = false;
        vm.tombol = "Simpan";
        vm.variant = "danger";
        vm.showing = true;
        setTimeout(() => {
          vm.showing = false;
        }, 4000);
      } else {
        let update = await axios.post(
          "project/update/" + vm.$route.params.id,
          {
            tanggalMulai: vm.tanggalMulai,
            deadline: vm.deadline,
          },
          {
            headers: {
              token: localStorage.getItem("token"),
            },
          }
        );
        if (update.data.message == "sukses") {
          vm.pesan = "Berhasil mengubah tanggal project";
          vm.loading = false;
          vm.tombol = "Simpan";
          vm.variant = "success";
          vm.showing = true;
          setTimeout(() => {
            vm.showing = false;
          }, 4000);
        } else if (update.data.message == "gagal") {
          vm.pesan = "Gagal mengubah tanggal project";
          vm.loading = false;
          vm.tombol = "Simpan";
          vm.variant = "danger";
          vm.showing = true;
          setTimeout(() => {
            vm.showing = false;
          }, 4000);
        }
         //window.location.reload();
      }
    },
  },
};
</script>

<style scoped>
.alert {
  position: absolute;
  width: 70%;
  bottom: -100%;
  left: 0;
  right: 0;
  margin: auto;
}
.btn {
  background-color: #327ff2;
  border: none;
  margin-top: 5%;
}
.forms {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
</style>
