<template>
  <div class="wrapper">
    <div
      style="width: 100%; height: 50px"
      class="d-md-block d-lg-block d-xl-none"
    ></div>
    <div class="header">
      <div class="judul">
        <span>Diskusi</span>
      </div>
      <div class="button-wrapper">
        <b-button @click="regis"
          ><img src="../../assets/icon/plus-circle.svg" alt="" /> Buat
          Diskusi</b-button
        >
      </div>
    </div>

    <div class="cards">
      <b-card v-for="(item, idx) in thread" :key="idx">
        <div class="content">
          <div class="user">
            <b-avatar v-if="item.ava" :src="item.ava" rounded> </b-avatar>
            <b-avatar v-else rounded> </b-avatar>
            <div class="text">
              <span style="margin-left: 1%">
                <span style="text-transform: capitalize">{{ item.nama }}</span>
                <br />
                <span style="font-weight: 500">{{ item.email }}</span>
              </span>

              <span
                style="
                  font-weight: 500;
                  color: #b5bac3;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;
                "
              >
                <span style="color: #061737">#{{ item.nomor }}</span>
                <span style="font-size: 14px; color: #a0a0a0"
                  >Tayang pada tanggal {{ item.createdAt }}</span
                >
              </span>
            </div>
          </div>
          <div class="isi-thread">
            {{ item.isi }}
          </div>
        </div>

        <div class="button-wrapper">
          <span>{{ item.reply }} balasan</span>
          <b-button @click="goBalas(item.id)" pill
            ><img
              style="margin-right: 4%"
              src="../../assets/icon/message-circle.svg"
              alt=""
            />
            Balas</b-button
          >
        </div>
      </b-card>
    </div>

    <b-modal hide-footer hide-header centered ref="modal-regis" size="lg">
      <div class="tutup" style="display: flex; justify-content: flex-end">
        <b-button
          @click="tutupModal1"
          style="background-color: white; border: none"
          ><img
            src="../../assets/icon/close.svg"
            alt=""
            style="width: 15px; height: 15px"
        /></b-button>
      </div>
      <div
        class="content"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 1% 2%;
        "
      >
        <span
          class="tittle"
          style="
            font-family: 'nunito', sans-serif;
            font-size: 28px;
            font-weight: 700;
            text-transform: capitalize;
          "
        >
          Buat Thread
        </span>
        <div class="form" style="width: 95%; margin-top: 4%">
          <b-form-input
            id="input-formatter"
            placeholder="Judul Thread"
            style="border-radius: 10px"
            size="lg"
            type="text"
            v-model="judul"
          >
          </b-form-input>
          <b-form-textarea
            style="margin-top: 2%"
            id="textarea"
            v-model="isi"
            placeholder="Isi thread..."
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </div>
      </div>
      <div
        class="wrapper"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 0 2%;
        "
      >
        <div
          class="button-wrapper"
          style="display: flex; width: 95%; justify-content: flex-end"
        >
          <b-button
            @click="doRegis"
            v-if="loading == false"
            style="
              background-color: #327ff2;
              color: #fff;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              width: 25%;
              border: none;
              margin-top: 20px;
              height: 40px;
            "
            >Post Thread</b-button
          >
          <b-button
            disabled
            v-else
            style="
              background-color: #327ff2;
              color: #fff;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              width: 25%;
              border: none;
              margin-top: 20px;
              height: 40px;
            "
            ><white-spinner
          /></b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "@/ip_config.js";
import { mapState } from "vuex";
import moment from "moment";
moment.locale("id");

export default {
  data() {
    return {
      loading: false,
      judul: "",
      isi: "",
      id: "",
      thread: [],
      userId: "",
    };
  },

  mounted() {
    this.getThread();
  },

  computed: {
    ...mapState("apiProject", ["role"]),
  },

  methods: {
    getThread() {
      let vm = this;
      let y = localStorage.getItem("idProject");
      let x = localStorage.getItem("token");
      axios
        .get("thread/threadByProject/" + y, {
          headers: { token: x },
        })
        .then(async (res) => {
          let payload = res.data;

          payload.forEach((el, id) => {
            payload[id].reply = 0;

            if (
              payload[id].id != "" ||
              payload[id].id != null ||
              payload[id].id != undefined ||
              payload[id].id != "undefined"
            ) {
              axios
                .get("reply/listByThread/" + payload[id].id, {
                  headers: {
                    token: x,
                  },
                })
                .then((res) => {
                  res.data.forEach((element, idx) => {
                    if (res.data.length > 0) {
                      if (res.data[idx].threadId == payload[id].id) {
                        payload[id].reply = res.data.length;
                      }
                    }
                  });
                });
            }
          });
          vm.thread = payload;
          axios
            .get("users/profileById/" + vm.thread[0].userId, {
              headers: {
                token: x,
              },
            })
            .then((fetched) => {
              for (let index = 0; index < fetched.data.length; index++) {
                for (let id = 0; id < vm.thread.length; id++) {
                  vm.thread[id].ava =
                    "https://engine.kapmobile.id/" + fetched.data[index].foto;
                  vm.thread[id].createdAt = moment(
                    vm.thread[id].createdAt
                  ).format("LL");
                  vm.thread[id].isi.substring(0, 120) + "...";
                  vm.thread[id].nomor = id + 1;
                }
              }
            });
        });
    },

    regis() {
      this.$refs["modal-regis"].show();
    },

    doRegis() {
      let vm = this;
      let x = localStorage.getItem("token");
      let y = localStorage.getItem("idProject");

      axios
        .post(
          "thread/register",
          {
            judul: vm.judul,
            jabatan: vm.role,
            isi: vm.isi,
            projectId: y,
          },
          {
            headers: { token: x },
          }
        )
        .then(() => {
          vm.judul = "";
          vm.isi = "";
          this.$refs["modal-regis"].hide();
          this.getThread();
        });
    },

    tutupModal1() {
      this.$refs["modal-regis"].hide();
    },

    goBalas(id) {
      this.$emit("idThread", id);
      this.$emit("changeView", 2);
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");
$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white": #fafafa,
  "white2": #fff,
  "gray-dark": #b5bac3,
  "blue-light": #e8f3fe,
  "red-light": #ffe8e7,
  "green-light": #eafffa,
  "green": #6adbbe,
);

.header {
  padding: 0 2%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .judul {
    font-family: "nunito", sans-serif;
    font-size: 30px;
    text-transform: capitalize;
  }
  .button-wrapper {
    width: 15%;
    display: flex;
    justify-content: flex-end;
    .btn {
      border: none !important;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      font-family: "nunito", sans-serif;
      width: 100%;
      box-shadow: 0px 20px 25px -10px rgba(15, 93, 210, 0.25);
      background-color: map-get($color, "blue");
    }
  }
}

.cards {
  font-family: "nunito", sans-serif;
  min-height: 64vh;
  max-height: 64vh;
  overflow-y: scroll;
  border: 1px solid #dcdee0;
  border-radius: 7px;
  padding: 1% 2%;
  margin-top: 2%;
  .card {
    border-radius: 7px;
    background-color: map-get($color, "white");
    box-shadow: 0px 20px 25px -10px rgba(71, 71, 71, 0.2);
    margin: 1% 0;
    min-height: 250px;
    max-height: 300px;
    .card-body {
      flex-direction: column;
      justify-content: space-between;
      display: flex;
      min-height: 200px;
      .content {
        width: 100%;
        .user {
          display: flex;
          align-items: center;
          width: 100%;
          border-bottom: 1px solid #dcdee0;
          padding-bottom: 1%;
        }
        .text {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: flex-end;
        }
        .isi-thread {
          margin-top: 1%;
        }
      }
      .button-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-weight: 700;
        span {
          margin-right: 2%;
        }
        .btn {
          border: none !important;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          font-family: "nunito", sans-serif;
          background-color: map-get($color, "blue");
        }
      }
    }
  }
}
</style>
