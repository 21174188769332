<template>
  <div class="wrapper">
    <div class="header">
      <b-button
        class="judul"
        style="
          background: none;
          border: none;
          color: #061737;
          display: flex;
          justify-content: space-around;
          align-items: center;
          width: 30%;
        "
        @click="back"
      >
        <img src="../../assets/icon/back-black.svg" alt="" />
        Kembali ke Diskusi</b-button
      >
      <div class="button-wrapper">
        <b-button>Telah Terselesaikan</b-button>
      </div>
    </div>

    <div class="card-wrapper">
      <div class="card-thread">
        <b-card>
          <div class="content">
            <div class="user">
              <b-avatar v-if="isi.ava" :src="isi.ava" rounded> </b-avatar>
              <b-avatar v-else rounded> </b-avatar>
              <div class="text">
                <span style="margin-left: 1%">
                  <span style="text-transform: capitalize">{{ isi.nama }}</span>
                  <br />
                  <span style="font-weight: 500">{{ isi.email }}</span>
                </span>

                <span
                  style="
                    font-weight: 500;
                    color: #b5bac3;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                  "
                >
                  <span
                    style="
                      color: #061737;
                      font-size: 22px;
                      text-transform: capitalize;
                      font-weight: 700;
                    "
                    >{{ isi.judul }}</span
                  >
                  <span style="font-size: 14px; color: #a0a0a0"
                    >Tayang pada tanggal {{ isi.createdAt }}</span
                  >
                </span>
              </div>
            </div>
            <div class="isi-thread">
              {{ isi.isi }}
            </div>
          </div>

          <div class="button-wrapper1">
            <span class="length">{{ jmlBalas }} balasan</span>
            <b-button @click="balasThread">
              <img src="@/assets/icon/message-circle.svg" alt="" />
              Tambah Balasan</b-button
            >
          </div>
        </b-card>
      </div>

      <div class="card-reply">
        <b-card v-for="(item, idx) in isiBalas" :key="idx">
          <div class="content">
            <b-card-text
              style="
                font-weight: bold;
                display: flex;
                width: 100%;
                border-bottom: 1px solid #dcdee0;
                padding-bottom: 1%;
              "
            >
              <div
                class="user"
                style="display: flex; align-items: center; width: 100%"
              >
                <b-avatar v-if="item.foto" :src="item.foto" rounded> </b-avatar>
                <b-avatar v-else rounded> </b-avatar>
                <div
                  class="text"
                  style="
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: flex-end;
                  "
                >
                  <span style="margin-left: 1%">
                    <span style="text-transform: capitalize">{{
                      item.nama
                    }}</span>
                    <br />
                    <span style="font-weight: 500">{{ item.email }}</span>
                  </span>

                  <span
                    style="
                      font-weight: 500;
                      color: #b5bac3;
                      display: flex;
                      flex-direction: column;
                      align-items: flex-end;
                    "
                  >
                    <span style="color: #061737; text-transform: capitalize"
                      >#{{ idx + 1 }}</span
                    >
                    <span style="font-size: 14px; color: #a0a0a0"
                      >dibalas pada tanggal {{ item.createdAt }}</span
                    >
                  </span>
                </div>
              </div>
            </b-card-text>
            <b-card-text>
              {{ item.balasan }}
            </b-card-text>
          </div>
        </b-card>
      </div>
    </div>
    <b-modal hide-footer hide-header centered ref="modal-balas" size="lg">
      <div class="tutup" style="display: flex; justify-content: flex-end">
        <b-button
          @click="tutupModal1"
          style="background-color: white; border: none"
          ><img
            src="../../assets/icon/close.svg"
            alt=""
            style="width: 15px; height: 15px"
        /></b-button>
      </div>
      <div
        class="content"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 1% 2%;
        "
      >
        <span
          class="tittle"
          style="
            font-family: 'nunito', sans-serif;
            font-size: 28px;
            font-weight: 700;
            text-transform: capitalize;
          "
        >
          Balas Thread
        </span>
        <div class="form" style="width: 95%; margin-top: 4%">
          <b-form-textarea
            style="margin-top: 2%"
            id="textarea"
            v-model="balasan"
            placeholder="Balas thread..."
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </div>
      </div>
      <div
        class="wrapper"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 0 2%;
        "
      >
        <div
          class="button-wrapper"
          style="display: flex; width: 95%; justify-content: flex-end"
        >
          <b-button
            @click="doBalas"
            v-if="loading == false"
            style="
              background-color: #327ff2;
              color: #fff;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              width: 25%;
              border: none;
              margin-top: 20px;
              height: 40px;
            "
            >Post Balasan</b-button
          >
          <b-button
            disabled
            v-else
            style="
              background-color: #327ff2;
              color: #fff;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              width: 25%;
              border: none;
              margin-top: 20px;
              height: 40px;
            "
            ><white-spinner
          /></b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "@/ip_config.js";
import moment from "moment";
moment.locale("id");
export default {
  data() {
    return {
      isi: "",
      balasan: "",
      isiBalas: "",
      jmlBalas: 0,
      reply: [],
      loading: true,
    };
  },
  async mounted() {
    await this.getThread();
    await this.getBalasan();
  },

  methods: {
    async getThread() {
      let vm = this;
      let y = localStorage.getItem("idThread");
      let fetch = await axios.get("thread/threadById/" + y, {
        headers: { token: localStorage.getItem("token") },
      });
      vm.isi = fetch.data[0];
      vm.isi.isi.substring(0, 120) + "...";
      vm.isi.createdAt = moment(vm.isi.createdAt).format("LL");

      let profile = await axios.get("users/profileById/" + vm.isi.userId, {
        headers: {
          token: localStorage.getItem("token"),
        },
      });
      profile.data.forEach((el) => {
        vm.isi.ava = "https://engine.kapmobile.id/" + el.foto;
        vm.isi.nama = el.nama;
      });
    },

    async balasThread() {
      this.$refs["modal-balas"].show();
    },

    async getBalasan() {
      let x = localStorage.getItem("token");
      let fetch = await axios.get("reply/listByThread/" + this.isi.id, {
        headers: { token: x },
      });
      this.isiBalas = fetch.data;
      this.jmlBalas = fetch.data.length;
      this.isiBalas.forEach(async (el) => {
        el.createdAt = moment(el.createdAt).format("LL");
        let payload = await axios.get("users/profileById/" + el.userId, {
          headers: {
            token: localStorage.getItem("token"),
          },
        });
        payload.data.forEach((elm) => {
          el.foto = "https://engine.kapmobile.id/" + elm.foto;
        });
      });
      setTimeout(() => {
        this.loading = false;
      }, 800);
    },

    doBalas() {
      let vm = this;
      axios
        .post(
          ip_server + "reply/register",
          {
            balasan: vm.balasan,
            threadId: vm.isi.id,
            jabatan: vm.isi.jabatan,
          },
          {
            headers: { token: localStorage.getItem("token") },
          }
        )
        .then((res) => {
          vm.reply = res.data;
          this.$refs["modal-balas"].hide();
          this.getBalasan();
        });
    },
    tutupModal1() {
      this.$refs["modal-balas"].hide();
    },
    back() {
      this.$emit("changeView", 1);
      this.$emit("idThread", "");
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");

$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white": #fafafa,
  "white2": #fff,
  "gray-dark": #b5bac3,
  "blue-light": #e8f3fe,
  "red-light": #ffe8e7,
  "green-light": #eafffa,
  "green": #6adbbe,
);

.header {
  padding: 2%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .judul {
    font-family: "nunito", sans-serif;
    font-size: 30px;
    text-transform: capitalize;
  }
  .button-wrapper {
    width: 15%;
    display: flex;
    justify-content: flex-end;
    .btn {
      border: none !important;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      font-family: "nunito", sans-serif;
      width: 100%;
      box-shadow: 0px 20px 25px -10px rgba(1, 195, 137, 0.25);
      background-color: map-get($color, "green");
    }
  }
}

.wrapper {
  font-family: "nunito", sans-serif;
  .card-wrapper {
    max-height: 60vh;
    overflow-y: scroll;
    padding: 2%;
    border-radius: 7px;
    border: 1px solid #dcdee0;
    .card-thread {
      .card {
        background-color: map-get($color, "white");
        border-radius: 7px;
        min-height: 250px;
        max-height: 300px;
        .card-body {
          flex-direction: column;
          justify-content: space-between;
          display: flex;
          .content {
            width: 100%;
            .user {
              display: flex;
              align-items: center;
              width: 100%;
              border-bottom: 1px solid #dcdee0;
              padding-bottom: 1%;
            }
            .text {
              display: flex;
              width: 100%;
              justify-content: space-between;
              align-items: flex-end;
            }
            .isi-thread {
              margin-top: 1%;
            }
          }
          .button-wrapper1 {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-weight: 700;
            span {
              margin-right: 2%;
            }
            .btn {
              border: none !important;
              display: flex;
              align-items: center;
              justify-content: space-evenly;
              font-family: "nunito", sans-serif;
              background-color: map-get($color, "blue");
              width: 18%;
            }
          }
        }
      }
    }
    .card-reply {
      padding-left: 2%;
      padding-right: 2%;
      margin-top: 2%;
      .card {
        border: 1px solid #dcdee0;
        margin-top: 1%;
        border-radius: 7px;
        overflow: hidden;
        background: #f3f3f3;
        min-height: 150px;
      }
    }
  }
}
</style>
