<template>
  <div class="wrapper">
    <b-modal hide-footer hide-header centered ref="modal-tambah" size="lg">
      <div class="tutup" style="display: flex; justify-content: flex-end">
        <b-button
          @click="tutupModal1"
          style="background-color: white; border: none"
          ><img
            src="../../assets/icon/close.svg"
            alt=""
            style="width: 15px; height: 15px"
        /></b-button>
      </div>
      <div
        class="content"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 1% 2%;
        "
      >
        <span
          class="tittle"
          style="
            font-family: 'nunito', sans-serif;
            font-size: 28px;
            font-weight: 700;
            text-transform: capitalize;
          "
        >
          Buat Akun
        </span>
        <div class="form" style="width: 95%; margin-top: 4%">
          <b-form-group
            label-size="lg"
            label-cols="5"
            label-cols-lg="3"
            label="Nama Akun"
            label-for="input-formatter"
          >
            <b-form-input
              id="input-formatter"
              placeholder="Nama Akun"
              style="border-radius: 10px; margin-bottom: 15px"
              size="lg"
              type="text"
              v-model="namaAkunHead"
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            label-size="lg"
            label-cols="5"
            label-cols-lg="3"
            label="Kode Akun"
            label-for="input-formatter"
          >
            <b-form-input
              placeholder="Kode Akun"
              style="border-radius: 10px; margin-bottom: 15px"
              size="lg"
              type="text"
              v-model="kodeHead"
            >
            </b-form-input>
          </b-form-group>

          <b-form-group
            label-size="lg"
            label-cols="5"
            label-cols-lg="3"
            label="Kategori Akun"
            label-for="input-formatter"
          >
            <b-form-input
              placeholder="Kategori Akun"
              style="border-radius: 10px; margin-bottom: 15px"
              size="lg"
              type="text"
              v-model="kategoriHead"
            >
            </b-form-input>
          </b-form-group>
        </div>
      </div>
      <div
        class="wrapper"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 0 2%;
        "
      >
        <div
          class="button-wrapper"
          style="display: flex; width: 95%; justify-content: flex-end"
        >
          <b-button
            @click="doTambah"
            v-if="loading == false"
            style="
              background-color: #327ff2;
              color: #fff;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              border: none;
              margin-top: 20px;
              height: 40px;
              align-items: center;
            "
            >Tambah Akun</b-button
          >
          <b-button
            disabled
            v-else
            style="
              background-color: #327ff2;
              color: #fff;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              width: 25%;
              border: none;
              margin-top: 20px;
              height: 40px;
            "
            ><white-spinner
          /></b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "@/ip_config.js";
import { mapState } from "vuex";
import _ from "lodash";
export default {
  computed: {
    ...mapState("apiProject", ["stateProject"]),
  },
  props: ["flag", "load"],
  data() {
    return {
      loading: false,
      namaAkunHead: "",
      kodeHead: "",
      tahunAkunHead: "",
      projectId: "",
      lastRows: [],
      kategoriHead: "",
    };
  },
  watch: {
    flag: function(newVal, oldVal) {
      if (newVal != oldVal) {
        this.$refs["modal-tambah"].show();
        this.lastRows = this.load[this.load.length - 1];
        let kod = parseInt(this.lastRows.kodeAkun);
        this.kodeHead = kod + 1;
      }
    },
  },
  methods: {
    doTambah() {
      let vm = this;
      let x = localStorage.getItem("token");
      let y = localStorage.getItem("idProject");
      axios
        .post(
          "akun/head/createAkun",
          {
            namaAkunHead: vm.namaAkunHead,
            kodeHead: vm.kodeHead,
            tahunAkunHead: this.stateProject.tahunProject,
            projectId: y,
            kategoriHead: _.toUpper(vm.kategoriHead),
          },
          {
            headers: { token: x },
          }
        )
        .then((res) => {
          this.$emit("refresh");
          if (res.data.status == 200) {
            vm.pesan = "sukses membuat akun";
            vm.alert2 = true;
            setTimeout(() => {
              vm.alert2 = false;
            }, 3000);
            vm.namaAkunHead = "";
            vm.kodeHead = "";
            this.$refs["modal-tambah"].hide();
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response) {
            this.$emit("refresh");
            vm.pesan = "kode sudah terdaftar";
            vm.alert = true;
            setTimeout(() => {
              vm.alert = false;
            }, 3000);
            vm.namaAkunHead = "";
            vm.kodeHead = "";
            this.$refs["modal-tambah"].hide();
          }
        });
    },

    tutupModal1() {
      this.$refs["modal-tambah"].hide();
    },
  },
};
</script>
