<template>
  <div class="wrapper">
    <div
      style="width: 100%; height: 50px"
      class="d-md-block d-lg-block d-xl-none"
    ></div>
    <div class="button-wrapper">
      <b-button @click="openModal"
        ><img src="../../assets/icon/plus-circle.svg" alt="" />
        <span class="text">Transaksi Penyesuaian</span></b-button
      >
      <b-button
        v-if="show == false && tampil == false"
        @click="showAll(), (tampil = true)"
        ><span class="text">Tampilkan Semua</span></b-button
      >
      <b-button
        v-else-if="show == false && tampil == true"
        @click="showAll(), (tampil = false)"
        ><span class="text">Tutup Semua</span></b-button
      >
      <b-button v-else-if="show == true" disabled
        ><span class="text">Mohon Tunggu</span></b-button
      >
    </div>
    <b-modal hide-footer hide-header centered ref="modal-edit" size="xl">
      <div class="tutup" style="display: flex; justify-content: flex-end">
        <b-button
          @click="tutupModal1"
          style="background-color: white; border: none"
          ><img
            src="../../assets/icon/close.svg"
            alt=""
            style="width: 15px; height: 15px"
        /></b-button>
      </div>
      <div
        class="content"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 1% 2%;
        "
      >
        <span
          class="tittle"
          style="
            font-family: 'nunito', sans-serif;
            font-size: 28px;
            font-weight: 700;
            text-transform: capitalize;
          "
        >
          Ubah Transaksi Jurnal
        </span>
        <div class="form" style="width: 95%; margin-top: 4%">
          <span style="font-family: 'nunito', sans-serif; font-weight: 600"
            >Nama Transaksi</span
          >
          <b-form-input
            id="input-formatter"
            placeholder="Deskripsi Jurnal"
            style="border-radius: 10px; text-transform: capitalize"
            size="lg"
            type="text"
            v-model="keterangan"
            disabled
          >
          </b-form-input>

          <div
            class="button-wrapper2"
            style="
              width: 100%;
              margin-top: 4%;
              margin-bottom: 1%;
              display: flex;
              justify-content: flex-end;
            "
          >
            <div class="text" style="text-align: center; width: 100%">
              <span
                style="
                  color: red;
                  text-transform: capitalize;
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 20px;
                "
                v-if="banding && banding != '--'"
                >Ada Selisih Sebanyak {{ banding }}!</span
              >
            </div>
            <b-button
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #327ff2;
                border: none;
                border-radius: 7px;
              "
              @click="tambahForm"
            >
              <img
                style="height: 24px"
                src="../../assets/icon/plus-circle.svg"
                alt=""
              />
            </b-button>
          </div>

          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
            "
            class="loop"
            v-for="(list, is) in forms"
            :key="is"
          >
            <div class="select" style="margin-bottom: 1%; width: 24%">
              <span style="font-family: 'nunito', sans-serif; font-weight: 600"
                >Kode Akun</span
              >
              <b-form-select
                v-model="forms[is].kodeSubsifik"
                style="border-radius: 7px"
                :options="options"
              ></b-form-select>
            </div>
            <div
              class="select"
              style="
                display: flex;
                flex-direction: column;
                margin-bottom: 1%;
                width: 24%;
              "
            >
              <span style="font-family: 'nunito', sans-serif; font-weight: 600"
                >Nominal Debit</span
              >
              <b-form-input
                v-model="forms[is].debit"
                id="input-formatter"
                placeholder="Masukan Nominal"
                style="border-radius: 7px"
                type="number"
              >
              </b-form-input>
            </div>
            <div
              class="select"
              style="
                display: flex;
                flex-direction: column;
                margin-bottom: 1%;
                width: 24%;
              "
            >
              <span style="font-family: 'nunito', sans-serif; font-weight: 600"
                >Nominal Kredit</span
              >
              <b-form-input
                v-model="forms[is].kredit"
                id="input-formatter"
                placeholder="Masukan Nominal"
                style="border-radius: 7px"
                type="number"
              >
              </b-form-input>
            </div>
            <div
              class="select"
              style="
                display: flex;
                flex-direction: column;
                margin-bottom: 1%;
                width: 24%;
              "
            >
              <span style="font-family: 'nunito', sans-serif; font-weight: 600"
                >Catatan</span
              >
              <b-form-input
                v-model="forms[is].catatanSub"
                placeholder="Masukan Catatan"
                style="border-radius: 7px"
                type="text"
              >
              </b-form-input>
            </div>
          </div>
        </div>
        <div
          class="pembanding"
          style="
            width: 95%;
            display: flex;
            margin-bottom: 1%;
            justify-content: space-between;
          "
        >
          <span
            style="
              width: 20%;
              font-family: 'nunito', sans-serif;
              font-weight: 600;
            "
            >Total</span
          >
          <span style="width: 20%; height: 37px">{{ totalDebit }}</span>
          <span style="width: 30%; height: 37px; margin-right: 18%">{{
            totalKredit
          }}</span>
        </div>
      </div>
      <div
        class="wrapper"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 0 2%;
        "
      >
        <div
          class="button-wrapper"
          style="display: flex; width: 95%; justify-content: flex-end"
        >
          <b-button
            @click="simpanEdit"
            style="
              background-color: #327ff2;
              color: #fff;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              width: 25%;
              display: flex;
              justify-content: center;
              border: none;
              margin-top: 20px;
              height: 40px;
              border-radius: 7px;
            "
            >Simpan</b-button
          >
        </div>
      </div>
    </b-modal>

    <b-modal hide-footer hide-header centered ref="modal-tambah" size="xl">
      <div class="tutup" style="display: flex; justify-content: flex-end">
        <b-button
          @click="tutupModal1"
          style="background-color: white; border: none"
          ><img
            src="../../assets/icon/close.svg"
            alt=""
            style="width: 15px; height: 15px"
        /></b-button>
      </div>
      <div
        class="content"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 1% 2%;
        "
      >
        <span
          class="tittle"
          style="
            font-family: 'nunito', sans-serif;
            font-size: 28px;
            font-weight: 700;
            text-transform: capitalize;
          "
        >
          Tambah Transaksi Jurnal
        </span>
        <div class="form" style="width: 95%; margin-top: 4%">
          <b-form-input
            id="input-formatter"
            placeholder="Nama Transaksi"
            style="border-radius: 10px; text-transform: capitalize"
            size="lg"
            type="text"
            v-model="keterangan"
          >
          </b-form-input>

          <div
            class="button-wrapper2"
            style="
              width: 100%;
              margin-top: 4%;
              margin-bottom: 1%;
              display: flex;
              justify-content: flex-end;
            "
          >
            <div class="text" style="text-align: center; width: 100%">
              <span
                style="
                  color: red;
                  text-transform: capitalize;
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 20px;
                "
                v-if="banding && banding != '--'"
                >Ada Selisih Sebanyak {{ banding }}!</span
              >
            </div>
            <b-button
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #327ff2;
                border: none;
              "
              @click="tambahForm"
            >
              <img
                style="height: 24px"
                src="../../assets/icon/plus-circle.svg"
                alt=""
              />
            </b-button>
          </div>

          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
            "
            class="loop"
            v-for="(list, is) in forms"
            :key="is"
          >
            <div class="select" style="margin-bottom: 1%; width: 24%">
              <span style="font-family: 'nunito', sans-serif; font-weight: 600"
                >Pilih Kode Akun</span
              >
              <b-form-select
                required
                v-model="forms[is].kodeSubsifik"
                style="border-radius: 10px"
                :options="options"
              ></b-form-select>
            </div>
            <div
              class="select"
              style="
                display: flex;
                flex-direction: column;
                margin-bottom: 1%;
                width: 24%;
              "
            >
              <span style="font-family: 'nunito', sans-serif; font-weight: 600"
                >Nominal Debit</span
              >
              <b-form-input
                v-model="forms[is].debit"
                id="input-formatter"
                placeholder="Masukan Nominal"
                style="border-radius: 10px"
                type="number"
              >
              </b-form-input>
            </div>
            <div
              class="select"
              style="
                display: flex;
                flex-direction: column;
                margin-bottom: 1%;
                width: 24%;
              "
            >
              <span style="font-family: 'nunito', sans-serif; font-weight: 600"
                >Nominal Kredit</span
              >
              <b-form-input
                v-model="forms[is].kredit"
                id="input-formatter"
                placeholder="Masukan Nominal"
                style="border-radius: 10px"
                type="number"
              >
              </b-form-input>
            </div>
            <div
              class="select"
              style="
                display: flex;
                flex-direction: column;
                margin-bottom: 1%;
                width: 24%;
              "
            >
              <span style="font-family: 'nunito', sans-serif; font-weight: 600"
                >Catatan</span
              >
              <b-form-input
                v-model="forms[is].catatanSub"
                placeholder="Masukan Catatan"
                style="border-radius: 10px"
                type="text"
              >
              </b-form-input>
            </div>
          </div>
        </div>
        <div
          class="pembanding"
          style="
            width: 95%;
            display: flex;
            margin-bottom: 1%;
            justify-content: space-between;
          "
        >
          <span
            style="
              width: 20%;
              font-family: 'nunito', sans-serif;
              font-weight: 600;
            "
            >Total</span
          >
          <span style="width: 20%; height: 37px">{{ totalDebit }}</span>
          <span style="width: 30%; height: 37px; margin-right: 18%">{{
            totalKredit
          }}</span>
        </div>
      </div>
      <div
        class="wrapper"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 0 2%;
        "
      >
        <div
          class="button-wrapper"
          style="display: flex; width: 95%; justify-content: flex-end"
        >
          <b-button
            v-if="loadingCreate == false"
            @click="simpanEdit"
            style="
              background-color: #327ff2;
              color: #fff;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              width: 25%;
              border: none;
              display: flex;
              justify-content: center;
              margin-top: 20px;
              height: 40px;
            "
            >Simpan</b-button
          >
          <b-button
            disabled
            v-else
            style="
              background-color: #327ff2;
              color: #fff;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              width: 25%;
              border: none;
              display: flex;
              justify-content: center;
              margin-top: 20px;
              height: 40px;
            "
            >Mohon Tunggu</b-button
          >
        </div>
      </div>
    </b-modal>

    <b-overlay :show="show" spinner-variant="primary" rounded="sm">
      <div class="table">
        <vue-ads-table
          :columns="columns"
          :rows="rows"
          :filter="filter"
          :start="start"
          :end="end"
          :classes="classes"
        >
          <template slot="sort-icon" slot-scope="props">
            ({{
              props.direction === null
                ? "null"
                : props.direction
                ? "up"
                : "down"
            }})
          </template>
          <template slot="toggle-children-icon" slot-scope="props">
            [{{ props.expanded ? "-" : "+" }}]
          </template>
          <template slot="aksi" slot-scope="props"
            ><img
              style="
                border: none;
                background-color: #f4b366;
                padding: 5%;
                border-radius: 4px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
              "
              v-if="props.row.nomor"
              @click="edit(props.row)"
              src="../../assets/icon/edit-2.svg"
              alt=""
            />
          </template>
        </vue-ads-table>
      </div>
    </b-overlay>

    <div class="alerts">
      <b-alert
        class="succ"
        v-model="showDismissibleAlert"
        variant="success"
        dismissible
      >
        <span class="text">{{ success }}!</span>
      </b-alert>
      <b-alert class="succ" v-model="showWrong" variant="danger" dismissible>
        <span class="text">{{ wrong }}!</span>
      </b-alert>
    </div>
  </div>
</template>

<script>
import { VueAdsTable } from "vue-ads-table-tree";
import axios from "@/ip_config.js";
import accounting from "accounting-js";
import "../../../node_modules/vue-ads-table-tree/dist/vue-ads-table-tree.css";
export default {
  components: {
    VueAdsTable,
  },
  data() {
    let classes = {
      table: {
        "vue-ads-border": true,
        "vue-ads-w-full": true,
        "vue-ads-font-bold": true,
      },
      info: {
        "vue-ads-text-center": true,
        "vue-ads-py-6": true,
        "vue-ads-text-sm": true,
        "vue-ads-border-t": true,
      },
      group: {
        "vue-ads-border-b": true,
        "vue-ads-italic": true,
      },
      selected: {
        "vue-ads-bg-teal-100": true,
      },
      "all/": {
        "hover:vue-ads-bg-gray-200": true,
      },
      "all/all": {
        "vue-ads-px-4": true,
        "vue-ads-py-2": true,
        "vue-ads-text-sm": true,
      },
      "even/": {
        "vue-ads-bg-white": true,
      },
      "odd/": {
        "vue-ads-bg-gray-100": true,
      },
      "0/all": {
        "vue-ads-px-4": true,
        "vue-ads-py-2": true,
        "vue-ads-text-center": true,
      },
      "0_-1/": {
        "vue-ads-border-b": true,
      },
      "/0_-1": {
        "vue-ads-border-r": true,
      },
      "1_/": {
        "vue-ads-text-blue": true,
      },
    };

    // let rows = [
    //   {}
    // ];

    let columns = [
      {
        property: "nomor",
        title: "No#",
        filterable: true,
      },
      {
        property: "keterangan",
        title: "Nama Transaksi",
        filterable: true,
        collapseIcon: true,
      },
      {
        property: "debit",
        title: "Debit",
        filterable: true,
      },
      {
        property: "kredit",
        title: "Kredit",
        filterable: true,
      },
      {
        property: "catatanSub",
        title: "Catatan",
        filterable: true,
      },
      {
        property: "aksi",
        title: "Aksi",
        filterable: true,
      },
    ];

    let forms = [
      {
        kodeSubsifik: "",
        catatanSub: "",
        debit: 0,
        kredit: 0,
      },
    ];
    return {
      columns,
      options: [],
      rows: [
        {
          keterangan: "",
          nomor: "",
          jurnalPenyesuaianId: "",
          _showChildren: false,
          _children: [
            {
              kodeSubsifik: "",
              keterangan: "",
              catatanSub: "",
              debit: "",
              kredit: "",
            },
          ],
        },
      ],
      forms,
      data: [],
      filter: "",
      loadingCreate: false,
      start: 0,
      end: 10000,
      classes,
      keterangan: "",
      currentPage: 1,
      loading: true,
      success: "",
      wrong: "",
      showDismissibleAlert: false,
      showWrong: false,
      formsId: "",
      kredit: 0,
      debit: 0,
      totalKredit: 0,
      totalDebit: 0,
      banding: "",
      jurnalPenyesuaianId: "",
      show: false,
      tampil: false,
      buatEdit: [],
    };
  },
  beforeMount() {
    if (this.loading == false) {
      this.loading = true;
    }
    this.forms = [
      {
        kodeSubsifik: "",
        debit: 0,
        kredit: 0,
      },
    ];
  },
  watch: {
    bandingKredit(newVal, oldVal) {
      let sum = 0;
      newVal.forEach((el) => {
        sum += Number(el);
      });
      this.kredit = sum;
      this.banding = sum - this.debit;
      let hasil = accounting.formatMoney(this.banding, {
        decimal: ",",
        thousand: ".",
        format: {
          pos: "%v",
          neg: "(%v)",
          zero: "--",
        },
      });
      this.banding = hasil;
      this.totalKredit = accounting.formatMoney(this.kredit, {
        decimal: ",",
        thousand: ".",
        format: {
          pos: "%v",
          neg: "(%v)",
          zero: "--",
        },
      });
    },
    bandingDebit(newVal, oldVal) {
      let sum = 0;
      newVal.forEach((el) => {
        sum += Number(el);
      });
      this.debit = sum;
      this.banding = sum - this.kredit;
      let hasil = accounting.formatMoney(this.banding, {
        decimal: ",",
        thousand: ".",
        format: {
          pos: "%v",
          neg: "(%v)",
          zero: "--",
        },
      });
      this.banding = hasil;
      this.totalDebit = accounting.formatMoney(this.debit, {
        decimal: ",",
        thousand: ".",
        format: {
          pos: "%v",
          neg: "(%v)",
          zero: "--",
        },
      });
    },
  },
  computed: {
    bandingKredit() {
      return this.forms.map((i) => {
        return i.kredit;
      });
    },
    bandingDebit() {
      return this.forms.map((i) => {
        return i.debit;
      });
    },
  },
  mounted() {
    let chunga = localStorage.getItem("token");
    let vm = this;

    axios
      .get(
        "akun/subsifik/sublifikasiSubfikByProjectId/" + this.$route.params.id,
        { headers: { token: chunga } }
      )
      .then((res) => {
        let payload = [];
        res.data.data.forEach((el) => {
          if (el.options) {
            payload.push(el);
          }
        });
        payload.forEach((el, id) => {
          el.text = el.namaAkunSublifikasi;
          el.keterangan = el.namaAkunSublifikasi;
          el.nomor = id + 1;
          el.options.forEach((elm) => {
            elm.value = elm.kodeSubsifik;
            elm.text =
              elm.kodeSubsifik + " " + "-" + " " + elm.namaAkunSubsifik;
            elm.debit = 0;
            elm.kredit = 0;
          });
        });
        vm.options = payload;

        axios
          .get(
            "jurnal/penyesuaian/checkByProjectAkun/" + this.$route.params.id,
            {
              headers: {
                token: chunga,
              },
            }
          )
          .then((res) => {
            this.buatEdit = res.data.data;
            let resp = [];

            res.data.data.forEach((el) => {
              resp.push(el);
            });
            if (resp.length > 0) {
              resp.forEach((item, id) => {
                item.nomor = id + 1;
                item._children.forEach((elm) => {
                  elm.kredit = this.formats(elm.kredit);
                  elm.debit = this.formats(elm.debit);
                });
              });
            }
            vm.rows = resp;

            // !-----------NGAMBIL KETERANGAN-----------!
            payload.forEach((el) => {
              el.options.forEach((elm) => {
                vm.rows.forEach((elx) => {
                  elx._children.forEach((elme) => {
                    if (elme.kodeSubsifik == elm.kodeSubsifik) {
                      elme.keterangan = elm.namaAkunSubsifik;
                    }
                  });
                });
              });
            });
          });
      });
  },
  methods: {
    formats(money) {
      money = accounting.formatMoney(money, {
        symbol: "",
        precicsion: 2,
        thousand: ".",
        decimal: ",",
        format: {
          pos: "%v",
          neg: "(%v)",
          zero: "-",
        },
      });
      return money;
    },
    showAll() {
      let vm = this;
      vm.show = true;

      setTimeout(async () => {
        await vm.rows.forEach((el) => {
          if (el._showChildren == false) {
            el._showChildren = true;
          } else {
            el._showChildren = false;
          }
        });
      }, 500);

      setTimeout(() => {
        vm.show = false;
      }, 1000);
    },

    tambahForm() {
      this.forms.push({
        kodeSubsifik: "",
        catatanSub: "",
        debit: 0,
        kredit: 0,
      });
    },
    refresh() {
      let chunga = localStorage.getItem("token");
      let vm = this;
      console.log("as");

      axios
        .get(
          "akun/subsifik/sublifikasiSubfikByProjectId/" + this.$route.params.id,
          { headers: { token: chunga } }
        )
        .then((res) => {
          let payload = [];
          res.data.data.forEach((el) => {
            if (el.options) {
              payload.push(el);
            }
          });

          payload.forEach((el, id) => {
            el.text = el.namaAkunSublifikasi;
            el.keterangan = el.namaAkunSublifikasi;
            el.nomor = id + 1;
            el.options.forEach((elm) => {
              elm.value = elm.kodeSubsifik;
              elm.text =
                elm.kodeSubsifik + " " + "-" + " " + elm.namaAkunSubsifik;
              elm.debit = 0;
              elm.kredit = 0;
            });
          });
          vm.options = payload;

          axios
            .get(
              "jurnal/penyesuaian/checkByProjectAkun/" + this.$route.params.id,
              {
                headers: {
                  token: chunga,
                },
              }
            )
            .then((res) => {
              let resp = [];

              res.data.data.forEach((el) => {
                resp.push(el);
              });

              if (resp.length > 0) {
                resp.forEach((item, id) => {
                  item.nomor = id + 1;
                  item._children.forEach((elm) => {
                    elm.kredit = this.formats(elm.kredit);
                    elm.debit = this.formats(elm.debit);
                  });
                });
              }
              vm.rows = resp;
              console.log(vm.rows);

              // !-------NGAMBIL KETERANGAN-----!
              payload.forEach((el) => {
                el.options.forEach((elm) => {
                  vm.rows.forEach((elx) => {
                    elx._children.forEach((elme) => {
                      if (elme.kodeSubsifik == elm.kodeSubsifik) {
                        elme.keterangan = elm.namaAkunSubsifik;
                      }
                    });
                  });
                });
              });
            });
        });
    },

    pick() {
      this.options.forEach((el) => {
        el.options.forEach((elme) => {
          this.forms.forEach((elm) => {
            if (elme.kodeSubsifik == elm.kodeSubsifik) {
              this.keterangan = el.keterangan;
            }
          });
        });
      });
    },

    openModal() {
      this.kredit = 0;
      this.debit = 0;
      this.banding = 0;
      this.forms = [
        {
          kodeSubsifik: "",
          debit: 0,
          kredit: 0,
        },
      ];
      this.keterangan = "";
      this.$refs["modal-tambah"].show();
    },

    tutupModal1() {
      this.$refs["modal-tambah"].hide();
      this.$refs["modal-edit"].hide();
    },

    edit(data) {
      let q = [];
      axios
        .get("jurnal/penyesuaian/checkByProjectAkun/" + this.$route.params.id, {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((res) => {
          let x = res.data.data.filter((el) => {
            return el.jurnalPenyesuaianId == data.jurnalPenyesuaianId;
          });
          x.map((item) => {
            item._children.map((child) => {
              child.debit = Number(child.debit);
              child.kredit = Number(child.kredit);
              q.push(child);
            });
          });
        });
      this.forms = [
        {
          kodeSubsifik: "",
          catatanSub: "",
          debit: "",
          kredit: "",
        },
      ];
      if (this.forms.length < data._children.length) {
        this.tambahForm();
      }
      this.forms = q;
      this.$refs["modal-edit"].show();
      this.kredit = 0;
      this.debit = 0;
      this.banding = 0;
      this.keterangan = data.keterangan;
      this.formsId = data.kodeSublifikasi;
      this.jurnalPenyesuaianId = data.jurnalPenyesuaianId;
    },

    simpanEdit() {
      let architects = localStorage.getItem("token");
      let kap = localStorage.getItem("idKAP");
      let vm = this;
      vm.loadingCreate = true;
      let go = false;

      vm.forms.forEach((el) => {
        el.kodeSublifikasi = vm.formsId;
        if (el.kodeSubsifik == "") {
          go = true;
        }
      });

      if (go == false) {
        axios
          .post(
            "jurnal/penyesuaian/bulkFunction/" + vm.$route.params.id,
            {
              bulkData: vm.forms,
              keteranganSublifikasi: vm.keterangan,
              jurnalPenyesuaianId: vm.jurnalPenyesuaianId,
              KAPId: Number(kap),
            },
            {
              headers: {
                token: architects,
              },
            }
          )
          .then(() => {
            vm.refresh();
            vm.loadingCreate = false;
            vm.success = "Berhasil mengubah transaksi";
            vm.showDismissibleAlert = true;
            setTimeout(() => {
              vm.showDismissibleAlert = false;
            }, 4000);
            vm.forms = [
              {
                kodeSubsifik: "",
                debit: 0,
                kredit: 0,
              },
            ];
            vm.jurnalPenyesuaianId = "";
            this.$refs["modal-edit"].hide();
            this.$refs["modal-tambah"].hide();
          })
          .catch((err) => {
            vm.wrong = "Gagal mengubah transaksi";
            vm.showWrong = true;
            vm.loadingCreate = false;
            vm.jurnalPenyesuaianId = "";
            vm.forms = [
              {
                kodeSubsifik: "",
                debit: 0,
                kredit: 0,
              },
            ];
            setTimeout(() => {
              vm.showWrong = false;
            }, 4000);
            vm.$refs["modal-edit"].hide();
            this.$refs["modal-tambah"].hide();
            this.refresh();
          });
      } else if (go == true) {
        vm.wrong = "Harap periksa kembali!";
        vm.showWrong = true;
        vm.loadingCreate = false;
        setTimeout(() => {
          vm.showWrong = false;
        }, 4000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");
$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white": #fafafa,
  "white2": #fff,
  "gray-dark": #b5bac3,
  "blue-light": #e8f3fe,
  "red-light": #ffe8e7,
  "green-light": #eafffa,
  "green": #6adbbe,
);

.wrapper {
  position: relative;
  .alerts {
    position: sticky;
    width: 90%;
    display: flex;
    z-index: 100;
    transform: translateY(-180%);
    align-items: flex-end;
    justify-content: center;
    .succ {
      width: 50%;
      text-transform: capitalize;
      .text {
        font-weight: 600;
        font-size: 18px;
      }
    }
  }
  .table {
    margin-top: 0.8%;
    max-height: 67.8vh;
    min-height: 67.8vh;
    border-radius: 7px;
    overflow-y: scroll;
    width: 100%;
    border: 1px solid map-get($color, "gray-dark");
    table {
      width: 100%;
      min-width: 100%;
      font-family: "Nunito", sans-serif;
      .test-row {
        background-color: #061737;
        font-size: 20px;
        font-family: "nunito", sans-serif;
      }
    }
  }
  .button-wrapper {
    display: flex;
    width: 100%;
    .btn {
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: map-get($color, "blue");
      font-family: "nunito", sans-serif;
      text-transform: capitalize;
      border: none;
      margin-right: 2%;
      .text {
        font-size: 14px;
      }
      img {
        width: 10%;
      }
    }
  }
}
</style>
