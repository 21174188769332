<template>
  <div>
    <section class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
      <nav_bar />
      <div
        style="
          width: 96%;
          height: 65px;
          background: #fff;
          box-shadow: 0px 10px 35px -10px rgba(123, 156, 160, 0.1);
          border-radius: 10px;
          position: fixed;
          left: 0;
          right: 0;
          top: 80px;
          margin: auto;
          z-index: 2;
          display: flex;
        "
      >
        <div
          style="
            width: 45%;
            height: 65px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-left: 15px;
          "
        >
          <b-breadcrumb>
            <b-breadcrumb-item @click="goBack3()">
              Dashboard
            </b-breadcrumb-item>
            <b-breadcrumb-item id="target1" @click="goBack2()">
              <b-tooltip target="target1" triggers="hover">{{
                stateProject.namaKAP
              }}</b-tooltip>
              {{ stateProject.namaKAP1 }}</b-breadcrumb-item
            >
            <b-breadcrumb-item id="target2" @click="goBack()">
              <b-tooltip target="target2" triggers="hover">{{
                namaClient1
              }}</b-tooltip>
              {{ namaClient }}</b-breadcrumb-item
            >
            <b-breadcrumb-item id="target3" active>
              <b-tooltip target="target3" triggers="hover">{{
                stateProject.namaProject
              }}</b-tooltip>
              {{ stateProject.namaProject1 }}</b-breadcrumb-item
            >
          </b-breadcrumb>
        </div>
        <div
          style="
            width: 100%;
            height: 65px;
            background-color: ;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 15px;
          "
        >
          <b-button
            :class="{ tabActive: currentTab == 1 }"
            class="d-md-none d-lg-none d-xl-block"
            @click="selectTab(1)"
              @click.right="openNewTab(1)"
              @click.middle="openNewTab(1)"
              @contextmenu.prevent
            >Dashboard</b-button
          >
          <b-button
            :class="{ tabActive: currentTab == 2 }"
            class="d-md-none d-lg-none d-xl-block"
            @click="selectTab(2)"
                          @click.right="openNewTab(2)"
              @click.middle="openNewTab(2)"
              @contextmenu.prevent
            >Lembar Kerja
          </b-button>
          <b-button
            :class="{ tabActive: currentTab == 3 }"
            class="d-md-none d-lg-none d-xl-block"
            @click="selectTab(3)"
                          @click.right="openNewTab(3)"
              @click.middle="openNewTab(3)"
              @contextmenu.prevent
            >Jurnal Penyesuaian
          </b-button>
          <b-button
            :class="{ tabActive: currentTab == 6 }"
            class="d-md-none d-lg-none d-xl-block"
            @click="selectTab(6)"
               @click.right="openNewTab(6)"
              @click.middle="openNewTab(6)"
              @contextmenu.prevent
            >Aset Tetap
          </b-button>
          <b-button
            :class="{ tabActive: currentTab == 4 }"
            class="d-md-none d-lg-none d-xl-block"
            @click="selectTab(4)"
               @click.right="openNewTab(4)"
              @click.middle="openNewTab(4)"
              @contextmenu.prevent
            >Diskusi
          </b-button>
          <b-button
            :class="{ tabActive: currentTab == 5 }"
            class="d-md-none d-lg-none d-xl-block"
            @click="selectTab(5)"
              @click.right="openNewTab(5)"
              @click.middle="openNewTab(5)"
              @contextmenu.prevent
            >Setting
          </b-button>

          <b-dropdown
            id="dropdown-right"
            right
            text="Menu"
            variant="primary"
            class="m-2 d-md-block d-lg-block d-xl-none"
          >
            <b-dropdown-item
              :class="{ tabActive: currentTab == 1 }"
              @click="selectTab(1)"
              >Dashboard</b-dropdown-item
            >
            <b-dropdown-item
              :class="{ tabActive: currentTab == 2 }"
              @click="selectTab(2)"
              >Lembar Kerja</b-dropdown-item
            >
            <b-dropdown-item
              :class="{ tabActive: currentTab == 3 }"
              @click="selectTab(3)"
              >Jurnal Penyesuaian</b-dropdown-item
            >
            <b-dropdown-item
              :class="{ tabActive: currentTab == 6 }"
              @click="selectTab(6)"
              >Aset Tetap</b-dropdown-item
            >
            <b-dropdown-item
              :class="{ tabActive: currentTab == 4 }"
              @click="selectTab(4)"
              >Diskusi</b-dropdown-item
            >
            <b-dropdown-item
              :class="{ tabActive: currentTab == 5 }"
              @click="selectTab(5)"
              >Setting</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
      <b-container fluid style="margin-top: 90px">
        <b-row style="padding: 0 1%">
          <b-col v-if="currentTab == 1" style="margin-top: 4%">
            <b-row>
              <b-col md="12" lg="12" class="d-md-block d-lg-block d-xl-none">
                <div style="width: 100%; height: 50px"></div>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                md="4"
                lg="4"
                xl="2"
                style="border-right: 1px solid #c8d5ec"
              >
                <user_project :currentTab="currentTab" />
              </b-col>
              <b-col
                style="border-right: 1px solid #c8d5ec"
                md="8"
                lg="8"
                xl="4"
              >
                <user_project_mid />
              </b-col>
              <b-col md="12" lg="12" xl="6"> <table_selisih /></b-col>
            </b-row>
          </b-col>

          <b-col v-else-if="currentTab == 6" style="margin-top: 4%">
            <b-row>
              <!-- <b-col cols="2" style="border-right: 1px solid #c8d5ec">
            <user_project :currentTab="currentTab" />
          </b-col> -->
              <b-col style="border-right: 1px solid #c8d5ec" cols="12">
                <aset-tetap />
              </b-col>
            </b-row>
          </b-col>

          <b-col v-else-if="currentTab == 2" style="margin-top: 4%">
            <b-row>
              <b-col md="12" lg="12" xl="12">
                <lembar_kerja3 :statusBanding="statusBanding" />
              </b-col>
            </b-row>
          </b-col>

          <b-col v-else-if="currentTab == 3" style="margin-top: 4%">
            <b-row>
              <b-col
                xl="2"
                style="border-right: 1px solid #c8d5ec"
                class="d-md-none d-lg-none d-xl-block"
              >
                <user_project :currentTab="currentTab" />
              </b-col>
              <b-col md="12" lg="12" xl="10">
                <jurnal_penyesuaian />
              </b-col>
            </b-row>
          </b-col>

          <b-col v-else-if="currentTab == 4" style="margin-top: 4%">
            <b-row>
              <b-col
                xl="2"
                style="border-right: 1px solid #c8d5ec"
                class="d-md-none d-lg-none d-xl-block"
                ><user_project :currentTab="currentTab" />
              </b-col>
              <b-col md="12" lg="12" xl="10">
                <forum_project
                  @changeView="updateView($event)"
                  @idThread="setThread($event)"
                  v-if="currentView == 1"
                />
                <forum_balas
                  @idThread="setThread($event)"
                  @changeView="updateView($event)"
                  v-else
                />
              </b-col>
            </b-row>
          </b-col>

          <b-col v-else style="margin-top: 4%">
            <b-row>
              <b-col>
                <settings />
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-modal centered ref="tidak-aktif" hide-footer title="PERHATIAN!">
          <div style="padding: 5%; font-size: 19px">
            Mohon maaf project silahkan pilih project lain
          </div>
          <center>
            <b-button
              style="background-color: #327ff2; border: none"
              @click="close"
              >Tutup</b-button
            >
          </center>
        </b-modal>
      </b-container>
    </section>
    <section></section>
  </div>
</template>

<script>
import nav_bar from "../../../components/nav_bar.vue";
import { mapState } from "vuex";
import User_project from "../../../components/details/user_project.vue";
import User_project_mid from "../../../components/details/user_project_mid.vue";
import Table_selisih from "../../../components/table/table_selisih.vue";
import lembar_kerja3 from "../../../components/project/lembar_kerja3.vue";
import forum_project from "../../../components/cards/forum_project.vue";
import Forum_balas from "../forum_balas.vue";
import Jurnal_penyesuaian from "../../../components/project/jurnal_penyesuaian.vue";
import settings from "../../../components/project/setting.vue";
import AsetTetap from "../../../components/project/asetTetap.vue";

export default {
  components: {
    nav_bar,
    User_project,
    User_project_mid,
    Table_selisih,
    lembar_kerja3,
    forum_project,
    Forum_balas,
    lembar_kerja3,
    Jurnal_penyesuaian,
    settings,
    AsetTetap,
  },
  data() {
    return {
      namaClient: "",
      namaClient1: "",
      currentTab: 1,
      currentView: 1,
      statusBanding: false,
    };
  },
  // async created() {
  //   await this.$store.dispatch("apiProject/getProject", this.$route.params.id);
  // },
  beforeMount() {
    let vm = this;
    let xx = localStorage.getItem("namaClient");
    if (xx.length > 14) {
      vm.namaClient = xx.substring(0, 14) + "...";
    } else {
      vm.namaClient = xx;
    }
    vm.namaClient1 = xx;
    localStorage.setItem("idProject", this.$route.params.id);
    localStorage.setItem("tahunProject", this.stateProject.tahunProject);

    let select = localStorage.getItem("pages");
    if(select != null){
            let vm = this;
      vm.currentTab = select;
    }
  },
  mounted() {
    this.checkProject();
  },

  computed: {
    ...mapState("apiProject", [
      "stateProject",
      "loadingP",
      "projectLength",
      "role",
      "tidakAktif",
    ]),
  },

  methods: {
    async checkProject() {
      await this.$store.dispatch(
        "apiProject/getProject",
        this.$route.params.id
      );
      if (this.tidakAktif || this.tidakAktif == "Project Tidak Aktif") {
        this.$refs["tidak-aktif"].show();
      }
    },
    close() {
      this.$refs["tidak-aktif"].hide();
      this.$router.push("/main_dashboard");
    },
    selectTab(selected) {
      let vm = this;
      vm.currentTab = selected;
    },
    openNewTab(selected){
      localStorage.setItem("pages", selected);
      window.open("https://online.kapmobile.id/#/project/" + this.$route.params.id, '');
    },
    updateView(value) {
      let vm = this;
      vm.currentView = value;
    },
    setThread(value) {
      localStorage.setItem("idThread", value);
    },
    goBack() {
      this.$router.push("/profile_client/" + localStorage.getItem("idClient"));
    },
    goBack2() {
      this.$router.push("/profile_kap/" + localStorage.getItem("idKAP"));
    },
    goBack3() {
      this.$router.push("/main_dashboard");
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");
$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white": #fafafa,
  "white2": #fff,
  "gray-dark": #b5bac3,
  "blue-light": #e8f3fe,
  "red-light": #ffe8e7,
  "green-light": #eafffa,
  "green": #6adbbe,
);
.bg {
  position: fixed;
  height: 19%;
  width: 100%;
  background-color: map-get($color, "blue");
  // background-image: url("../../../assets/vector/Pattern.svg");
  // background-repeat: repeat;
  // background-blend-mode: soft-light;
  z-index: 2;
}

.top {
  width: 96%;
  height: 65px;
  background-color: map-get($color, "white2");
  box-shadow: 0px 10px 35px -10px rgba(123, 156, 160, 0.1);
  border-radius: 10px;
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  display: flex;
  padding-right: 1%;
  .breadcrumb {
    font-weight: 700;
    font-family: "nunito", sans-serif;
    text-transform: capitalize;
    transform: translateY(16%);
    background-color: transparent;
    color: map-get($color, "blue");
    width: 100%;
  }
  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    background-color: red;
    flex-wrap: wrap;
    .btn {
      font-family: "nunito", sans-serif;
      color: map-get($color, "blue");
      font-weight: 700;
      background-color: map-get($color, "white");
      border: 2px solid #327ff2;
      text-transform: capitalize;
      border-radius: 10px;
      margin-left: 1%;
      height: 40px;
    }
    .tabActive {
      font-family: "nunito", sans-serif;
      color: map-get($color, "white");
      font-weight: 700;
      background-color: map-get($color, "blue");
      border: 2px solid #fff;
      text-transform: capitalize;
      box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
      border-radius: 10px;
      height: 40px;
      border: 2px solid map-get($color, "blue");
    }
  }
}
.breadcrumb {
  font-weight: 700;
  font-family: "nunito", sans-serif;
  text-transform: capitalize;
  transform: translateY(16%);
  background-color: transparent;
  color: map-get($color, "blue");
}
.btn {
  font-family: "nunito", sans-serif;
  color: map-get($color, "blue");
  font-weight: 700;
  background-color: map-get($color, "white");
  border: 2px solid #327ff2;
  text-transform: capitalize;
  border-radius: 10px;
  margin-left: 0.5%;
  height: 40px;
}

.tabActive {
  font-family: "nunito", sans-serif;
  color: map-get($color, "white");
  font-weight: 700;
  background-color: map-get($color, "blue");
  text-transform: capitalize;
  box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
  height: 40px;
  border: 2px solid map-get($color, "blue");
}

.first-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  .top {
    display: flex;
    width: 95%;
    align-items: center;
    background-color: map-get($color, "white2");
    box-shadow: 0px 10px 35px -10px rgba(123, 156, 160, 0.1);
    border-radius: 10px;
    transform: translateY(16%);
    justify-content: space-between;
    position: fixed;
    z-index: 2;
    padding-right: 1%;
    .breadcrumb {
      font-weight: 700;
      font-family: "nunito", sans-serif;
      text-transform: capitalize;
      transform: translateY(16%);
      background-color: transparent;
      color: map-get($color, "blue");
    }
    .button-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .btn {
        font-family: "nunito", sans-serif;
        color: map-get($color, "blue");
        font-weight: 700;
        background-color: map-get($color, "white");
        border: 2px solid #327ff2;
        text-transform: capitalize;
        border-radius: 10px;
        margin-left: 1%;
        height: 40px;
      }
      .tabActive {
        font-family: "nunito", sans-serif;
        color: map-get($color, "white");
        font-weight: 700;
        // background-color: map-get($color, "blue");
        text-transform: capitalize;
        box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
        border-radius: 10px;
        height: 40px;
        border: 2px solid map-get($color, "blue");
      }
    }
  }
}
</style>
