<template>
  <b-modal centered size="md" id="editTodo" title="BootstrapVue">
    <b-form-group label="Nama Todo List:">
      <b-form-input
        v-model="dataProps.namaTitle"
        type="text"
        placeholder="Todo List"
      ></b-form-input>
    </b-form-group>
    <template #modal-footer>
      <b-button
        :disabled="busy"
        variant="secondary"
        @click="$bvModal.hide('editTodo')"
        >Tutup</b-button
      >
      <b-button :disabled="busy" variant="primary" @click="execute"
        >Simpan</b-button
      >
    </template>
  </b-modal>
</template>

<script>
import axios from "@/ip_config";
export default {
  props: {
    dataProps: {
      type: Object,
    },
  },
  data() {
    return {
      busy: false,
    };
  },
  methods: {
    execute() {
      let vm = this;
      vm.busy = true;

      axios
        .post(
          "toDoList/update",
          {
            id: vm.dataProps.idTodoList,
            namaTitle: vm.dataProps.judul,
            projectId: +vm.$route.params.id,
            namaTodoList: vm.dataProps.namaTitle,
          },
          {
            headers: {
              token: localStorage.getItem("token"),
            },
          }
        )
        .then(() => {
          vm.$emit("refresh");
          this.$bvModal.hide("editTodo");
          vm.busy = false;
          vm.$emit("alertnya", { pesan: "Berhasil Mengubah To Do List" });
        })
        .catch((err) => {
          console.log(err);
          vm.busy = false;
        });
    },
  },
};
</script>
