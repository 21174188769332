<template>
  <div class="wrapper">
    <div class="text">
      <div class="title">
        <span
          v-b-tooltip.hover
          :title="stateProject.namaProject"
          v-if="loadingP == false"
          >{{ stateProject.namaProject1 }}</span
        >
        <b-skeleton
          v-else
          class="loading-skeleton"
          animation="wave"
        ></b-skeleton>
      </div>
      <!-- <div class="join">Joined on {{ kap.tanggalkap }}</div> -->
      <div class="peran">peran anda</div>
      <div v-if="roleLocal == 'undefined' || roleLocal == undefined">
        <div class="admin" v-if="role == 'admin'">
          <span>{{ role }}</span>
        </div>
        <div class="mng" v-else-if="role == 'manager'">
          <span>{{ role }}</span>
        </div>
        <div class="spv" v-else-if="role == 'supervisor'">
          <span>{{ role }}</span>
        </div>
        <div class="auditor" v-else-if="role == 'auditor'">
          <span>{{ role }}</span>
        </div>
      </div>
      <div v-else>
        <div class="admin" v-if="roleLocal == 'admin'">
          <span>{{ roleLocal }}</span>
        </div>
        <div class="mng" v-else-if="roleLocal == 'manager'">
          <span>{{ roleLocal }}</span>
        </div>
        <div class="spv" v-else-if="roleLocal == 'supervisor'">
          <span>{{ roleLocal }}</span>
        </div>
        <div class="auditor" v-else-if="roleLocal == 'auditor'">
          <span>{{ roleLocal }}</span>
        </div>
      </div>
      <!-- <div class="jumlah">jumlah client</div>
      <div class="total">
        <span>{{ client }} client</span>
      </div>
      <div class="jumlah">jumlah akun</div>
      <div class="total">
        <span>{{ user }} akun</span>
      </div> -->
      <div class="buttons">
        <b-button pill @click="openLog">Lihat Log</b-button>
      </div>
    </div>

    <div class="button-wrapper">
      <b-button @click="openModal" style="background: #327ff2">
        <img src="../../assets/icon/icon/mail.svg" alt="" />
        <span>undang akun</span></b-button
      >
      <b-button v-b-modal.auditor style="background: #e0544e">Auditor</b-button>
    </div>

    <div class="alerts">
      <div>
        <b-alert
          dismissible
          style="width: 100%"
          variant="danger"
          fade
          :show="alert"
        >
          {{ pesan }}
        </b-alert>
        <b-alert
          dismissible
          style="width: 100%"
          variant="success"
          fade
          :show="alert2"
        >
          {{ pesan }}
        </b-alert>
      </div>
    </div>

    <b-modal hide-footer centered hide-header ref="modal-invite">
      <div class="tutup" style="display: flex; justify-content: flex-end">
        <b-button
          @click="tutupModal1"
          style="background-color: white; border: none"
          ><img
            src="../../assets/icon/close.svg"
            alt=""
            style="width: 15px; height: 15px"
        /></b-button>
      </div>
      <div
        style="
          text-align: center;
          font-size: 22px;
          font-weight: 700;
          font-family: 'nunito', sans-serif;
        "
      >
        Undang Akun
      </div>
      <div style="margin-bottom: 6%">
        <label class="typo__label">Masukan Email</label>
        <b-form-input
          id="input-1"
          v-model="email"
          type="email"
          placeholder="Enter email"
          required
        ></b-form-input>
        <!-- <multiselect
          v-model="value"
          :options="options"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Pilih akun"
          label="nama"
          track-by="nama"
          :hideSelected="true"
          :preselect-first="false"
          style="font-family: 'nunito', sans-serif"
        >
          <template slot="selection" slot-scope="{ values, search, isOpen }"
            ><span
              class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen"
              >{{ values.length }} options selected</span
            ></template
          >
        </multiselect> -->
      </div>

      <span class="peran" style="">Peran</span>

      <b-form-radio-group label="Peran" v-slot="{ ariaDescribedby }">
        <b-form-radio
          v-model="jabatan"
          :aria-describedby="ariaDescribedby"
          name="some-radios"
          value="auditor"
          >Auditor</b-form-radio
        >
      </b-form-radio-group>

      <b-button
        block
        @click="doInvite"
        v-if="loading3 == false && jabatan != ''"
        style="
          margin-top: 4%;
          background-color: #327ff2;
          font-family: 'nunito', sans-serif;
          font-weight: 700;
          border: none;
        "
        >Undang</b-button
      >
      <b-button
        block
        v-else-if="loading3 == true"
        disabled
        style="
          margin-top: 4%;
          background-color: #327ff2;
          font-family: 'nunito', sans-serif;
          font-weight: 700;
          border: none;
        "
        ><white-spinner
      /></b-button>
      <b-button
        block
        disabled
        v-else
        style="
          margin-top: 4%;
          background-color: #327ff2;
          font-family: 'nunito', sans-serif;
          font-weight: 700;
          border: none;
        "
        >Undang</b-button
      >
    </b-modal>
    <b-modal hide-footer hide-header centered ref="modal-log" size="lg">
      <div class="tutup" style="display: flex; justify-content: flex-end">
        <b-button
          @click="tutupModal1"
          style="background-color: white; border: none"
          ><img
            src="../../assets/icon/close.svg"
            alt=""
            style="width: 15px; height: 15px"
        /></b-button>
      </div>
      <div
        style="
          text-align: center;
          font-weight: 700;
          font-family: 'nunito', sans-serif;
          font-size: 22px;
        "
      >
        Logging
      </div>

      <div v-if="loadingModal == false">
        <div
          class="logs"
          style="
            display: flex;
            align-items: center;
            font-family: 'Nunito', sans-serif;
            text-transform: capitalize;
            padding: 1% 0;
            border-bottom: 1px solid #b5bac3;
          "
          v-for="(log, mcr) in logs"
          :key="mcr"
        >
          <div class="nomor" style="margin-right: 2%">
            <span>{{ mcr + 1 }}.</span>
          </div>

          <div
            class="history"
            style="
              height: 80px;
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
            "
          >
            <span style="font-weight: 600"
              >Aksi : {{ log.history }}
              <span
                style="text-transform: capitalize"
                v-if="log.namaProject != null"
                >- {{ log.namaProject }}</span
              ></span
            >

            <span>
              oleh :
              <span
                style="
                  text-transform: lowercase;
                  color: #327ff2;
                  font-weight: 600;
                  text-decoration: underline;
                "
              >
                {{ log.pengguna }}
              </span>
            </span>

            <span>tanggal : {{ log.createdAt }} WIB</span>
          </div>
        </div>
      </div>
      <div
        v-else
        style="
          height: 350px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
        "
      >
        <div v-for="sk in 8" :key="sk">
          <b-skeleton animation="throb"></b-skeleton>
        </div>
      </div>
    </b-modal>

    <b-modal centered hide-footer id="auditor">
      <TableAuditor />
    </b-modal>
  </div>
</template>

<script>
import axios from "@/ip_config.js";
import whiteSpinner from "../spinner/white-spinner.vue";
import BlueSpinner from "../spinner/blue-spinner.vue";
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";
import moment from "moment";
import TableAuditor from "../table/table_auditor.vue";
moment.locale("id");

export default {
  components: { whiteSpinner, BlueSpinner, Multiselect, TableAuditor },
  props: ["currentTab"],

  data() {
    return {
      user: "",
      email: "",
      client: "",
      value: [],
      logs: [],
      loadingModal: false,
      loading3: false,
      options: [],
      jabatan: "",
      roleLocal: "",
      pesan: "",
      alert: false,
      alert2: false,
    };
  },

  mounted() {
    let vm = this;
    vm.roleLocal = localStorage.getItem("role");
  },

  computed: {
    ...mapState("apiProject", [
      "stateProject",
      "projectLength",
      "loadingP",
      "role",
    ]),
  },

  methods: {
    openModal() {
      this.$refs["modal-invite"].show();
      // let vm = this;
      // vm.loading4 = true;
      // let x = localStorage.getItem("token");

      // axios
      //   .get(
      //     ip_server + "users/usersNotInProject/" + this.stateProject.projectid,
      //     {
      //       headers: {
      //         token: x,
      //       },
      //     }
      //   )
      //   .then((res) => {
      //     vm.options = res.data;

      //     vm.options.forEach((el, id) => {
      //       if (vm.options[id].role == "superuser") {
      //         let x = vm.options.indexOf(vm.options[id]);
      //         if (x > -1) {
      //           vm.options.splice(x, 1);
      //         }
      //       }
      //     });
      //   });
    },
    tutupModal1() {
      this.$refs["modal-invite"].hide();
      this.$refs["modal-log"].hide();
    },

    async openLog() {
      let vm = this;
      vm.loadingModal = true;
      let fetch = await axios.get(
        "log/listByProject/" + this.$route.params.id,
        {
          headers: {
            token: localStorage.getItem("token"),
          },
        }
      );
      fetch.data.forEach((el) => {
        el.createdAt = moment(el.createdAt).format("LLL");
      });
      vm.logs = fetch.data;
      this.$refs["modal-log"].show();
      setTimeout(() => {
        vm.loadingModal = false;
      }, 800);
    },

    doInvite() {
      let vm = this;
      // vm.value.forEach((el) => {
      //   vm.id = el.id;
      // });

      vm.loading3 = true;

      axios
        .post(
          "poolProject/invite",
          {
            email: vm.email,
            KAPId: Number(localStorage.getItem("idKAP")),
            clientId: Number(localStorage.getItem("idClient")),
            jabatan: vm.jabatan,
            projectId: vm.stateProject.projectid,
          },
          {
            headers: {
              token: localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.data.message == "tidak punya authorization") {
            vm.pesan = "Anda tidak memiliki akses";
            vm.alert = true;
            setTimeout(() => {
              vm.alert = false;
            }, 3000);
          } else if (
            res.data.message ==
            "user telah terdaftar atau sedang menunggu konfirmasi"
          ) {
            vm.pesan = "Menunggu konformasi atau telah diundang";
            vm.alert = true;
            setTimeout(() => {
              vm.alert = false;
            }, 3000);
          } else if (res.data.message == "email tidak terdaftar") {
            console.log(res.data.message);
            vm.pesan = "Email Tidak Terdaftar";
            vm.alert = true;
            setTimeout(() => {
              vm.alert = false;
            }, 3000);
          } else {
            vm.pesan = "Undangan berhasil dikirim";
            vm.alert2 = true;
            setTimeout(() => {
              vm.alert2 = false;
            }, 3000);
          }
          vm.id = [];
          vm.email = "";
          vm.jabatan = [];
          this.$refs["modal-invite"].hide();
          vm.loading3 = false;
        });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");

$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white": #fafafa,
  "white2": #fff,
  "gray-dark": #b5bac3,
  "blue-light": #e8f3fe,
  "red-light": #ffe8e7,
  "green-light": #eafffa,
  "green": #6adbbe,
);

.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  .text {
    height: 500px;
    .title {
      font-weight: 700;
      color: map-get($color, "black");
      font-size: 28px;
      font-family: "nunito", sans-serif;
      text-transform: capitalize;
      .loading-skeleton {
        height: 28px;
      }
    }
    .join {
      font-weight: 300;
      color: map-get($color, "gray-dark");
      font-family: "nunito", sans-serif;

      font-size: 16px;
      margin-top: 8%;
    }
    .peran {
      font-weight: 300;
      color: map-get($color, "black");
      font-family: "nunito", sans-serif;

      font-size: 16px;
      margin-top: 8%;
      text-transform: capitalize;
    }
    .admin {
      margin-top: 2%;
      span {
        background-color: #eafffa;
        font-family: "nunito", sans-serif;
        border-radius: 50px;
        color: #20bd95;
        text-transform: capitalize;
        border: 2px solid #20bd95;
        padding: 0.5% 5%;
        font-weight: 600;
      }
    }
    .spv {
      margin-top: 2%;
      span {
        background-color: #ffefdb;
        font-family: "nunito", sans-serif;
        padding: 0.5% 5%;
        font-weight: 600;
        border-radius: 50px;
        border: 2px solid #ffac4b;
        color: #ffac4b;
        text-transform: capitalize;
      }
    }
    .mng {
      margin-top: 2%;
      span {
        background-color: #f4f3ff;
        font-family: "nunito", sans-serif;
        padding: 0.5% 5%;
        font-weight: 600;
        border-radius: 50px;
        border: 2px solid #5c4af5;
        color: #5c4af5;
        text-transform: capitalize;
      }
    }
    .auditor {
      margin-top: 2%;
      span {
        background-color: #ffe8e7;
        font-family: "nunito", sans-serif;
        padding: 0.5% 5%;
        font-weight: 600;
        border-radius: 50px;
        border: 2px solid #e0544e;
        color: #e0544e;
        text-transform: capitalize;
      }
    }
    .jumlah {
      margin-top: 8%;
      font-family: "nunito", sans-serif;

      text-transform: capitalize;
    }
    .total {
      margin-top: 4%;
      span {
        background-color: rgba(238, 238, 238, 0.5);
        font-weight: 700;
        font-family: "nunito", sans-serif;

        text-transform: capitalize;
        color: map-get($color, "black");
        padding: 2% 6%;
        border-radius: 50px;
      }
    }
  }

  .button-wrapper {
    width: 100%;
    // margin-top: 85%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .btn {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      font-family: "nunito", sans-serif;
      margin-bottom: 4%;
      height: 57px;
      border: none !important;
      font-family: "nunito", sans-serif;
      font-weight: 700;
      text-transform: capitalize;
    }
  }
  .multiselect__input {
    font-family: "nunito", sans-serif;
  }
  .alerts {
    width: 50%;
    z-index: 3;
    position: fixed;
    right: 0;
    left: 0;
    margin: auto;
    bottom: 0;
    padding-bottom: 3%;
  }
  .buttons {
    margin-top: 8%;
    .btn {
      font-weight: 500;
      background-color: map-get($color, "blue");
      border: none;
      font-size: 14px;
    }
  }
}
</style>
