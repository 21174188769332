<template>
  <div class="wrapper">
    <div class="nama">
      <span>profile KAP</span>
      <div class="button-wrapper2">
        <b-button @click="editKAP">Edit</b-button>
      </div>
    </div>

    <div class="details">
      <div class="rows">
        <span class="title">Akuntan Public</span>
        <span class="isi">{{ stateProject.namaAkuntanPublik }} </span>
      </div>

      <div class="rows">
        <span class="title">Reviewer</span>
        <span class="isi" v-if="stateProject.namaReviewer != null">{{
          stateProject.namaReviewer
        }}</span>
        <span class="isi" v-else>-</span>
      </div>

      <div class="rows">
        <span class="title">Penyusun</span>
        <span
          class="isi"
          v-if="
            stateProject.namaPenyusun == null || stateProject.namaPenyusun == ''
          "
          >-</span
        >
        <span class="isi" v-else>{{ stateProject.namaPenyusun }}</span>
      </div>

      <div class="rows">
        <span class="title">Auditor</span>
        <span
          class="isi"
          v-if="
            stateProject.namaAuditor == null || stateProject.namaAuditor == ''
          "
          >-</span
        >
        <span class="isi" v-else>{{ stateProject.namaAuditor }}</span>
      </div>
    </div>
    <div class="nama">
      <span>Contribution</span>
    </div>

    <div class="details">
      <div class="rows">
        <span class="title">Tick Mark</span>
        <span class="isi">{{ kontri.tickmark }}</span>
      </div>

      <div class="rows">
        <span class="title">Adjustment</span>
        <span class="isi">{{ kontri.jurnal }}</span>
      </div>

      <div class="rows">
        <span class="title">TS</span>
        <span class="isi">{{ kontri.TS }}</span>
      </div>

      <div class="rows">
        <span class="title">Comment</span>
        <span class="isi">{{ kontri.comment }}</span>
      </div>
    </div>

    <b-alert fade dismissible variant="success" :show="show" style=""
      >Berhasil Mengubah Data</b-alert
    >

    <b-modal hide-footer hide-header centered ref="modal-edit" size="lg">
      <div class="tutup" style="display: flex; justify-content: flex-end">
        <b-button
          @click="tutupModal1"
          style="background-color: white; border: none"
          ><img
            src="../../assets/icon/close.svg"
            alt=""
            style="width: 15px; height: 15px"
        /></b-button>
      </div>
      <div
        class="content"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 1% 2%;
        "
      >
        <span
          class="tittle"
          style="
            font-family: 'nunito', sans-serif;
            font-size: 28px;
            font-weight: 700;
            text-transform: capitalize;
          "
        >
          Edit {{ stateProject.namaKAP }}
        </span>
        <div class="form" style="width: 95%; margin-top: 4%">
          <b-form-group
            label="Akuntan Publik"
            label-for="input-formatter"
            class="mb-0"
            style="
              font-family: 'nunito', sans-serif;
              font-size: 20px;
              font-weight: 300;
            "
          >
            <b-form-input
              id="input-formatter"
              placeholder="Akuntan Publik"
              style="border-radius: 10px"
              size="lg"
              type="text"
              v-model="namaAkuntanPublik"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Reviewer"
            label-for="input-formatter"
            class="mb-0"
            style="
              font-family: 'nunito', sans-serif;
              font-size: 20px;
              font-weight: 300;
            "
          >
            <b-form-input
              id="input-formatter"
              placeholder="Reviewer"
              style="border-radius: 10px"
              size="lg"
              type="text"
              v-model="namaReviewer"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Ketua Tim"
            label-for="input-formatter"
            class="mb-0"
            style="
              font-family: 'nunito', sans-serif;
              font-size: 20px;
              font-weight: 300;
            "
          >
            <b-form-input
              id="input-formatter"
              placeholder="Ketua Tim"
              style="border-radius: 10px"
              size="lg"
              v-model="namaPenyusun"
              type="text"
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            label="Auditor"
            label-for="input-formatter"
            class="mb-0"
            style="
              font-family: 'nunito', sans-serif;
              font-size: 20px;
              font-weight: 300;
            "
          >
            <b-form-input
              id="input-formatter"
              placeholder="Auditor"
              style="border-radius: 10px"
              size="lg"
              type="text"
              v-model="namaAuditor"
            >
            </b-form-input>
          </b-form-group>
        </div>
      </div>
      <div
        class="wrapper"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 0 2%;
        "
      >
        <div
          class="button-wrapper"
          style="display: flex; width: 95%; justify-content: flex-end"
        >
          <b-button
            @click="doEdit"
            style="
              background-color: #327ff2;
              color: #fff;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              width: 25%;
              border: none;
              margin-top: 20px;
              height: 40px;
            "
            >Simpan</b-button
          >
          <!-- <b-button
            disabled
            v-else
            style="
              background-color: #327ff2;
              color: #fff;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              width: 25%;
              border: none;
              margin-top: 20px;
              height: 40px;
            "
            ><white-spinner
          /></b-button> -->
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "@/ip_config.js";
import WhiteSpinner from "../spinner/white-spinner.vue";
export default {
  components: { WhiteSpinner },
  data() {
    return {
      namaKAP: "",
      kap: [],
      users: [],
      auditor: "",
      kontri: {
        tickmark: "",
        jurnal: "",
        TS: "",
        comment: "",
      },
      show: false,
      namaAkuntanPublik: "",
      namaAuditor: "",
      namaPenyusun: "",
      namaReviewer: "",
    };
  },

  mounted() {
    this.contrib();
  },

  computed: {
    ...mapState("apiProject", ["stateProject"]),
    ...mapState("apiUser", ["stateId"]),
  },

  methods: {
    tutupModal1() {
      this.$refs["modal-edit"].hide();
    },

    async contrib() {
      let vm = this;

      let x = localStorage.getItem("idKAP");
      let fetch = await axios.get(
        "log/kontribusi/" + x + "/" + vm.$route.params.id,
        {
          headers: {
            token: localStorage.getItem("token"),
          },
        }
      );

      vm.kontri.tickmark = fetch.data.tickmark[0]["count"];
      vm.kontri.jurnal = fetch.data.adjustment[0]["count"];
      vm.kontri.TS = fetch.data.thread[0]["count"];
      vm.kontri.comment = fetch.data.comment[0]["count"];
    },

    editKAP() {
      this.namaAkuntanPublik = this.stateProject.namaAkuntanPublik;
      this.namaAuditor = this.stateProject.namaAuditor;
      this.namaReviewer = this.stateProject.namaReviewer;
      this.namaPenyusun = this.stateProject.namaPenyusun;
      this.$refs["modal-edit"].show();
    },
    // getData() {
    //   let vm = this;
    //   let z = localStorage.getItem("token");
    //   axios
    //     .get(ip_server + "project/listById/" + vm.$route.params.id, {
    //       headers: {
    //         token: localStorage.getItem("token"),
    //       },
    //     })
    //     .then((res) => {
    //       vm.kap = res.data.data[0];
    //     });
    // },

    async doEdit() {
      let vm = this;
      let y = localStorage.getItem("idProject");
      let send = await axios.post(
        "project/update/" + vm.$route.params.id,
        {
          namaProject: vm.stateProject.namaProject,
          tahunProject: vm.stateProject.tahunProject,
          namaAkuntanPublik: vm.namaAkuntanPublik,
          namaAuditor: vm.namaAuditor,
          namaPenyusun: vm.namaPenyusun,
          namaReviewer: vm.namaReviewer,
        },
        {
          headers: {
            token: localStorage.getItem("token"),
          },
        }
      );
      if (send.data.message == "sukses") {
        vm.$refs["modal-edit"].hide();
        vm.show = true;

        setTimeout(() => {
          vm.show = false;
        }, 3000);

        this.$store.dispatch("apiProject/getProject", y);
      }
    },

    // async findAudit() {
    //   let vm = this;
    //   let z = localStorage.getItem("token");
    //   let y = localStorage.getItem("idProject");
    //   await axios
    //     .post(
    //       ip_server + "project/usersByProject",
    //       {
    //         id: y,
    //       },
    //       {
    //         headers: {
    //           token: z,
    //         },
    //       }
    //     )
    //     .then((res) => {
    //       // console.log(res);
    //     });
    // },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");

$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white": #fafafa,
  "white2": #fff,
  "gray-dark": #b5bac3,
  "blue-light": #e8f3fe,
  "red-light": #ffe8e7,
  "green-light": #eafffa,
  "green": #6adbbe,
);

.wrapper {
  .nama {
    font-size: 28px;
    font-family: "nunito", sans-serif;
    font-weight: 300;
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .button-wrapper2 {
      .btn {
        font-size: 14px;
        background-color: map-get($color, "white2");
        border: 1.5px solid map-get($color, "blue");
        color: map-get($color, "blue");
        font-family: "nunito", sans-serif;
        font-weight: 700;
        height: 28px;
        display: flex;
        align-items: center;
        box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
      }
      .btn:hover {
        font-size: 14px;
        background-color: map-get($color, "blue");
        border: 1.5px solid map-get($color, "blue");
        color: map-get($color, "white");
        font-family: "nunito", sans-serif;
        font-weight: 700;
        height: 28px;
        display: flex;
        align-items: center;
        box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
      }
    }
  }

  .details {
    margin-bottom: 30px;
    .rows {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 1% 0;
      .title {
        font-family: "nunito", sans-serif;
        font-weight: 300;
        color: map-get($color, "gray-dark");
        text-transform: capitalize;
        width: 50%;
      }
      .isi {
        font-family: "nunito", sans-serif;
        font-weight: 300;
        color: map-get($color, "black");
        text-transform: capitalize;
        width: 50%;
      }
    }
  }

  .alert {
    position: fixed;
    width: 40%;
    display: flex;
    justify-content: center;
    transform: translateX(30%);
    margin: auto;
  }
}
</style>
