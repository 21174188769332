<template>
  <div class="wrapper">
    <div class="front">
      <div class="title">
        <div>
          <span>Cek Selisih</span>
          <!-- <img
            style="margin-left: 2%"
            src="../../assets/icon/red-icon.svg"
            alt=""
          /> -->
        </div>
        <div style="width: 80%; display: flex; justify-content: flex-end">
          <multiselect
            v-model="selected"
            :options="pilihan"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="false"
            placeholder="Pilih Pembanding"
            track-by="value"
            label="text"
            :hideSelected="false"
            :preselect-first="false"
            style="font-family: 'nunito', sans-serif; width: 15vw"
            @input="gantiPembanding"
          >
          </multiselect>
        </div>
      </div>
    </div>
    <div class="table">
      <b-overlay :show="loading == true" rounded="sm">
        <b-table
          hover
          :filter="filter"
          :items="items"
          show-empty
          striped
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          style="text-align: center; font-family: 'nunito', sans-serif"
        >
          <!-- <template #cell(namaClient)="data"
            ><a @click="goClient(data.item.clientid)">{{ data.value }}</a>
          </template>
          <template #cell(aksi)="data">
            <b-button
              pill
              @click="goClient(data.item.clientid)"
              style="
                background-color: #327ff2;
                font-weight: 700;
                border: none;
                font-family: 'nunito';
              "
            >
              <img src="../../assets/icon/info.svg" alt="" />
              info
            </b-button>
            <b-button
              pill
              @click="editClient(data.item.clientid)"
              style="
                background-color: #f4b366;
                border: none;
                font-family: 'nunito';
                font-weight: 700;
                margin: 2%;
              "
            >
              <img src="../../assets/icon/edit-2.svg" alt="" />
              edit</b-button
            >
            <b-button
              pill
              @click="doDelete(data.items.clientid)"
              style="
                background-color: #e0544e;
                border: none;
                font-family: 'nunito';
                font-weight: 700;
              "
            >
              <img src="../../assets/icon/trash.svg" alt="" />
              hapus</b-button
            >
          </template> -->
          <template #cell(selisih)="props">
            <span style="color: red" v-if="props.item.status == 'red'"
              >{{ props.item.selisih }}▼</span
            >
            <span style="color: #e100ff" v-else-if="props.item.status == 'pink'"
              >{{ props.item.selisih }}▼</span
            >
            <span
              style="color: #f4b366"
              v-else-if="props.item.status == 'yellow'"
            >
              =</span
            >
            <span style="color: #f4b366" v-else-if="props.item.status == 'nan'">
              =</span
            >
            <span
              style="color: #6adbbe"
              v-else-if="props.item.status == 'tosca'"
              >{{ props.item.selisih }}▲</span
            >
            <span style="color: green" v-else-if="props.item.status == 'green'"
              >{{ props.item.selisih }}▲</span
            >
          </template>
        </b-table>
      </b-overlay>
    </div>

    <!-- <div class="paginate" v-if="items.length > 0"> -->
    <!-- <b-pagination
      v-model="currentPage"
      :total-rows="items.length"
      style="width: 50%; font-family: 'nunito', sans-serif"
      :per-page="perPage"
      align="fill"
      size="sm"
    ></b-pagination> -->
  </div>
</template>

<script>
import axios from "@/ip_config.js";
import accounting from "accounting-js";
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect,
  },
  data() {
    let balance = {
      nomer: "",
      keterangan: "Cek Balance",
      selisih: 0,
      nominal: 0,
      pembanding: 0,
    };
    let asetLancar = {
      nomer: "",
      keterangan: "Total Aset Lancar",
      selisih: 0,
      nominal: 0,
      pembanding: 0,
    };
    let asetLainnya = {
      nomer: "",
      keterangan: "Total Aset Lainnya",
      selisih: 0,
      nominal: 0,
      pembanding: 0,
    };
    let kewajibanPendek = {
      nomer: "",
      keterangan: "Total Kewajiban Pendek",
      selisih: 0,
      nominal: 0,
      pembanding: 0,
    };
    let kewajibanPanjang = {
      nomer: "",
      keterangan: "Total Kewajiban Panjang",
      selisih: 0,
      nominal: 0,
      pembanding: 0,
    };
    let ekuitas = {
      nomer: "",
      keterangan: "Total Ekuitas",
      selisih: 0,
      nominal: 0,
      pembanding: 0,
    };
    let omset = {
      nomer: "",
      keterangan: "Total Omset",
      selisih: 0,
      nominal: 0,
      pembanding: 0,
    };
    let labaKotor = {
      nomer: "",
      keterangan: "Total Laba Kotor",
      selisih: 0,
      nominal: 0,
      pembanding: 0,
    };
    let labaBersih = {
      nomer: "",
      keterangan: "Total Laba Bersih",
      selisih: 0,
      nominal: 0,
      pembanding: 0,
    };
    return {
      currentPage: 1,
      loading: false,
      items: [],
      perPage: 10,
      filter: null,
      balance,
      asetLancar,
      asetLainnya,
      kewajibanPendek,
      kewajibanPanjang,
      ekuitas,
      omset,
      labaKotor,
      labaBersih,
      nomer: 0,
      otherProject: [],
      selected: [],
      pilihan: [],
      selecttahun: [],
      rows: [],
      idPembanding: 0,
      idProject: "",
      fields: [
        {
          key: "nomer",
          label: "#",
        },
        {
          key: "keterangan",
          label: "Keterangan",
        },
        {
          key: "selisih",
          label: "Selisih",
        },
        {
          key: "nominal",
          label: "Nominal",
        },
        {
          key: "pembanding",
          label: "Pembanding",
        },
      ],
    };
  },
  mounted() {
    let checkOther = JSON.parse(localStorage.getItem("pembandingTSelisih"));
    if (checkOther === null) {
      this.getAkuns();
      this.getOtherProject();
    } else if (this.$route.params.id === checkOther.project) {
      this.getAkuns(checkOther.value);
      this.getOtherProject();
      this.selected = checkOther;
    } else if (this.$route.params.id != checkOther.project) {
      localStorage.removeItem("pembandingTSelisih");
      this.getAkuns();
      this.getOtherProject();
    }
  },

  computed: {
    ...mapState("apiProject", ["stateProject"]),
  },

  methods: {
    getAkuns(id) {
      let vm = this;
      vm.loading = true;
      if (id) {
        axios
          .get("akun/sublifikasi/report/" + vm.$route.params.id + "/" + id, {
            headers: { token: localStorage.getItem("token") },
          })
          .then((res) => {
            vm.rows = res.data.hasil;
            vm.loading = false;
            vm.fields[3].label = vm.stateProject.tahunProject;
            vm.fields[4].label = vm.selected.text;
            vm.loading = false;
            vm.items = [];
            vm.table();
          });
      } else {
        axios
          .get("akun/sublifikasi/report/" + vm.$route.params.id + "/" + 0, {
            headers: { token: localStorage.getItem("token") },
          })
          .then((res) => {
            vm.rows = res.data.hasil;
            vm.loading = false;
            vm.fields[3].label = vm.stateProject.tahunProject;
            vm.fields[4].label = "Pembanding";
            vm.loading = false;
            vm.items = [];
            vm.table();
          });
      }
    },

    async getOtherProject() {
      let vm = this;
      vm.loading = true;
      let getProject = await axios.get(
        "client/listById/" + localStorage.getItem("idClient"),
        {
          headers: { token: localStorage.getItem("token") },
        }
      );
      let arr = getProject.data.data;

      for (let iterate in arr) {
        if (arr[iterate].projectid != vm.stateProject.id) {
          vm.pilihan.push({
            text: arr[iterate].tahunProject,
            value: arr[iterate].projectid,
            project: vm.$route.params.id,
          });
        }
      }
      vm.loading = false;
    },

    gantiPembanding() {
      let vm = this;
      vm.loading = true;
      let getBefore = localStorage.getItem("pembandingTSelisih");
      if (getBefore === null && vm.selected != null) {
        localStorage.setItem("pembandingTSelisih", JSON.stringify(vm.selected));
        vm.getAkuns(vm.selected.value);
        vm.fields[4].label = vm.selected.text;
      } else if (vm.selected != null) {
        vm.getAkuns(vm.selected.value);
        vm.fields[4].label = vm.selected.text;
      }
      if (vm.selected === null) {
        vm.getAkuns();
        vm.fields[4].label = "Pembanding";
      }
    },

    table() {
      let vm = this;
      let kode1 = 0;
      let kode2 = 0;
      let kode3 = 0;
      let kode4 = 0;
      let kode5 = 0;
      let kode6 = 0;
      let kode7 = 0;
      let kode8 = 0;
      let kode9 = 0;
      let kode10 = 0;
      let kode11 = 0;
      let kode12 = 0;
      let kode13 = 0;
      let kode14 = 0;
      let kode15 = 0;
      let kode1x = 0;
      let kode2x = 0;
      let kode3x = 0;
      let kode4x = 0;
      let kode5x = 0;
      let kode6x = 0;
      let kode7x = 0;
      let kode8x = 0;
      let kode9x = 0;
      let kode10x = 0;
      let kode11x = 0;
      let kode12x = 0;
      let kode13x = 0;
      let kode14x = 0;
      let kode15x = 0;

      vm.rows.forEach((el) => {
        if (el.kategori == 1 && el.total_akhir) {
          kode1 = el.total_akhir;
        } else if (el.kategori == 2 && el.total_akhir) {
          kode2 = el.total_akhir;
        } else if (el.kategori == 3 && el.total_akhir) {
          kode3 = el.total_akhir;
        } else if (el.kategori == 4 && el.total_akhir) {
          kode4 = el.total_akhir;
        } else if (el.kategori == 5 && el.total_akhir) {
          kode5 = el.total_akhir;
        } else if (el.kategori == 6 && el.total_akhir) {
          kode6 = el.total_akhir;
        } else if (el.kategori == 7 && el.total_akhir) {
          kode7 = el.total_akhir;
        } else if (el.kategori == 8 && el.total_akhir) {
          kode8 = el.total_akhir;
        } else if (el.kategori == 9 && el.total_akhir) {
          kode9 = el.total_akhir;
        } else if (el.kategori == 10 && el.total_akhir) {
          kode10 = el.total_akhir;
        } else if (el.kategori == 11 && el.total_akhir) {
          kode11 = el.total_akhir;
        } else if (el.kategori == 12 && el.total_akhir) {
          kode12 = el.total_akhir;
        } else if (el.kategori == 13 && el.total_akhir) {
          kode13 = el.total_akhir;
        } else if (el.kategori == 14 && el.total_akhir) {
          kode14 = el.total_akhir;
        }
      });
      vm.rows.forEach((el) => {
        if (el.kategori == 1 && el.pembanding) {
          kode1x = el.pembanding;
        } else if (el.kategori == 2 && el.pembanding) {
          kode2x = el.pembanding;
        } else if (el.kategori == 3 && el.pembanding) {
          kode3x = el.pembanding;
        } else if (el.kategori == 4 && el.pembanding) {
          kode4x = el.pembanding;
        } else if (el.kategori == 5 && el.pembanding) {
          kode5x = el.pembanding;
        } else if (el.kategori == 6 && el.pembanding) {
          kode6x = el.pembanding;
        } else if (el.kategori == 7 && el.pembanding) {
          kode7x = el.pembanding;
        } else if (el.kategori == 8 && el.pembanding) {
          kode8x = el.pembanding;
        } else if (el.kategori == 9 && el.pembanding) {
          kode9x = el.pembanding;
        } else if (el.kategori == 10 && el.pembanding) {
          kode10x = el.pembanding;
        } else if (el.kategori == 11 && el.pembanding) {
          kode11x = el.pembanding;
        } else if (el.kategori == 12 && el.pembanding) {
          kode12x = el.pembanding;
        } else if (el.kategori == 13 && el.pembanding) {
          kode13x = el.pembanding;
        } else if (el.kategori == 14 && el.pembanding) {
          kode14x = el.pembanding;
        }
      });

      vm.balance.nominal =
        kode1 + kode2 + kode3 + kode4 + kode5 - (kode6 + kode7 + kode8);
      vm.balance.pembanding =
        kode1x + kode2x + kode3x + kode4x + kode5x - (kode6x + kode7x + kode8x);
      let ba = vm.balance.nominal - vm.balance.pembanding;
      let be = ba / vm.balance.nominal;
      let bu = be * 100;
      let bo = Number(bu.toFixed(2));
      if (bo < -50) {
        vm.balance.status = "red";
      } else if (bo < -0 && bo >= -50) {
        vm.balance.status = "pink";
      } else if (bo == 0) {
        vm.balance.status = "yellow";
      } else if (bo > 0 && bo <= 50) {
        vm.balance.status = "tosca";
      } else if (bo > 50) {
        vm.balance.status = "green";
      }
      vm.balance.selisih = bu.toFixed(0) + "%";
      vm.balance.nominal = accounting.formatMoney(vm.balance.nominal, {
        symbol: "",
        precicsion: 2,
        thousand: ".",
        decimal: ",",
        format: {
          pos: "%v",
          neg: "(%v)",
          zero: "-",
        },
      });
      vm.balance.pembanding = accounting.formatMoney(vm.balance.pembanding, {
        symbol: "",
        precicsion: 2,
        thousand: ".",
        decimal: ",",
        format: {
          pos: "%v",
          neg: "(%v)",
          zero: "-",
        },
      });

      vm.asetLancar.nominal = kode1;
      vm.asetLancar.pembanding = kode1x;
      let ca = vm.asetLancar.nominal - vm.asetLancar.pembanding;
      let ce = ca / vm.asetLancar.nominal;
      let cu = ce * 100;
      let co = Number(cu.toFixed(2));
      if (co < -50) {
        vm.asetLancar.status = "red";
      } else if (co < -0 && co >= -50) {
        vm.asetLancar.status = "pink";
      } else if (co == 0) {
        vm.asetLancar.status = "yellow";
      } else if (co > 0 && co <= 50) {
        vm.asetLancar.status = "tosca";
      } else if (co > 50) {
        vm.asetLancar.status = "green";
      }
      vm.asetLancar.selisih = cu.toFixed(0) + "%";
      vm.asetLancar.nominal = accounting.formatMoney(vm.asetLancar.nominal, {
        symbol: "",
        precicsion: 2,
        thousand: ".",
        decimal: ",",
        format: {
          pos: "%v",
          neg: "(%v)",
          zero: "-",
        },
      });
      vm.asetLancar.pembanding = accounting.formatMoney(
        vm.asetLancar.pembanding,
        {
          symbol: "",
          precicsion: 2,
          thousand: ".",
          decimal: ",",
          format: {
            pos: "%v",
            neg: "(%v)",
            zero: "-",
          },
        }
      );

      vm.asetLainnya.nominal = kode2 + kode3 + kode4 + kode5;
      vm.asetLainnya.pembanding = kode2x + kode3x + kode4x + kode5x;
      let da = vm.asetLainnya.nominal - vm.asetLainnya.pembanding;
      let de = da / vm.asetLainnya.nominal;
      let du = de * 100;
      let ddo = Number(du.toFixed(2));
      if (ddo < -50) {
        vm.asetLainnya.status = "red";
      } else if (ddo < -0 && ddo >= -50) {
        vm.asetLainnya.status = "pink";
      } else if (ddo == 0) {
        vm.asetLainnya.status = "yellow";
      } else if (ddo > 0 && ddo <= 50) {
        vm.asetLainnya.status = "tosca";
      } else if (ddo > 50) {
        vm.asetLainnya.status = "green";
      }
      vm.asetLainnya.selisih = du.toFixed(0) + "%";
      vm.asetLainnya.nominal = accounting.formatMoney(vm.asetLainnya.nominal, {
        symbol: "",
        precicsion: 2,
        thousand: ".",
        decimal: ",",
        format: {
          pos: "%v",
          neg: "(%v)",
          zero: "-",
        },
      });
      vm.asetLainnya.pembanding = accounting.formatMoney(
        vm.asetLainnya.pembanding,
        {
          symbol: "",
          precicsion: 2,
          thousand: ".",
          decimal: ",",
          format: {
            pos: "%v",
            neg: "(%v)",
            zero: "-",
          },
        }
      );

      vm.kewajibanPendek.nominal = kode6;
      vm.kewajibanPendek.pembanding = kode6x;
      let qa = vm.kewajibanPendek.nominal - vm.kewajibanPendek.pembanding;
      let qe = qa / vm.kewajibanPendek.nominal;
      let qu = qe * 100;
      let qo = Number(qu.toFixed(2));
      if (qo < -50) {
        vm.kewajibanPendek.status = "red";
      } else if (qo < -0 && qo >= -50) {
        vm.kewajibanPendek.status = "pink";
      } else if (qo == 0) {
        vm.kewajibanPendek.status = "yellow";
      } else if (qo > 0 && qo <= 50) {
        vm.kewajibanPendek.status = "tosca";
      } else if (qo > 50) {
        vm.kewajibanPendek.status = "green";
      }
      vm.kewajibanPendek.selisih = qu.toFixed(0) + "%";
      vm.kewajibanPendek.nominal = accounting.formatMoney(
        vm.kewajibanPendek.nominal,
        {
          symbol: "",
          precicsion: 2,
          thousand: ".",
          decimal: ",",
          format: {
            pos: "%v",
            neg: "(%v)",
            zero: "-",
          },
        }
      );
      vm.kewajibanPendek.pembanding = accounting.formatMoney(
        vm.kewajibanPendek.pembanding,
        {
          symbol: "",
          precicsion: 2,
          thousand: ".",
          decimal: ",",
          format: {
            pos: "%v",
            neg: "(%v)",
            zero: "-",
          },
        }
      );

      vm.kewajibanPanjang.nominal = kode7;
      vm.kewajibanPanjang.pembanding = kode7x;
      let wa = vm.kewajibanPanjang.nominal - vm.kewajibanPanjang.pembanding;
      let we = wa / vm.kewajibanPanjang.nominal;
      let wu = we * 100;
      let wo = Number(wu.toFixed(2));
      if (wo < -50) {
        vm.kewajibanPanjang.status = "red";
      } else if (wo < -0 && wo >= -50) {
        vm.kewajibanPanjang.status = "pink";
      } else if (wo == 0) {
        vm.kewajibanPanjang.status = "yellow";
      } else if (wo > 0 && wo <= 50) {
        vm.kewajibanPanjang.status = "tosca";
      } else if (wo > 50) {
        vm.kewajibanPanjang.status = "green";
      } else if (wa == 0) {
        vm.kewajibanPanjang.status = "nan";
      }
      vm.kewajibanPanjang.selisih = wu.toFixed(0) + "%";
      vm.kewajibanPanjang.nominal = accounting.formatMoney(
        vm.kewajibanPanjang.nominal,
        {
          symbol: "",
          precicsion: 2,
          thousand: ".",
          decimal: ",",
          format: {
            pos: "%v",
            neg: "(%v)",
            zero: "-",
          },
        }
      );
      vm.kewajibanPanjang.pembanding = accounting.formatMoney(
        vm.kewajibanPanjang.pembanding,
        {
          symbol: "",
          precicsion: 2,
          thousand: ".",
          decimal: ",",
          format: {
            pos: "%v",
            neg: "(%v)",
            zero: "-",
          },
        }
      );

      vm.ekuitas.nominal = kode8;
      vm.ekuitas.pembanding = kode8x;
      let pa = vm.ekuitas.nominal - vm.ekuitas.pembanding;
      let pe = pa / vm.ekuitas.nominal;
      let pu = pe * 100;
      let po = Number(pu.toFixed(2));
      if (po < -50) {
        vm.ekuitas.status = "red";
      } else if (po < -0 && po >= -50) {
        vm.ekuitas.status = "pink";
      } else if (po == 0) {
        vm.ekuitas.status = "yellow";
      } else if (po > 0 && po <= 50) {
        vm.ekuitas.status = "tosca";
      } else if (po > 50) {
        vm.ekuitas.status = "green";
      }
      vm.ekuitas.selisih = pu.toFixed(0) + "%";
      vm.ekuitas.nominal = accounting.formatMoney(vm.ekuitas.nominal, {
        symbol: "",
        precicsion: 2,
        thousand: ".",
        decimal: ",",
        format: {
          pos: "%v",
          neg: "(%v)",
          zero: "-",
        },
      });
      vm.ekuitas.pembanding = accounting.formatMoney(vm.ekuitas.pembanding, {
        symbol: "",
        precicsion: 2,
        thousand: ".",
        decimal: ",",
        format: {
          pos: "%v",
          neg: "(%v)",
          zero: "-",
        },
      });

      vm.omset.nominal = kode9;
      vm.omset.pembanding = kode9x;
      let ya = vm.omset.nominal - vm.omset.pembanding;
      let ye = ya / vm.omset.nominal;
      let yu = ye * 100;
      let yo = Number(yu.toFixed(2));
      if (yo < -50) {
        vm.omset.status = "red";
      } else if (yo < -0 && yo >= -50) {
        vm.omset.status = "pink";
      } else if (yo == 0) {
        vm.omset.status = "yellow";
      } else if (yo > 0 && yo <= 50) {
        vm.omset.status = "tosca";
      } else if (yo > 50) {
        vm.omset.status = "green";
      }
      vm.omset.selisih = yu.toFixed(0) + "%";
      vm.omset.nominal = accounting.formatMoney(vm.omset.nominal, {
        symbol: "",
        precicsion: 2,
        thousand: ".",
        decimal: ",",
        format: {
          pos: "%v",
          neg: "(%v)",
          zero: "-",
        },
      });
      vm.omset.pembanding = accounting.formatMoney(vm.omset.pembanding, {
        symbol: "",
        precicsion: 2,
        thousand: ".",
        decimal: ",",
        format: {
          pos: "%v",
          neg: "(%v)",
          zero: "-",
        },
      });

      vm.labaKotor.nominal = kode9 - kode10;
      vm.labaKotor.pembanding = kode9x - kode10x;
      let oa = vm.labaKotor.nominal - vm.labaKotor.pembanding;
      let oe = oa / vm.labaKotor.nominal;
      let ou = oe * 100;
      let oo = Number(ou.toFixed(2));
      if (oo < -50) {
        vm.labaKotor.status = "red";
      } else if (oo < -0 && oo >= -50) {
        vm.labaKotor.status = "pink";
      } else if (oo == 0) {
        vm.labaKotor.status = "yellow";
      } else if (oo > 0 && oo <= 50) {
        vm.labaKotor.status = "tosca";
      } else if (oo > 50) {
        vm.labaKotor.status = "green";
      }
      vm.labaKotor.selisih = ou.toFixed(0) + "%";
      vm.labaKotor.nominal = accounting.formatMoney(vm.labaKotor.nominal, {
        symbol: "",
        precicsion: 2,
        thousand: ".",
        decimal: ",",
        format: {
          pos: "%v",
          neg: "(%v)",
          zero: "-",
        },
      });
      vm.labaKotor.pembanding = accounting.formatMoney(
        vm.labaKotor.pembanding,
        {
          symbol: "",
          precicsion: 2,
          thousand: ".",
          decimal: ",",
          format: {
            pos: "%v",
            neg: "(%v)",
            zero: "-",
          },
        }
      );

      vm.rows.forEach((el) => {
        if (el.kategori == 8) {
          el._children.forEach((els) => {
            els._children.forEach((elk) => {
              elk._children.forEach((elx) => {
                elx._children.forEach((elg) => {
                  if (elg.kategori == 15 && elg.total_akhir) {
                    kode15 = el.total_akhir;
                  }
                });
              });
            });
          });
        }
      });
      vm.rows.forEach((el) => {
        if (el.kategori == 8) {
          el._children.forEach((els) => {
            els._children.forEach((elk) => {
              elk._children.forEach((elx) => {
                elx._children.forEach((elg) => {
                  if (elg.kategori == 15 && elg.pembanding) {
                    kode15x = el.pembanding;
                  }
                });
              });
            });
          });
        }
      });
      vm.labaBersih.nominal = kode15;
      vm.labaBersih.pembanding = kode15x;
      let ra = vm.labaBersih.nominal - vm.labaBersih.pembanding;
      let re = ra / vm.labaBersih.nominal;
      let ru = re * 100;
      let ro = Number(ru.toFixed(2));
      if (ro < -50) {
        vm.labaBersih.status = "red";
      } else if (ro < -0 && ro >= -50) {
        vm.labaBersih.status = "pink";
      } else if (ro == 0) {
        vm.labaBersih.status = "yellow";
      } else if (ro > 0 && ro <= 50) {
        vm.labaBersih.status = "tosca";
      } else if (ro > 50) {
        vm.labaBersih.status = "green";
      }
      vm.labaBersih.selisih = ru.toFixed(0) + "%";
      vm.labaBersih.nominal = accounting.formatMoney(vm.labaBersih.nominal, {
        symbol: "",
        precicsion: 2,
        thousand: ".",
        decimal: ",",
        format: {
          pos: "%v",
          neg: "(%v)",
          zero: "-",
        },
      });
      vm.labaBersih.pembanding = accounting.formatMoney(
        vm.labaBersih.pembanding,
        {
          symbol: "",
          precicsion: 2,
          thousand: ".",
          decimal: ",",
          format: {
            pos: "%v",
            neg: "(%v)",
            zero: "-",
          },
        }
      );

      this.items.push(this.balance);
      this.items.push(this.asetLancar);
      this.items.push(this.asetLainnya);
      this.items.push(this.kewajibanPendek);
      this.items.push(this.kewajibanPanjang);
      this.items.push(this.ekuitas);
      this.items.push(this.omset);
      this.items.push(this.labaKotor);
      this.items.push(this.labaBersih);
      this.items.forEach((el, ii) => {
        let qwe = this.items[ii];
        qwe.nomer = ii + 1;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");

$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white": #fafafa,
  "white2": #fff,
  "gray-dark": #b5bac3,
  "blue-light": #e8f3fe,
  "red-light": #ffe8e7,
  "green-light": #eafffa,
  "green": #6adbbe,
);

.wrapper {
  .front {
    font-family: "nunito", sans-serif;
    font-size: 28px;
    font-weight: 300;
    padding-bottom: 1%;
    .title {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
